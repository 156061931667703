<template>
  <div class="card mainpractice rect-gradient-2">
    <div class="mainpractice-heading">
      <div class="header-icon-container">
        <i class="header-icon fa-solid fa-landmark" />
      </div>
      <div style="max-width: 200px">
        <h1>Practice</h1>
        <span style="text-align: center"
          >Practice from positions with different themes, curated to learn you
          anything from the basics to advanced concepts</span
        >
      </div>
    </div>
    <div class="categoryheader-points">
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Collect practice points from beating practices. 1 point per 100 rating points beaten"
        class="categoryheader-points-item"
      >
        <div>
          <img
            style="filter: hue-rotate(45deg)"
            src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
          />
          <span>Points</span>
        </div>
        <div v-if="stats.total <= 0" style="width: 6rem; height: 1.8rem">
          <Loader size="1.8rem" />
        </div>
        <span
          v-else
          style="font-size: 1.8rem; width: 6rem; height: 1.8rem"
          class="fade-in"
          >{{ stats.points }}</span
        >
      </div>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Number of practices beaten at any level"
        class="categoryheader-points-item"
      >
        <div>
          <i
            class="header-icon fa-solid fa-square-check"
            :style="{
              color:
                stats.finished === stats.total && stats.total > 0
                  ? 'var(--clr-accent4)'
                  : 'var(--clr-rect-2)',
            }"
          />
          <span>Finished</span>
        </div>
        <div v-if="stats.total <= 0" style="width: 6rem; height: 1.8rem">
          <Loader size="1.8rem" />
        </div>
        <span
          v-else
          style="font-size: 1.8rem; width: 6rem; height: 1.8rem"
          class="fade-in"
          >{{ stats.finished }}/{{ stats.total }}</span
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type PropType, watch } from "vue";
import { ref } from "vue";
import type { PracticePosition } from "@/types/apitypes";
import Loader from "@/components/util/LoaderNew.vue";

const props = defineProps({
  practices: {
    type: Object as PropType<PracticePosition[]>,
  },
});

let stats = ref<{
  points: number;
  finished: number;
  total: number;
  averageRating: number;
}>({
  points: 0,
  finished: 0,
  total: 0,
  averageRating: 0,
});

watch(
  () => props.practices,
  () => updateStats(),
  { immediate: true }
);

function updateStats() {
  if (!props.practices) return;
  let points = 0;
  let finished = 0;
  let ratingSum = 0;

  for (let practice of props.practices) {
    if (practice.user_achieved) {
      points += practice.user_points!;
      finished++;
      ratingSum += practice.user_botRatingAtWin!;
    }
  }

  stats.value = {
    points: points,
    finished: finished,
    total: props.practices.length,
    averageRating: ratingSum / finished,
  };
}
</script>

<style scoped>
.mainpractice {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.mainpractice-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.header-icon {
  color: var(--clr-rect-2);
  font-size: 2rem;
}
.header-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  min-width: 5rem;
  height: 5rem;
}

.categoryheader-points {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  padding: 0.5rem;
}

.categoryheader-points-item img {
  width: 2rem;
  aspect-ratio: 1;
}

.categoryheader-points-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.categoryheader-points-item > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem;
}

.fa-square-check {
  filter: drop-shadow(1px 1px 1px var(--clr-icons-drop-shadow));
}

@media (max-width: 992px) {
  .mainpractice-heading {
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: start;
  }

  .categoryheader-points {
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
  }

  .categoryheader-points-item {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
}
</style>
