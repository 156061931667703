<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <ConfettiExplosion
      v-if="showConfetti"
      :colors="['#FFDFB7', '#FFD49D', '#FFC741', '#FFC139', '#FFA849']"
      :force="0.7"
      :stageHeight="getViewportSize().height"
      :stageWidth="getViewportSize().width"
      :duration="3500"
      :particleCount="50"
    />
    <i
      class="win-puzzle-check-achieved win-puzzle-check-icon fa-solid fa-circle-check"
      id="starFull"
    />
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";
import ConfettiExplosion from "vue-confetti-explosion";
import { usePageStore } from "@/stores/pageStore";
import { getViewportSize } from "@/util/util.js";

let showConfetti = ref(false);

const explode = async () => {
  showConfetti.value = false;
  await nextTick();
  showConfetti.value = true;
};

const start = () => {
  usePageStore().setPlaySound("succeed-puzzle");

  explode();

  let starFullElement = document.getElementById("starFull");
  starFullElement!.classList.add("win-puzzle-rotate-scale-up-diag-1");

  starFullElement!.addEventListener("animationend", function () {
    starFullElement!.classList.remove("win-puzzle-rotate-scale-up-diag-1");

    setTimeout(() => {
      showConfetti.value = false;
      starFullElement!.classList.add("win-puzzle-rotate-out-center");
    }, 500);
  });
};

onMounted(() => {
  start();
});
</script>

<style>
.win-puzzle-check-icon {
  position: absolute;
  width: 10rem;
  height: 10rem;
  z-index: 10;
  font-size: 10rem;

  border-radius: 50%;
  --bs-btn-bg: var(--clr-rect-2);
  --bs-btn-border-color: var(--clr-rect-2);
  --bs-btn-hover-bg: var(--clr-rect-1);
  --bs-btn-hover-border-color: var(--clr-rect-1);
  --bs-btn-text: var(--clr-rect-1);
}

.win-puzzle-check-achieved {
  background: white;
  color: var(--clr-accent4);
}

.win-puzzle-rotate-out-center {
  animation: win-puzzle-rotate-out-center 0.6s ease-in-out both;
}
.win-puzzle-rotate-scale-up-diag-1 {
  animation: win-puzzle-rotate-scale-up-diag-1 0.7s linear both;
}
@keyframes win-puzzle-rotate-out-center {
  0% {
    transform: rotate(0) scale(100%);
    opacity: 1;
  }
  100% {
    transform: rotate(-360deg) scale(0%);
    opacity: 0;
  }
}
@keyframes win-puzzle-rotate-scale-up-diag-1 {
  0% {
    transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    transform: scale(2) rotate3d(1, 1, 0, -180deg);
  }
  100% {
    transform: scale(1) rotate3d(1, 1, 0, -360deg);
  }
}
</style>
