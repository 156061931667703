<template>
  <div class="card" style="text-align: center">
    <h2 class="title">{{ titleText }}</h2>
    <div
      v-if="loading || bots.length <= 0"
      class="profiles pulsate-load-strong"
    >
      <div v-for="i in 6" :key="'filler' + i" class="bot-card">
        <div>
          <div
            class="card main-gradient"
            :style="{
              width: getProfileCardScale().width + 'rem',
              height: getProfileCardScale().width + 'rem',
            }"
          ></div>
          <div
            class="card"
            :style="{
              marginTop: '0.2rem',
              width: getProfileCardScale().width + 'rem',
              height: getProfileCardScale().fontSize * 1.5 + 'rem',
            }"
          ></div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 0.5rem;
            "
          >
            <div style="display: flex; gap: 0.2rem">
              <button
                class="btn btn-info btn-sm ph-no-capture"
                :data-bs-original-title="'Play rated'"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                disabled
              >
                <i class="fa-duotone fa-arrow-trend-up" />
              </button>
              <button
                class="btn btn-info btn-sm ph-no-capture"
                :data-bs-original-title="'Play casual'"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                disabled
              >
                <i class="fa-duotone fa-flask-round-potion" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="profiles">
      <div v-for="(bot, index) in bots" :key="index">
        <ProfileCard :bot="bot" :anonymous="anonymize" profile-scale="small" />
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.5rem;
          "
        >
          <div style="display: flex; gap: 0.2rem">
            <button
              v-if="useBotsStore().isLocked(bot)"
              class="btn btn-warning btn-sm ph-no-capture"
              :data-bs-original-title="'Play against ' + bot.name"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="
                () => {
                  router.push({
                    name: 'premium',
                  });
                  track('new_bots_box', 'play_locked', 'click', {
                    botId: bot.id,
                    rated: false,
                  });
                }
              "
            >
              <i class="fa-solid fa-crown" /> Unlock
            </button>
            <button
              v-else
              class="btn btn-info btn-sm ph-no-capture"
              :data-bs-original-title="'Play against ' + bot.name"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="
                () => {
                  playAgainst(bot.id);
                  track('new_bots_box', 'play', 'click', {
                    botId: bot.id,
                    rated: false,
                  });
                }
              "
            >
              <i class="fa-duotone fa-swords" /> Play
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      href="/play"
      style="margin: 1rem 0"
      @click="track('new_bots_box', 'explore_more_opponents', 'click')"
    >
      Explore more opponents <i class="fa-duotone fa-arrow-right"
    /></a>
  </div>
</template>

<script setup lang="ts">
import { onMounted, type PropType, ref, type Ref } from "vue";
import ProfileCard from "@/components/common/ProfileCard.vue";
import type { Bot } from "@/types/apitypes";
import { useBotsStore } from "@/stores/botStore";
import { track } from "@/util/tracking";
import { useRouter } from "vue-router";

const bs = useBotsStore();

const props = defineProps({
  titleText: { type: String, required: true },
  filterDate: { type: String, required: false },
  botList: { type: Array as PropType<string[]>, required: false },
  anonymize: { type: Boolean, default: false },
});

const router = useRouter();
let loading = ref(true);
let bots: Ref<Bot[]> = ref([]);

onMounted(() => {
  getBots().then((r) => (bots.value = r));
});

function getProfileCardScale() {
  return { width: 8, height: 9.6, fontSize: 0.95 };
}

function playAgainst(botId: string | null) {
  const query = { bid: botId, type: "casual" };

  router.push({
    name: "game",
    query: query,
  });
}

async function getBots() {
  let bots: Bot[] = [];

  if (props.filterDate != null) {
    bots = await bs.getBotsOnDate(props.filterDate);
  } else if (props.botList != null) {
    bots = (
      await Promise.all(props.botList.map((id) => bs.getUserBotProfile(id)))
    ).map((r) => r.data.bot);
  }

  loading.value = false;

  return bots;
}
</script>

<style scoped>
.profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  padding-block: 1rem;
}

@media (min-width: 768px) {
  .profiles {
    gap: 1rem;
    padding: 1rem; /* On larger screens, add padding to sides as well */
  }
}
</style>
