<template>
  <div class="card p-4 custom-challenge-wrapper">
    <div
      class="top-section ph-no-capture"
      @click="
        () => {
          toggleExpand();
          track('custom_challenge_box', 'toggle_expand', 'click', {
            expanded: expanded,
          });
        }
      "
      role="button"
    >
      <h2>Custom position</h2>
      <span>Set up your own challenge</span>
      <div class="profile-selector-container-expanded-after"></div>
    </div>
    <div class="bottom-section" v-if="expanded">
      <BoardEditor
        :boardBg="boardBg"
        @fen-changed="fenChanged"
        :hasActive="active != null"
        :initial-expand-editor="true"
      />
      <hr class="hr" />
      <div v-if="active != null || fen == ''">
        <button
          role="button"
          class="btn btn-info w-100 ph-no-capture"
          :disabled="true"
        >
          {{
            fen == "" ? "Invalid position" : "You already have an ongoing game"
          }}
        </button>
      </div>
      <div v-else style="display: flex; gap: 1rem; justify-content: center">
        <button
          role="button"
          class="btn btn-info ph-no-capture"
          @click="
            () => {
              gotoChallenge('white');
              track('custom_challenge_box', 'goto_challenge', 'click', {
                side: 'white',
              });
            }
          "
        >
          <i class="fa-solid fa-circle" /> Play white
        </button>
        <button
          role="button"
          class="btn btn-info ph-no-capture"
          @click="
            () => {
              gotoChallenge('black');
              track('custom_challenge_box', 'goto_challenge', 'click', {
                side: 'black',
              });
            }
          "
        >
          <i class="fa-regular fa-circle" /> Play black
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGameStore } from "@/stores/gameStore";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import BoardEditor from "@/components/practice/BoardEditor.vue";
import { track } from "@/util/tracking";

const gs = useGameStore();

const { active } = storeToRefs(gs);

const router = useRouter();
let boardBg = ref("var(--clr-accent");
let fen = ref("");
let expanded = ref(false);

function toggleExpand() {
  expanded.value = !expanded.value;
}

function fenChanged(changedFen: { fen: string; isValid: boolean }) {
  if (changedFen.isValid) {
    fen.value = changedFen.fen;
  } else {
    fen.value = ""; // Represent an invalid fen
  }
}

function gotoChallenge(side: "white" | "black") {
  router.push({
    name: "game",
    query: {
      type: "custom",
      fen: fen.value,
      side: side,
    },
  });
}
</script>

<style scoped>
:deep(cg-board) {
  background-color: v-bind("boardBg"); /* Override the board background color */
}

.top-section {
  text-align: center;
}

.profile-selector-container-expanded-after {
  left: 25%;
  bottom: 0;
  width: 0; /* Set width to 0 */
  height: 0; /* Set height to 0 */
  border-left: 5rem solid transparent; /* Left border, half the width of the triangle */
  border-right: 5rem solid transparent; /* Right border, half the width of the triangle */
  border-top: 1rem solid var(--clr-main-lightest); /* Top border, height and color of the triangle */
  margin: 0.2rem auto;
}
</style>
