<template>
  <div
    class="d-flex flex-row"
    style="
      margin-left: 0rem;
      margin-top: 0rem;
      text-align: center;
      justify-content: center;
    "
  >
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        opacity:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) ||
          moves == null ||
          moves == 0
            ? 0.6
            : 1.0,
        cursor:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) ||
          moves == null ||
          moves == 0
            ? 'default'
            : 'pointer',
      }"
      :data-bs-toggle="isMobileWidth() ? '' : 'tooltip'"
      data-bs-placement="bottom"
      title="Go to start of game (Shortcut: Up arrow)"
      data-bs-original-title="Go to start of game (Shortcut: Up arrow)"
      @click="
        () => {
          history(UserInput.HistoryStart);
          track('move_navigation', 'history_start', 'click');
        }
      "
    >
      <i class="fa-solid fa-angles-left"></i>
    </button>
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        opacity:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) ||
          moves == null ||
          moves == 0
            ? 0.6
            : 1.0,
        cursor:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) ||
          moves == null ||
          moves == 0
            ? 'default'
            : 'pointer',
      }"
      :data-bs-toggle="isMobileWidth() ? '' : 'tooltip'"
      data-bs-placement="bottom"
      title="Previous move (Shortcut: Left arrow)"
      data-bs-original-title="Previous move (Shortcut: Left arrow)"
      @click="
        () => {
          history(UserInput.HistoryPrevious);
          track('move_navigation', 'history_previous', 'click');
        }
      "
    >
      <i class="fa-solid fa-angle-left"></i>
    </button>
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        height: '3rem',
        width: '3rem',
        marginTop: '-0.5rem',
        opacity:
          viewingHistoryPly != null && viewingHistoryPly < moves ? 1.0 : 0.6,
        cursor:
          viewingHistoryPly != null && viewingHistoryPly < moves
            ? 'pointer'
            : 'default',
      }"
      :data-bs-toggle="isMobileWidth() ? '' : 'tooltip'"
      data-bs-placement="bottom"
      title="Next move (Shortcut: Right arrow)"
      data-bs-original-title="Next move (Shortcut: Right arrow)"
      @click="
        () => {
          history(UserInput.HistoryNext);
          track('move_navigation', 'history_next', 'click');
        }
      "
    >
      <i class="fa-solid fa-angle-right" style="font-size: 1.4rem"></i>
    </button>
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        opacity:
          viewingHistoryPly != null && viewingHistoryPly < moves ? 1.0 : 0.6,
        cursor:
          viewingHistoryPly != null && viewingHistoryPly < moves
            ? 'pointer'
            : 'default',
      }"
      :data-bs-toggle="isMobileWidth() ? '' : 'tooltip'"
      data-bs-placement="bottom"
      title="Go to end of game (Shortcut: Down arrow)"
      data-bs-original-title="Go to end of game (Shortcut: Down arrow)"
      @click="
        () => {
          history(UserInput.HistoryEnd);
          track('move_navigation', 'history_end', 'click');
        }
      "
    >
      <i class="fa-solid fa-angles-right"></i>
    </button>
  </div>
</template>
<script setup lang="ts">
import { onMounted, type PropType } from "vue";
import { UserInput } from "@/types/internaltypes";
import { Tooltip as BsTooltip } from "bootstrap";
import { isMobileWidth } from "@/util/util";
import { track } from "@/util/tracking";

const props = defineProps({
  moves: { type: Number, required: true },
  opponentColor: { type: String, default: "#fff" },
  viewingHistoryPly: {
    type: null as unknown as PropType<number | null>,
    default: null,
  },
});

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

const emit = defineEmits(["userInput"]);

onMounted(() => {
  window.addEventListener("keydown", function (e) {
    if (props.moves == null || props.moves == 0) {
      return;
    }

    if (e.key === "ArrowLeft") {
      e.preventDefault();
      history(UserInput.HistoryPrevious);
    } else if (e.key === "ArrowRight") {
      e.preventDefault();
      history(UserInput.HistoryNext);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      history(UserInput.HistoryStart);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      history(UserInput.HistoryEnd);
    }
  });
});

const history = (type: UserInput) => {
  emit("userInput", { type: type });
};
</script>
<style scoped>
.profile-image-circle {
  height: 2rem;
  width: 2rem;
  z-index: 10;
  border-radius: 50%;
  border: 1px solid #444;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
