// Used for various communication between pages
import { defineStore } from "pinia";
import { nextTick } from "vue";
import { type GameSettings, ImageType } from "@/types/internaltypes";
import type { TimeControl } from "@/types/apitypes";

export const usePageStore = defineStore("page", {
  state: () => ({
    wonAgainstBotIdForTheFirstTime: null as string | null,
    imagesBase: "https://storage.googleapis.com/chessiverse1/images/" as string,
    puzzleHintsChanged: { change: 0, current: 2 } as {
      change: number;
      current: number;
    },
    sounds: {
      master: {
        muted: false,
        volume: 100,
      },
      moves: {
        muted: false,
        volume: 100,
      },
      other: {
        muted: false,
        volume: 100,
      },
    },
    boardColorOverride: {
      active: false,
      color: "#73cdee",
    },
    showBoardCoordinates: true,
    showBotThinkingBubble: true,
    botPlaysInstantInInfinite: false,
    allowResignation: true,
    playSound: "" as string,
    playMoveSounds: [] as string[],
    gameSettings: null as GameSettings | null,
    currentMoveTime: null as number | null,
  }),
  getters: {
    getMasterAdjustedVolumes(): { moves: number; other: number } {
      const masterPercent = this.sounds.master.volume * 0.01;

      return {
        moves: Math.round(this.sounds.moves.volume * masterPercent),
        other: Math.round(this.sounds.other.volume * masterPercent),
      };
    },
  },
  actions: {
    setCurrentMoveTime(time: number | null) {
      this.currentMoveTime = time;
    },
    setPlaySound(sound: string) {
      this.playSound = "";
      nextTick().then(() => {
        this.playSound = sound;
      });
    },
    setPlayMoveSound(sound: string) {
      this.playMoveSounds.push(sound);
    },
    updateGameSettings(
      time: TimeControl | null | undefined,
      rated: "rated" | "unrated" | null | undefined,
      color: "random" | "white" | "black" | null | undefined
    ) {
      if (this.gameSettings == null) {
        // If gameSettings wasn't set at all before, default undefineds to null and sett the full object
        this.gameSettings = {
          timeControl: time === undefined ? null : time,
          rated: rated === undefined ? null : rated,
          color: color === undefined ? null : color,
        };
      } else {
        // GameSettings were set since before so only update the fields that are not undefined
        if (time !== undefined) {
          this.gameSettings.timeControl = time;
        }
        if (rated !== undefined) {
          this.gameSettings.rated = rated;
        }
        if (color !== undefined) {
          this.gameSettings.color = color;
        }
      }
    },
    img(
      identifier: string | null,
      imageType: ImageType,
      dimensions: string | null
    ): string {
      let imageUrl = this.imagesBase;

      switch (imageType) {
        case ImageType.BotProfile:
          imageUrl += "bots/profiles/";
          break;
        case ImageType.BotBackground:
          imageUrl += "bots/backgrounds/";
          break;
      }

      if (
        imageType == ImageType.BotProfile ||
        imageType == ImageType.BotBackground
      ) {
        if (identifier == null) {
          throw "Missing identifier";
        }
        imageUrl += identifier;
      }

      if (imageType == ImageType.BotProfile) {
        if (dimensions != "320" && dimensions != "175" && dimensions != "80") {
          throw "Invalid dimensions";
        }

        imageUrl += "_" + dimensions;
      } else if (imageType == ImageType.BotBackground) {
        if (
          dimensions != null &&
          dimensions != "" &&
          dimensions != "410_landscape" &&
          dimensions != "124" &&
          dimensions != "20"
        ) {
          throw "Invalid dimensions";
        }

        if (dimensions != null && dimensions != "") {
          imageUrl += "_" + dimensions;
        }
      }

      return imageUrl + ".webp";
    },
    handledWonAgainstBotIdForTheFirstTime(): void {
      this.wonAgainstBotIdForTheFirstTime = null;
    },
  },
  persist: true,
});
