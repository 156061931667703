<template>
  <div
    v-if="show"
    class="card information-container bounce-top"
    style="--bs-card-border-color: var(--clr-accent)"
  >
    <div class="main">
      <div class="premium-text-and-button">
        <span
          >👋 Enjoying Chessiverse? Try a 7-day free Premium trial and unlock
          all 500+ bots! Use the code <strong>TRYOUT20</strong>, and if you
          decide to stay you also get a 20% discount! 🎉</span
        >
        <a
          class="btn btn-warning"
          href="/premium"
          @click="
            () => {
              track('dashboard_notification', 'get_premium', 'click');
            }
          "
        >
          <i class="fa-solid fa-crown" /> Get premium
        </a>
      </div>
      <div>
        <a
          role="button"
          class="ph-no-capture"
          @click="
            () => {
              dismissBanner();
              track('dashboard', 'dismiss_going_premmium_banner', 'click');
            }
          "
          ><i class="fa-regular fa-xmark-circle close-x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { ref } from "vue";
import { track } from "@/util/tracking";
import { parseDate } from "@/util/util";

const userStore = useUserStore();

let show = ref(shouldShow());

function shouldShow() {
  if (localStorage.getItem("dismissedGoingPremiumBanner") == "true") {
    return false;
  }

  if (
    useUserStore().user.data?.roles?.includes("beta") ||
    useUserStore().user.data?.roles?.includes("premium") ||
    useUserStore().user.data?.roles?.includes("lifetime") ||
    useUserStore().user.data?.roles?.includes("yearly") ||
    useUserStore().user.data?.roles?.includes("monthly")
  ) {
    return false;
  }

  // @ts-ignore createdAt does exist
  let createdAt = parseDate(useUserStore().user.firebaseUser?.createdAt);

  createdAt = new Date("2024-08-07");

  if (createdAt == null) {
    return false;
  }

  let sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  let fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

  if (createdAt > sevenDaysAgo || createdAt < fourteenDaysAgo) {
    return false;
  }

  return true;
}

function dismissBanner() {
  show.value = false;
  localStorage.setItem("dismissedGoingPremiumBanner", "true");
}
</script>

<style scoped>
.premium-text-and-button {
  display: flex;
  gap: 1rem;
}

.premium-text-and-button strong {
  font-size: 1.1rem !important;
}

.premium-text-and-button a {
  width: 5rem;
}

@media (max-width: 768px) {
  .premium-text-and-button {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .premium-text-and-buttonstrong {
    font-size: 1.5rem;
  }

  .premium-text-and-button {
    text-align: center;
    text-wrap: balance;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.information-container {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
}

.close-x {
  color: var(--clr-accent);
}

.close-x:hover {
  color: var(--clr-accent2);
}
</style>
