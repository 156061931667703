<template>
  <div class="card position" @click.stop>
    <div
      class="board-and-play"
      @click="
        () => {
          ms.showPracticePreview(position);
        }
      "
    >
      <TheChessboard
        :board-config="getBoardConfig(position.start_position, position.side)"
        class="positionboard"
      />
      <i class="expand-board-icon fa-solid fa-expand" />
    </div>

    <div class="positionmiddle">
      <div class="positionname">
        <div
          :style="{
            borderRadius: '50%',
            width: '1rem',
            height: '1rem',
            display: 'inline-block',
            margin: '0 0.5rem -0.2rem 0',
            border: '1px solid black',
            backgroundColor: position.side == 'white' ? 'white' : 'black',
          }"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="'Play as ' + position.side"
          :data-bs-original-title="'Play as ' + position.side"
        ></div>
        <span style="white-space: normal">
          {{ position.name }}
        </span>
      </div>
      <div class="best-win" v-if="position.user_achieved">
        <span>Best:</span>
        <img
          class="bot-profile-image"
          :src="
                        ps.img(position.user_botId!, ImageType.BotProfile, '175')
                      "
        />
        <span>{{ position.user_botRatingAtWin }}</span>
      </div>
      <div class="best-win" v-else>
        <span>Best: -</span>
      </div>
    </div>
    <div class="positionright">
      <div class="points">
        <div
          v-if="position.user_achieved"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="
                        'Got ' +
                        position.user_points +
                        ' points for beating a ' +
                        position.user_points! * 100 +
                        '+ rated opponent'
                      "
          :data-bs-original-title="
                        'Got ' +
                        position.user_points +
                        ' points for beating a ' +
                        position.user_points! * 100 +
                        '+ rated opponent'
                      "
        >
          <img
            style="filter: hue-rotate(45deg)"
            src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
          />
          <span>{{ position.user_points }}</span>
        </div>
        <div
          v-else
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Never finished"
        >
          <img
            style="filter: hue-rotate(45deg)"
            src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
          />
          0
        </div>
      </div>
      <button
        class="btn btn-info play-icon ph-no-capture"
        @click="
          () => {
            playPosition(position.id);
            track('practice_view', 'play_position', 'click', {
              position_id: position.id,
            });
          }
        "
      >
        Play
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useModalsStore } from "@/stores/modalsStore";
import { usePageStore } from "@/stores/pageStore";
import type { PropType } from "vue";
import type { PracticePosition } from "@/types/apitypes";
import { ImageType } from "@/types/internaltypes";
import router from "@/router";
import { track } from "@/util/tracking";
import { type BoardConfig, TheChessboard } from "vue3-chessboard";

const ps = usePageStore();
const ms = useModalsStore();

defineProps({
  position: { type: Object as PropType<PracticePosition>, required: true },
});

function playPosition(positionId: string) {
  router.push({
    name: "game",
    query: {
      type: "practice",
      practiceId: positionId,
    },
  });
}

function getBoardConfig(fen: string, side: "white" | "black"): BoardConfig {
  return {
    fen: fen,
    orientation: side,
    viewOnly: true,
  };
}
</script>
<style scoped>
.position {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.positionmiddle {
  flex-grow: 1;
  padding: 0.2rem;
}

.positionright {
  padding: 0.2rem 0.4rem 0.2rem 0.2rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
}

.positionright img {
  width: 1.5rem;
  aspect-ratio: 1;
  display: inline-block;
}

.positionboard {
  width: 6rem;
  height: 6rem;
}

@media (max-width: 992px) {
}

:deep(cg-board) {
  background-color: var(--clr-accent); /* Override the board background color */
}

@keyframes cardEntrance {
  from {
    opacity: 0;
    transform: scale(0.3);
    filter: hue-rotate(180deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
    filter: hue-rotate(0deg);
  }
}

.bot-profile-image {
  border-radius: 50%;
}

.points {
  flex-grow: 1;
}

.points > * {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
}

.points img {
  width: 2rem;
  aspect-ratio: 1;
}

.positionmiddle {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.position {
  cursor: auto;
}

.positionname {
  flex-grow: 1;
}

.best-win {
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
  align-items: center;
  justify-content: start;
}

.best-win img {
  width: 1.5rem;
  aspect-ratio: 1;
  margin: -0.3rem 0 0 0.2rem;
}

.play-icon {
  color: white;
  --bs-btn-bg: var(--clr-rect-2);
  --bs-btn-border-color: var(--clr-rect-2);
  --bs-btn-hover-bg: var(--clr-rect-1);
  --bs-btn-hover-border-color: var(--clr-rect-1);
  --bs-btn-text: var(--clr-rect-1);
}

.expand-board-icon {
  position: absolute;
  top: 4.5rem;
  left: 4.5rem;
  font-size: 1.5rem;
  z-index: 10;
  color: white;
}

.categoryheader-points img {
  width: 2.3rem;
  aspect-ratio: 1;
}

.categoryheader-points div:first-child {
  font-size: 1.8rem;
}

.right a {
  font-family: "Cabin Sketch", "Neucha", sans-serif;
}
</style>
