<template>
  <div>
    <div class="card">
      <div
        class="card-body"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
        "
      >
        <div id="moves" class="moves-container">
          <input class="fen-input" v-model="currentFen" />
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Copy FEN"
            class="btn btn-info ph-no-capture"
            style="padding: 0.2rem 0.2rem 0rem 0.2rem"
            @click="copy()"
          >
            <i class="fa-regular fa-copy"></i>
          </button>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <span>Continue from position</span>
          <div style="display: flex; gap: 1rem; justify-content: center">
            <button
              role="button"
              class="btn btn-info ph-no-capture"
              @click="
                () => {
                  gotoChallenge('white');
                  track('custom_challenge_box', 'goto_challenge', 'click', {
                    side: 'white',
                  });
                }
              "
            >
              <i class="fa-solid fa-circle" /> Play white
            </button>
            <button
              role="button"
              class="btn btn-info ph-no-capture"
              @click="
                () => {
                  gotoChallenge('black');
                  track('custom_challenge_box', 'goto_challenge', 'click', {
                    side: 'black',
                  });
                }
              "
            >
              <i class="fa-regular fa-circle" /> Play black
            </button>
          </div>
        </div>
        <div class="profile-container d-flex flex-row gap-5">
          <div
            class="card profile-name-text"
            :style="{ backgroundColor: opponentColor }"
          >
            <div class="card-body" style="margin-top: -0.75rem">FEN</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "vue-toast-notification";
import { track } from "@/util/tracking";
import { ref, watch } from "vue";
import router from "@/router";

const props = defineProps({
  fen: {
    type: String,
    required: true,
  },
  opponentColor: { type: String, default: "#fff" },
});

let currentFen = ref(props.fen);

watch(
  () => props.fen,
  () => {
    currentFen.value = props.fen;
  }
);

function gotoChallenge(side: "white" | "black") {
  router.push({
    name: "game",
    query: {
      type: "custom",
      fen: currentFen.value,
      side: side,
    },
  });
}

function copy() {
  navigator.clipboard
    .writeText(props.fen)
    .then(() => {
      useToast().success("Copied FEN to clipboard");
    })
    .catch((e) => {
      useToast().error(
        "Failed copying FEN to clipboard, make sure you allow the browser to copy, the error was: " +
          e
      );
    });
}
</script>

<style scoped>
.moves-container {
  height: 2rem;
  margin-top: 1rem;
  white-space: nowrap;
  display: flex;
  gap: 0.2rem;
  width: 100%;
}

.fen-input {
  width: 100%;
}

.bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.profile-image-circle {
  height: 2.5rem;
  width: 2.5rem;
  z-index: 10;
  border-radius: 50%;
  border: 1px solid #444;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-name-text {
  height: 2rem;
  width: 8rem;
  text-align: center;
}

.profile-container {
  position: absolute;
  top: -0.75rem;
  left: 1rem;
}
</style>
