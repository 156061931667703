<template>
  <div class="container">
    <div
      class="card profile-image-box"
      :style="{
        background:
          'url(' + ps.img(bot.id, ImageType.BotBackground, '20') + ')',
        backgroundSize: 'cover',
      }"
    >
      <img
        :src="ps.img(bot.id, ImageType.BotProfile, '80')"
        alt="Bot profile image"
        class="profile-image"
      />
    </div>
    <div
      class="card profile-text-box"
      :style="{
        backgroundColor: bot.config.boardbg,
      }"
    >
      <div class="profile-name mt-auto">
        {{ bot.name }}
      </div>
      <div class="profile-rating mt-auto">
        ({{ bot.strength.estimated_elo }})
      </div>
      <img
        alt="Golden star"
        v-if="showStar && bot.user?.hasWon"
        :style="{
          height: '1rem',
          width: '1rem',
          opacity: 1,
          marginTop: '0.4rem',
        }"
        src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp
              "
      />
      <img
        alt="Golden star outline"
        v-if="showStar && !bot.user?.hasWon"
        :style="{
          height: '1rem',
          width: '1rem',
          opacity: 0.9,
          marginTop: '0.4rem',
        }"
        src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp
              "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalsStore } from "@/stores/modalsStore";
import { usePageStore } from "@/stores/pageStore";
import type { Bot } from "@/types/apitypes";
import { ImageType } from "@/types/internaltypes";
import type { PropType } from "vue";

const ms = useModalsStore();
const ps = usePageStore();

defineProps({
  bot: { type: Object as PropType<Bot>, default: null },
  showStar: { type: Boolean, default: true },
});
</script>

<style scoped>
.container {
  display: flex;
  height: 2rem;
  justify-content: center;
}
.profile-image-box {
  height: 2rem;
  aspect-ratio: 1;
}
.profile-text-box {
  height: 2rem;
  padding-inline: 1rem;
  font-size: 1rem;
  margin-left: 2px;
  white-space: nowrap;
  width: 12rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.profile-name {
}
.profile-rating {
  color: var(--clr-main-light);
  flex-grow: 1;
}
</style>
