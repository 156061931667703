<template>
  <div
    style="
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: -2rem;
      width: 100%;
    "
  >
    <div
      v-if="showSettings"
      style="
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 1.5rem;
        width: 100%;
      "
    >
      <span style="color: var(--clr-dark-accent); font-weight: 800"
        >Settings:</span
      >
      <span style="color: var(--clr-dark-accent)">{{
        getSettingsTooltip()
      }}</span>
      <RatedSettings
        v-if="showRatedSelection()"
        @update="updateRated"
        :initial="ps.gameSettings?.rated ? ps.gameSettings?.rated : null"
      />
      <ColorSettings
        v-if="showColorSelection()"
        @update="updateColor"
        :initial="ps.gameSettings?.color ? ps.gameSettings?.color : null"
      />
      <TimeSettings
        v-if="showTimeSelection()"
        @update="updateTime"
        :initial="
          ps.gameSettings?.timeControl ? ps.gameSettings?.timeControl : null
        "
      />
    </div>

    <div class="card" style="display: flex">
      <a
        style="
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 0.5rem;
          background-color: var(--clr-dark-accent);
          color: white;
          text-decoration: none;
          padding: 0 0.25rem;
        "
        role="button"
        class="ph-no-capture"
        @click="
          () => {
            showSettings = !showSettings;
            track('start_game_box', 'settings_toggle_icons', 'click');
          }
        "
        :data-bs-original-title="getSettingsTooltip()"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
      >
        <i
          :class="
            'fa-solid ' + (showSettings ? 'fa-caret-up' : 'fa-caret-down')
          "
          style="margin: -0.1rem 0 0 0.2rem; font-size: 1.5rem"
        />
        |
        <i
          v-if="
            !exclueRatedSelection.includes(challengeState) &&
            (ps.gameSettings?.rated == null ||
              ps.gameSettings?.rated == 'rated')
          "
          class="fa-solid fa-arrow-trend-up"
        />
        <i
          v-else-if="!exclueRatedSelection.includes(challengeState)"
          class="fa-duotone fa-flask-round-potion"
        />
        <i
          v-if="
            !excludeColorSelection.includes(challengeState) &&
            ps.gameSettings?.rated == 'unrated' &&
            ps.gameSettings?.color == 'random'
          "
          class="fa-solid fa-circle-half-stroke"
        />
        <i
          v-if="
            !exclueRatedSelection.includes(challengeState) &&
            !excludeColorSelection.includes(challengeState) &&
            ps.gameSettings?.rated == 'unrated' &&
            ps.gameSettings?.color == 'white'
          "
          class="fa-solid fa-circle"
        />
        <i
          v-if="
            !exclueRatedSelection.includes(challengeState) &&
            !excludeColorSelection.includes(challengeState) &&
            ps.gameSettings?.rated == 'unrated' &&
            ps.gameSettings?.color == 'black'
          "
          class="fa-regular fa-circle"
        />
        <i
          v-if="
            !excludeTimeSelection.includes(challengeState) &&
            ps.gameSettings?.timeControl
          "
          class="fa-solid fa-chess-clock-flip"
        />
        <i v-else-if="showTimeSelection()" class="fa-solid fa-infinity" />
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ChallengeState } from "@/types/internaltypes";
import { track } from "@/util/tracking";
import RatedSettings from "@/components/games/RatedSettings.vue";
import TimeSettings from "@/components/games/TimeSettings.vue";
import ColorSettings from "@/components/games/ColorSettings.vue";
import { usePageStore } from "@/stores/pageStore";
import { type PropType, ref } from "vue";
import type { TimeControl } from "@/types/apitypes";

const props = defineProps({
  challengeState: {
    type: Number as PropType<ChallengeState | null>,
    required: true,
  },
});

const ps = usePageStore();

let showSettings = ref(false);

// Since the type is ChallengeState | null, it means that we can check for null with array includes
// the behaviour we want is if the state is null, we want to show the setting, i.e. it's not excluded
let exclueRatedSelection: (ChallengeState | null)[] = [
  ChallengeState.StartingChallenge,
  ChallengeState.StartingCustomChallenge,
  ChallengeState.StartingDailyPosition,
  ChallengeState.StartingPractice,
  ChallengeState.StartingPuzzleSet,
  ChallengeState.ContinuingPuzzleSet,
];

let excludeColorSelection: (ChallengeState | null)[] = [
  ChallengeState.StartingDailyMatchup,
];

let excludeTimeSelection: (ChallengeState | null)[] = [
  ChallengeState.StartingPuzzleSet,
  ChallengeState.ContinuingPuzzleSet,
];

function updateTime(time: TimeControl | null) {
  ps.updateGameSettings(time, undefined, undefined);
}
function updateRated(rated: "rated" | "unrated") {
  ps.updateGameSettings(undefined, rated, undefined);
}
function updateColor(color: "random" | "white" | "black" | null) {
  ps.updateGameSettings(undefined, undefined, color);
}

function getSettingsTooltip() {
  switch (props.challengeState) {
    case ChallengeState.StartingPractice:
    case ChallengeState.StartingCustomChallenge:
      return getSettingsTooltipPractice();
    default:
      return getSettingsTooltipGeneral();
  }
}

function getSettingsTooltipPractice() {
  let tooltip = "Game with ";

  tooltip += ps.gameSettings?.timeControl
    ? "time control " +
      ps.gameSettings?.timeControl.initial / 1000 / 60 +
      " minutes and " +
      ps.gameSettings?.timeControl.increment / 1000 +
      " seconds increment."
    : " no time control.";

  return tooltip;
}

function getSettingsTooltipGeneral() {
  let tooltip = "";

  if (!exclueRatedSelection.includes(props.challengeState)) {
    tooltip +=
      ps.gameSettings?.rated == null || ps.gameSettings?.rated == "rated"
        ? "Rated game with "
        : "Unrated game with ";
  } else {
    tooltip += "Game with ";
  }

  if (!excludeTimeSelection.includes(props.challengeState)) {
    tooltip += ps.gameSettings?.timeControl
      ? "time control " +
        ps.gameSettings?.timeControl.initial / 1000 / 60 +
        " minutes and " +
        ps.gameSettings?.timeControl.increment / 1000 +
        " seconds increment."
      : " no time control.";
  } else {
    tooltip += "no time control.";
  }

  if (
    !excludeColorSelection.includes(props.challengeState) &&
    !exclueRatedSelection.includes(props.challengeState) &&
    ps.gameSettings?.rated == "unrated"
  ) {
    tooltip +=
      " You start with " +
      (ps.gameSettings?.color == "random" || ps.gameSettings?.color == null
        ? "a random color."
        : "the " + ps.gameSettings?.color + " pieces.");
  }

  return tooltip;
}

function showRatedSelection() {
  return !exclueRatedSelection.includes(props.challengeState);
}

function showColorSelection() {
  return (
    !exclueRatedSelection.includes(props.challengeState) &&
    !excludeColorSelection.includes(props.challengeState) &&
    ps.gameSettings?.rated != "rated"
  );
}

function showTimeSelection() {
  return !excludeTimeSelection.includes(props.challengeState);
}
</script>
