<template>
  <div>
    <div class="card">
      <div class="card-body text-center" style="height: 8rem">
        <div
          style="
            display: flex;
            justify-content: center;
            gap: 3rem;
            align-items: center;
            margin-top: 1rem;
          "
        >
          <div style="display: flex; justify-content: center">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: end;
              "
            >
              <span
                ><strong
                  >{{ props.result == null ? "Current" : "New" }} rating</strong
                ></span
              >
              <span
                v-if="!isProvisionalRating(ratingInfo.new == null? ratingInfo.old : ratingInfo.new!)"
                :style="{ fontSize: '2rem', color: getRatingColor() }"
                >{{ getCurrentRating() }}
                <span
                  v-if="result != null"
                  style="color: var(--clr-main-light); font-size: 1rem"
                >
                  ({{ Math.round(ratingInfo.old.rating) }})</span
                ></span
              >
              <span
                v-else
                :style="{ fontSize: '2rem', color: getRatingColor() }"
                data-bs-original-title="Your rating is provisional which means it will move around a lot until you have played some more games. While your rating is provisional it wont be counted towards your rating history."
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                >{{ getCurrentRating()
                }}<span
                  v-if="isProvisionalRating(ratingInfo.new == null? ratingInfo.old : ratingInfo.new!)"
                  style="margin-left: 0.2rem"
                  >?
                </span>
                <span
                  v-if="result != null"
                  style="color: var(--clr-main-light); font-size: 1rem"
                >
                  ({{ Math.round(ratingInfo.old.rating) }})</span
                ></span
              >
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;

              gap: 1rem;
            "
          >
            <div
              :style="{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                opacity: getPotentialOpacity('win'),
              }"
              data-bs-original-title="How much your rating will change if you win the current game"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <span><strong>Win</strong></span>
              <div v-html="getRatingChange(ratingInfo.potential.win)"></div>
            </div>
            <div
              :style="{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                opacity: getPotentialOpacity('draw'),
              }"
              data-bs-original-title="How much your rating will change if you draw the current game"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <span><strong>Draw</strong></span>
              <div v-html="getRatingChange(ratingInfo.potential.draw)"></div>
            </div>
            <div
              :style="{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                opacity: getPotentialOpacity('loss'),
              }"
              data-bs-original-title="How much your rating will change if you lose the current game"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <span><strong>Lose</strong></span>
              <div v-html="getRatingChange(ratingInfo.potential.loss)"></div>
            </div>
          </div>
        </div>
        <div class="profile-container d-flex flex-row gap-5">
          <div
            class="card profile-name-text"
            :style="{ backgroundColor: opponentColor }"
          >
            <div class="card-body" style="margin-top: -0.75rem">Rated</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChallengeState } from "@/types/internaltypes";
import type { PropType } from "vue";
import { Tooltip as BsTooltip } from "bootstrap";
import type { Rating } from "@/types/apitypes";
import { isProvisionalRating } from "@/util/ratings";

const props = defineProps({
  result: {
    type: Object as PropType<"win" | "draw" | "loss" | null>,
    default: null,
  },
  opponentColor: { type: String, default: "#fff" },
  challengeState: {
    type: Number as PropType<ChallengeState | null>,
    default: null,
  },
  ratingBot: { type: Number, required: true },
  ratingInfo: {
    type: Object as PropType<{
      potential: {
        win: number;
        draw: number;
        loss: number;
      };
      old: Rating;
      new?: Rating;
    }>,
    required: true,
  },
});

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

function getCurrentRating() {
  if (props.ratingInfo.new != null) {
    return Math.round(props.ratingInfo.new.rating);
  }

  return Math.round(props.ratingInfo.old.rating);
}

function getRatingColor() {
  if (
    props.result != null &&
    props.ratingInfo.new != null &&
    props.ratingInfo.old != null
  ) {
    let positveChange =
      props.ratingInfo.new.rating - props.ratingInfo.old.rating;

    if (positveChange) {
      return "var(--clr-accent4)";
    } else {
      return "var(--clr-rect-3)";
    }
  } else {
    return "var(--clr-main-light)";
  }
}

function getPotentialOpacity(result: string) {
  let opacity = "1.0";

  if (props.result != null) {
    switch (result) {
      case "win":
        opacity = props.result === "win" ? "1.0" : "0.25";
        break;
      case "draw":
        opacity = props.result === "draw" ? "1.0" : "0.25";
        break;
      case "loss":
        opacity = props.result === "loss" ? "1.0" : "0.25";
        break;
    }
  }

  return opacity;
}

// Not sure if this is a good way to generate elements, but it works. Maybe JSX would be better?
function getRatingChange(ratingChange: number) {
  let color = ratingChange >= 0 ? "--clr-accent4" : "--clr-rect-3";
  let plusSign = ratingChange >= 0 ? "+" : "";

  return `<span style="color: var(${color})">${plusSign}${ratingChange}</span>`;
}
</script>

<style scoped>
.profile-name-text {
  height: 2rem;
  width: 8rem;
  text-align: center;
}

.profile-container {
  position: absolute;
  top: -0.75rem;
  left: 1rem;
}
</style>
