<template>
  <div
    style="
      line-height: 0;
      position: absolute;
      right: 0;
      top: 3.2rem;
      text-align: right;
      opacity: 0.3;
      z-index: -1;
    "
  >
    <!-- Repeat this div 100 times for 10x10 mosaic -->
    <div class="square"></div>
    <div class="square"></div>
    <div class="square"></div>
    <div class="square"></div>
    <div class="square"></div>
    <br />
    <div class="square"></div>
    <div class="square"></div>
    <div class="square"></div>
    <br />
    <div class="square"></div>
    <div class="square"></div>
    <br />
    <div class="square"></div>

    <!-- ... -->
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.mosaic {
  display: grid;
  grid-template-columns: repeat(10, 10rem);
  grid-gap: 0.5rem;
}

.square {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  background-color: var(--color);
}

.square:nth-child(1) {
  --color: #5cc0c6;
}
.square:nth-child(2) {
  --color: #3fb2be;
}
.square:nth-child(3) {
  --color: #4895ac;
}
.square:nth-child(4) {
  --color: #3d4175;
}
.square:nth-child(5) {
  --color: #6b4580;
}
.square:nth-child(6) {
  --color: #ff0;
}
.square:nth-child(7) {
  --color: #3ca5b7;
}
.square:nth-child(8) {
  --color: #3e87a8;
}
.square:nth-child(9) {
  --color: #3a4f8f;
} /* Khaki */
.square:nth-child(10) {
  --color: #ff0;
} /* LightCyan */
.square:nth-child(11) {
  --color: #2f6a8b;
} /* LightCyan */
.square:nth-child(12) {
  --color: #344686;
} /* LightCyan */
.square:nth-child(13) {
  --color: #ff0;
} /* LightCyan */
.square:nth-child(14) {
  --color: #223565;
} /* LightCyan */
</style>
