<template>
  <div class="faq-wrapper">
    <div class="faq-title">
      <h4>
        Frequently Asked
        <span style="color: var(--clr-accent)">Questions</span>
      </h4>
    </div>
    <div class="faq-body">
      <div
        class="faq-section"
        v-for="(faqsection, index) of faqs"
        :key="'faq-content' + index"
      >
        <div
          class="card faq-qa ph-no-capture"
          v-for="(qa, qaindex) of faqsection.qas"
          :key="'faq-qa' + qaindex"
          @click="
            () => {
              selectQa(qa.id);
              track('faq', 'load_more_games', 'click', { selectedQa: qa.id });
            }
          "
          role="button"
        >
          <div class="qa-header">
            <h4>{{ qa.question }}</h4>
            <i v-if="qa.selected" class="fa-solid fa-caret-up" />
            <i v-else class="fa-solid fa-caret-down" />
          </div>
          <Transition name="bounce">
            <p v-if="qa.selected" style="margin: 0" v-html="qa.answer" />
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { track } from "@/util/tracking";

function selectQa(qaId: number) {
  for (let faqsection of faqs.value) {
    for (let qa of faqsection.qas) {
      if (qa.id === qaId) {
        qa.selected = !qa.selected;
      } else {
        qa.selected = false;
      }
    }
  }
}

let faqs = ref<any>([
  {
    section: "Payments",
    qas: [
      {
        id: 1,
        selected: false,
        question: "What happens if I cancel my premium subscription?",
        answer:
          "You will revert to the Free plan, but you can always upgrade again anytime.",
      },
      {
        id: 5,
        selected: false,
        question:
          "Will I be able to access my old games if I cancel my Premium subscription?",
        answer:
          "Yes, any games played, challenges completed or achievements accomplished while on the Premium plan will still be there if you cancel your subscription.",
      },
      {
        id: 2,
        selected: false,
        question: "Can I switch from a monthly to a yearly subscription?",
        answer:
          "Yes, you can switch plans from your account settings at any time.",
      },
      {
        id: 3,
        selected: false,
        question: "Is there a trial period for the Subscription plans?",
        answer:
          "Yes! For the monthly and yearly subscription plans there's an automatic 7-day, no-strings-attached, free trial. Just cancel before the trial ends and you won't be charged anything.",
      },
      {
        id: 4,
        selected: false,
        question: "Is there a trial period for the Lifetime plan?",
        answer:
          "There's no automatic trial period for the Lifetime plan, but if you for some reason are not satisfied within the first 7 day, just contact us for a full no-questions-asked refund.",
      },
    ],
  },
]);
</script>

<style scoped>
.bounce-enter-active {
  transform-origin: top;
  animation: bounce-in 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}
.profile-selector-container {
  cursor: pointer;
}
h2,
h3,
h4 {
  font-size: 2.2rem;
  text-align: center;
}

.faq-body h3 {
  font-size: 2rem;
}

.faq-body h4 {
  font-size: 1.2rem;
  text-align: left;
}

.faq-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.faq-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.faq-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.faq-qa {
  padding: 1rem;
  width: 40rem;
}

@media (max-width: 768px) {
  .faq-qa {
    width: 90%;
  }
}

.qa-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: var(--clr-accent);
}

.faq-qa p {
  color: var(--clr-main-light);
}

.qa-header i {
  margin-top: -0.5rem;
  color: var(--clr-main-lighter);
  font-size: 1.5rem;
}
</style>
