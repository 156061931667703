<template>
  <div
    class="card hints-box"
    :style="{ backgroundColor: opponentColor, fontWeight: '700' }"
    title="Remaining hints. Gain more by completing two puzzles in a row without using hints. An unlit bulb means you will get another hint if you finish the next puzzle without hints."
    data-bs-toggle="tooltip"
    data-bs-placement="bottom"
  >
    <div
      class="card-body"
      style="margin-top: -0.75rem; display: flex; gap: 0.1rem"
    >
      <span style="margin-right: 0.75rem">Hints:</span>
      <i v-if="hint1Show" :class="hint1Classes" />
      <i v-if="hint2Show" :class="hint2Classes" />
      <i v-if="hint3Show" :class="hint3Classes" />
      <i v-if="hintHalfShow" :class="hintHalfClasses" />
      <span v-if="hintNoShow">-</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { usePageStore } from "@/stores/pageStore";

const ps = usePageStore();

const props = defineProps({
  opponentColor: { type: String, default: "#fff" },
});

watch(
  () => ps.puzzleHintsChanged,
  () => {
    updateBulbs();
  },
  { deep: true }
);

onMounted(() => {
  updateBulbs();
});

let hint1Classes = ref("fa-solid fa-lightbulb lighted");
let hint1Show = ref(false);
let hint2Classes = ref("fa-solid fa-lightbulb lighted");
let hint2Show = ref(false);
let hint3Classes = ref("fa-solid fa-lightbulb lighted");
let hint3Show = ref(false);
let hintHalfClasses = ref("fa-regular fa-lightbulb");
let hintHalfShow = ref(false);
let hintNoShow = ref(false);

function updateBulbs(): void {
  // Start with updating what bulbs to show, if needed we adjust this below

  hintHalfShow.value =
    Math.floor(ps.puzzleHintsChanged.current) !=
      ps.puzzleHintsChanged.current && ps.puzzleHintsChanged.current < 3;
  hint1Show.value = ps.puzzleHintsChanged.current >= 1;
  hint2Show.value = ps.puzzleHintsChanged.current >= 2;
  hint3Show.value = ps.puzzleHintsChanged.current >= 3;
  hintNoShow.value = ps.puzzleHintsChanged.current == 0;

  // Now check for changes and if we need to add animations

  if (
    ps.puzzleHintsChanged.change == 0.5 &&
    Math.floor(ps.puzzleHintsChanged.current) != ps.puzzleHintsChanged.current
  ) {
    // Increase was 0.5, and we have a half bulb, so we gained a half bulb
    hintHalfClasses.value = "fa-regular fa-lightbulb fade-in";
  } else if (ps.puzzleHintsChanged.change == 0.5) {
    // Increase was 0.5 and we don't have half a bulb, so we gained a full bulb

    usePageStore().setPlaySound("get-hint");

    switch (ps.puzzleHintsChanged.current) {
      case 1:
        hint1Classes.value = "fa-solid fa-lightbulb lighted bounce-top-zoom";
        break;
      case 2:
        hint2Classes.value = "fa-solid fa-lightbulb lighted bounce-top-zoom";
        break;
      case 3:
        hint3Classes.value = "fa-solid fa-lightbulb lighted bounce-top-zoom";
        break;
      default:
        console.error(
          "Gained some other number?",
          ps.puzzleHintsChanged.current
        );
    }
  } else if (ps.puzzleHintsChanged.change == -0.5) {
    // This should not happen, we can't lose only the half bulb since that would mean that we missed something
    // in the previous puzzle and the half bulb would've disappeared together with the full one below
  } else if (
    ps.puzzleHintsChanged.change == -1 ||
    ps.puzzleHintsChanged.change == -1.5
  ) {
    // We're not playing sound here because we need to differentiate between using a hint and making the wrong move, which we don't know here

    switch (ps.puzzleHintsChanged.current) {
      case 0:
        hintNoShow.value = false;
        hint1Show.value = true;
        hint1Classes.value = "fa-solid fa-lightbulb lighted shake-and-fade-out";

        setTimeout(() => {
          hintNoShow.value = true;
          hint1Show.value = false;
        }, 2000);
        break;
      case 1:
        hint2Show.value = true;
        hint2Classes.value = "fa-solid fa-lightbulb lighted shake-and-fade-out";
        setTimeout(() => {
          hint2Show.value = false;
        }, 2000);
        break;
      case 2:
        hint3Show.value = true;
        hint3Classes.value = "fa-solid fa-lightbulb lighted shake-and-fade-out";
        setTimeout(() => {
          hint3Show.value = false;
        }, 2000);
        break;
      default:
        console.error("Lost some other number?", ps.puzzleHintsChanged.current);
        break;
    }
  }
}
</script>
<style scoped>
.hints-box {
  height: 2rem;
  width: 8rem;
}

.lighted {
  color: yellow;
  filter: drop-shadow(0 0 1px black);
}

.shake-and-fade-out {
  animation: shake-and-fade-out 2s ease-in;
}

@keyframes shake-and-fade-out {
  5%,
  45% {
    transform: translateX(-1px) rotate(-0.1deg);
  }

  10%,
  40% {
    transform: translateX(2px) rotate(0.2deg);
  }

  15%,
  25%,
  35% {
    transform: translateX(-4px) rotate(-0.4deg);
  }

  20%,
  30% {
    transform: translateX(4px) rotate(0.4deg);
  }
  50% {
    opacity: 1;
    transform: scale(100%);
  }
  75% {
    transform: scale(200%);
  }
  100% {
    opacity: 0;
    transform: scale(0%);
  }
}

.fade-in {
  animation: fade-in 1s ease-in;
}

@keyframes fade-in {
  0% {
    transform: scale(0%);
    opacity: 0;
  }
  25% {
    transform: scale(300%);
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

.bounce-top-zoom {
  -webkit-animation: bounce-top-zoom 1s both;
  animation: bounce-top-zoom 1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-9-24 17:43:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@keyframes bounce-top-zoom {
  0% {
    transform: scale(500%) translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  40% {
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(100%) translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
</style>
