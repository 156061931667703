<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <div id="lose-animation-text" class="lose-text">
      {{ termination === GameTermination.Time ? "Time" : "Lost" }}
      <i
        v-if="termination === GameTermination.Time"
        class="fa-solid fa-chess-clock-flip bounce-top"
      ></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, type PropType } from "vue";
import { usePageStore } from "@/stores/pageStore";
import { GameTermination } from "@/types/apitypes";
import { UserInput } from "@/types/internaltypes";

const props = defineProps({
  termination: { type: Number as PropType<GameTermination> },
});

const emit = defineEmits(["isRunning"]);

function start() {
  emit("isRunning", { isRunning: true });

  usePageStore().setPlaySound(
    props.termination === GameTermination.Time ? "end-of-time" : "lose-sound-2"
  );

  let innerTextElement = document.getElementById("lose-animation-text");

  if (!innerTextElement) {
    return;
  }

  innerTextElement.classList.add("flip-in-hor-bottom");

  function animation1End() {
    if (!innerTextElement) {
      return;
    }
    innerTextElement.classList.remove("flip-in-hor-bottom");
    innerTextElement.classList.add("flip-out-hor-top");
    innerTextElement.removeEventListener("animationend", animation1End);
    innerTextElement.addEventListener("animationend", animation2End);
  }

  function animation2End() {
    if (!innerTextElement) {
      return;
    }
    emit("isRunning", { isRunning: false });
    innerTextElement.removeEventListener("animationend", animation2End);
  }

  innerTextElement.addEventListener("animationend", animation1End);
}

onMounted(() => {
  start();
});
</script>

<style scoped>
@media (max-width: 359px) {
  .lose-text {
    font-size: 6rem;
  }
}
</style>

<style>
.flip-out-hor-top {
  -webkit-animation: flip-out-hor-top 0.45s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 1.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:18:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:26:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    opacity: 0;
  }
}

#text-spot {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.lose-text {
  display: inline-block;
  font-size: 120px;
  color: #ff7171;
  letter-spacing: 10px;
  font-family: "Luckiest Guy";
  font-weight: 100;
  -webkit-text-stroke: 2px black;
}
</style>
