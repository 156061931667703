import { defineStore } from "pinia";

// If the background is changed, it's picked up as a mutation in App.vue and set on the body element
export const useBackgroundStore = defineStore("background", {
  state: () => ({
    background: null as string | null,
  }),
  actions: {
    setBackground(newBackground: string | null) {
      this.background = newBackground;
    },
  },
});
