import type { ChatTriggerId, Game } from "@/types/apitypes";

// Checks if something has happened in the game that should trigger a chat message
// NOTE: This function is currently called on the user's turn, just after the engine has moved, so the user has just made a move
// so we're assuming that for example game.moves[game.moves.length - 1] is the move the engine just made and game.moves[game.moves.length - 2] is the move the user just made
// If this is called on both sides turn in the future, this needs to be adjusted.
export async function getChatTriggerIdFromGame(game: Game): Promise<{
  id: ChatTriggerId;
  data: Record<string, string> | undefined;
} | null> {
  let triggerId: ChatTriggerId | null = null;
  let data: Record<string, string> | undefined = undefined;

  if (game.moves.length <= 1) {
    // Start of game. Since we're calling this function on the user's turn till will be either 0 (if user is white) or 1 (if user is black, which means the engine moved first)

    if (game.challenge != null) {
      // If this is some type of challenge game, like a daily matchup or practice, it's not really interesting what side
      // the user got since it wasn't a direct option
      // So only do the following for non-challenge games

      triggerId = game.rated ? "start_of_game_rated" : "start_of_game_casual";
    }
  } else {
    const potentialTriggers = await Promise.all([
      getPotentialTriggers(game, game.moves.length - 1, "_bot"),
      getPotentialTriggers(game, game.moves.length - 2, "_user"),
    ]);
    const potentialTriggersAfterBotMove = potentialTriggers[0];
    const potentialTriggersAfterUserMove = potentialTriggers[1];

    const chosenTrigger = choosePotentialTrigger(
      potentialTriggersAfterBotMove,
      potentialTriggersAfterUserMove
    );
    if (chosenTrigger != null) {
      triggerId = chosenTrigger?.triggerId;
      data = chosenTrigger?.data;
    }
  }

  return triggerId == null ? null : { id: triggerId, data: data };
}

async function getPotentialTriggers(
  game: Game,
  moveIndex: number,
  suffix: "_bot" | "_user" // Suffix to add on some triggers ids to indicate if it's for the bot or the user
) {
  const potentialTriggers: {
    triggerId: ChatTriggerId;
    data: Record<string, string> | undefined;
  }[] = [];

  const moveSan = game.movesSan[moveIndex];
  const moveLan = game.moves[moveIndex];

  if (moveSan.includes("+")) {
    potentialTriggers.push({
      triggerId: ("check" + suffix) as ChatTriggerId,
      data: undefined,
    });
  }

  if (moveSan.includes("O-O") && !moveSan.includes("O-O-O")) {
    potentialTriggers.push({
      triggerId: ("castle_short" + suffix) as ChatTriggerId,
      data: undefined,
    });
  }

  if (moveSan.includes("O-O-O")) {
    potentialTriggers.push({
      triggerId: ("castle_long" + suffix) as ChatTriggerId,
      data: undefined,
    });
  }

  if (moveLan.endsWith("q")) {
    potentialTriggers.push({
      triggerId: ("promption_queen" + suffix) as ChatTriggerId,
      data: undefined,
    });
  } else if (moveLan.length > 4) {
    // Since we're in else if, we know it's not queen promotion, and length > 4 means it's a promotion
    potentialTriggers.push({
      triggerId: ("underpromotion" + suffix) as ChatTriggerId,
      data: undefined,
    });
  }

  const newGamePhase = gamePhaseChanged(
    game.positions[moveIndex - 1],
    game.positions[moveIndex]
  );
  if (newGamePhase == "middle") {
    potentialTriggers.push({
      triggerId: ("entered_middlegame" + suffix) as ChatTriggerId,
      data: undefined,
    });
  } else if (newGamePhase == "ending") {
    potentialTriggers.push({
      triggerId: ("entered_ending" + suffix) as ChatTriggerId,
      data: undefined,
    });
  }

  if (
    lostCastling(
      moveSan,
      game.positions[moveIndex - 1],
      game.positions[moveIndex]
    )
  ) {
    potentialTriggers.push({
      triggerId: ("lost_castling" + suffix) as ChatTriggerId,
      data: undefined,
    });
  }

  if (moveSan.includes("x")) {
    const fromSquare = moveLan.substring(0, 2);
    const toSquare = moveLan.substring(2, 4);
    const capturingPiece = pieceOnSquare(
      game.positions[moveIndex - 1],
      fromSquare
    );
    const capturedPiece = pieceOnSquare(
      game.positions[moveIndex - 1],
      toSquare
    );

    if (
      capturingPiece != null &&
      capturingPiece != "" &&
      capturedPiece != null
    ) {
      if (capturingPiece.toLowerCase() == "p" && capturedPiece == "") {
        potentialTriggers.push({
          triggerId: ("en_passant" + suffix) as ChatTriggerId,
          data: undefined,
        });
      } else {
        let triggerType = "trade"; // Default to trade, means getPieceValues are equal
        if (getPieceValue(capturingPiece) < getPieceValue(capturedPiece)) {
          triggerType = "capture_gain";
        } else if (
          getPieceValue(capturingPiece) > getPieceValue(capturedPiece)
        ) {
          triggerType = "capture_loss";
        }

        potentialTriggers.push({
          triggerId: (triggerType + suffix) as ChatTriggerId,
          data: {
            capturing_piece: getPieceName(capturingPiece),
            captured_piece: getPieceName(capturedPiece),
          },
        });
      }
    }
  }

  if (moveIndex >= 1 && game.moves.length <= 30) {
    const openingNames = await Promise.all([
      getOpeningName(
        game.moves.slice(0, moveIndex).join(","),
        game.startPosition
      ),
      getOpeningName(
        game.moves.slice(0, moveIndex + 1).join(","),
        game.startPosition
      ),
    ]);
    const openingOnPreviousMove = openingNames[0];
    const openingOnThisMove = openingNames[1];

    if (
      openingOnPreviousMove != null &&
      openingOnThisMove != null &&
      openingOnPreviousMove != openingOnThisMove
    ) {
      potentialTriggers.push({
        triggerId: "named_opening_variation",
        data: { opening_name: openingOnThisMove },
      });
    }
  }
  return potentialTriggers;
}

function getPieceName(piece: string): string {
  switch (piece.toLowerCase()) {
    case "p":
      return "pawn";
    case "n":
      return "knight";
    case "b":
      return "bishop";
    case "r":
      return "rook";
    case "q":
      return "queen";
    case "k":
      return "king";
    default:
      return "";
  }
}

function getPieceValue(piece: string): number {
  switch (piece.toLowerCase()) {
    case "p":
      return 1;
    case "n":
    case "b":
      return 3;
    case "r":
      return 5;
    case "q":
      return 9;
    default:
      return 0;
  }
}

function choosePotentialTrigger(
  potentialTriggersAfterBotMove: {
    triggerId: ChatTriggerId;
    data: Record<string, string> | undefined;
  }[],
  potentialTriggersAfterUserMove: {
    triggerId: ChatTriggerId;
    data: Record<string, string> | undefined;
  }[]
): {
  triggerId: ChatTriggerId;
  data: Record<string, string> | undefined;
} | null {
  const triggersWithChance = [];

  for (let i = 0; i < potentialTriggersAfterBotMove.length; i++) {
    const triggerConfigIndex = gameEventsConfig.findIndex(
      (t) => t.trigger == potentialTriggersAfterBotMove[i].triggerId
    );

    triggersWithChance.push({
      trigger: potentialTriggersAfterBotMove[i],
      prio: gameEventsConfig.length - triggerConfigIndex,
      chance: gameEventsConfig[triggerConfigIndex]?.triggerChance ?? 0,
    });
  }
  const userMoveDownPrio = 0.2; // Lower chance to trigger user moves
  for (let i = 0; i < potentialTriggersAfterUserMove.length; i++) {
    const triggerConfigIndex = gameEventsConfig.findIndex(
      (t) => t.trigger == potentialTriggersAfterUserMove[i].triggerId
    );

    triggersWithChance.push({
      trigger: potentialTriggersAfterUserMove[i],
      prio: gameEventsConfig.length - triggerConfigIndex,
      chance:
        gameEventsConfig[triggerConfigIndex]?.triggerChance -
          userMoveDownPrio ?? 0,
    });
  }

  triggersWithChance.sort((a, b) => b.prio - a.prio);

  let chosenTrigger;
  for (let i = 0; i < triggersWithChance.length; i++) {
    if (Math.random() < triggersWithChance[i].chance) {
      chosenTrigger = triggersWithChance[i].trigger;
    }
  }

  return chosenTrigger ?? null;
}

async function getOpeningName(
  commaSeparatedSanMoves: string,
  startPosition: string | undefined
): Promise<string | null> {
  const fen = startPosition ? `&fen=${startPosition}` : "";
  return (
    (
      await (
        await fetch(
          `https://explorer.lichess.ovh/masters?play=${commaSeparatedSanMoves}${fen}`
        )
      ).json()
    ).opening?.name ?? null
  );
}

function pieceOnSquare(fen: string, square: string): string | null {
  // Split the FEN string to get the piece placement part
  const piecePlacement = fen.split(" ")[0];

  // Convert the FEN ranks into an array of ranks
  const ranks = piecePlacement.split("/");

  // Convert algebraic notation (e.g., "e4") into array indices
  const file = square.charCodeAt(0) - "a".charCodeAt(0); // 'a' -> 0, 'b' -> 1, ..., 'h' -> 7
  const rank = 8 - parseInt(square[1]); // '8' -> 0, '7' -> 1, ..., '1' -> 7

  // Validate the square input
  if (file < 0 || file > 7 || rank < 0 || rank > 7) {
    return null;
  }

  // Expand ranks with numbers into full piece representations
  const expandedRanks = ranks.map((rank) =>
    rank.replace(/\d/g, (num) => ".".repeat(parseInt(num)))
  );

  // Extract the piece from the expanded rank
  const piece = expandedRanks[rank][file];

  // Return the piece or indicate the square is empty
  return piece === "." ? "" : piece;
}

function gamePhaseChanged(
  previousPosition: string,
  currentPosition: string
): GamePhase | null {
  const phasePrevious: GamePhase | null = gamePhase(previousPosition);
  const phaseNow: GamePhase | null = gamePhase(currentPosition);

  if (phasePrevious == null || phaseNow == null) {
    return null;
  } else if (phaseNow == "opening") {
    // Phases shouldn't go backwards, so whatever previous was, it's shouldn't have moved to opening
    return null;
  } else if (phaseNow == "middle" && phasePrevious === "ending") {
    // Moved backwards from ending
    return null;
  }

  return phasePrevious != phaseNow ? phaseNow : null;
}

type GamePhase = "opening" | "middle" | "ending";

function gamePhase(fen: string): GamePhase | null {
  try {
    const pieceValues: Record<string, number> = {
      n: 1,
      N: 1,
      b: 1,
      B: 1,
      r: 2,
      R: 2,
      q: 4,
      Q: 4,
    };

    const positionStr = fen.split(" ")[0];
    let sum = 0;

    for (const char of positionStr) {
      if (char in pieceValues) {
        sum += pieceValues[char];
      }
    }

    if (sum <= 8) return "ending";
    else if (sum > 20) return "opening";
    else return "middle";
  } catch (e) {
    // If we can't parse the FEN, we'll just return null
    return null;
  }
}

// We define lost castling as being in the opening or middle game, and the castling right changing with the king moving.
// Of course a side "loses" castling when they castle too, or when they bring the king out in the endgame, but that's not what we're checking for here.
function lostCastling(
  moveSan: string,
  positionBeforeMove: string,
  positionAfterMove: string
): boolean {
  try {
    if (gamePhase(positionAfterMove) == "ending") {
      return false;
    }

    const castlingRightsBeforeMove: string | undefined =
      positionBeforeMove.split(" ")[2];
    const castlingRightsAfterMove: string | undefined =
      positionAfterMove.split(" ")[2];

    castlingRightsBeforeMove.includes("KQ");

    return (
      (moveSan?.startsWith("K") &&
        castlingRightsBeforeMove?.includes("KQ") &&
        !castlingRightsAfterMove?.includes("KQ")) ||
      (castlingRightsBeforeMove?.includes("kq") &&
        !castlingRightsAfterMove?.includes("kq"))
    );
  } catch (e) {
    // If we somehow fail here, positions being undefined or similar, just return false
    return false;
  }
}

const gameEventsConfig: { trigger: ChatTriggerId; triggerChance: number }[] = [
  { trigger: "named_opening_variation", triggerChance: 1 },
  { trigger: "check_bot", triggerChance: 0.8 },
  { trigger: "check_user", triggerChance: 0.8 },
  { trigger: "en_passant_bot", triggerChance: 0.8 },
  { trigger: "en_passant_user", triggerChance: 0.8 },
  { trigger: "castle_short_bot", triggerChance: 0.8 },
  { trigger: "castle_long_bot", triggerChance: 0.8 },
  { trigger: "castle_short_user", triggerChance: 0.8 },
  { trigger: "castle_long_user", triggerChance: 0.8 },
  { trigger: "promption_queen_bot", triggerChance: 0.8 },
  { trigger: "promotion_queen_user", triggerChance: 0.8 },
  { trigger: "underpromotion_bot", triggerChance: 0.8 },
  { trigger: "underpromotion_user", triggerChance: 0.8 },
  { trigger: "capture_gain_bot", triggerChance: 0.8 },
  { trigger: "capture_gain_user", triggerChance: 0.8 },
  { trigger: "lost_castling_bot", triggerChance: 0.8 },
  { trigger: "lost_castling_user", triggerChance: 0.8 },
  { trigger: "capture_loss_bot", triggerChance: 0.5 },
  { trigger: "capture_loss_user", triggerChance: 0.5 },
  { trigger: "trade_bot", triggerChance: 0.3 },
  { trigger: "trade_user", triggerChance: 0.3 },
  { trigger: "entered_middlegame", triggerChance: 0.5 },
  { trigger: "entered_endgame", triggerChance: 0.5 },
  // { trigger: "out_of_book", triggerChance: 0.0 },
  // { trigger: "blunder_bot", triggerChance: 0.0 },
  // { trigger: "blunder_user", triggerChance: 0.0 },
];
