<template>
  <div class="carousel-wrapper">
    <div style="width: 100%">
      <Carousel
        :itemsToShow="itemsToShow()"
        :wrapAround="true"
        :transition="500"
        :autoplay="autoplay"
        :pauseAutoplayOnHover="true"
        @slide-end="onSlideEnd"
      >
        <Slide v-for="bot in bots" :key="bot.name">
          <div class="flex-column">
            <ProfileCard
              :botMock="{
                name: bot.name,
                countryCode: bot.country.code,
                countryName: bot.country.name,
                rating: bot.rating,
                botId: bot.images,
                occupation: bot.occupation,
              }"
              :display-occupation="true"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
      <div
        class="d-flex justify-content-center"
        :style="{ marginTop: '-2rem' }"
      >
        <div class="w-75 d-flex justify-content-center flex-column">
          <div
            class="card p-4 quote-bg"
            ref="quoteBox"
            :style="{
              minHeight: quoteBoxHeight() + 'rem',
              padding: '0 1rem 0 1rem !important',
            }"
          >
            <TypeWriter
              v-if="!toggledMore"
              :targetText="bots[currentSlide].text"
              :style="'height: 6rem; width: 100%; margin: auto; margin-top: 1rem; cursor: pointer'"
              :pClasses="['card-text', 'text-center']"
              :newTextDelay="0"
            />
            <div
              v-else
              :style="{
                padding: '1rem 2rem 1rem 2rem !important',
              }"
            >
              <p>
                {{ bots[currentSlide].age }} years old,
                {{ bots[currentSlide].occupation }} from
                {{ bots[currentSlide].country.name }}
                <img
                  :src="getFlagUrl(bots[currentSlide].country.code)"
                  :alt="bots[currentSlide].country.name"
                  style="height: 0.7rem; margin: -0.2rem 0.2rem 0 0"
                />
              </p>
              <p>
                Rated <strong>{{ bots[currentSlide].rating }} Elo</strong> and
                likes to open with
                {{ bots[currentSlide].openings }}
              </p>
            </div>
          </div>
          <a
            type="button"
            class="btn btn-info ph-no-capture"
            @click="
              () => {
                toggleMore();
                track('landing_carousel', 'load_more_button', 'click');
              }
            "
            :style="{
              width: '8rem',
              margin: '1rem auto',
            }"
          >
            {{ toggledMoreText() }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TypeWriter from "@/components/util/TypeWriter.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { ref } from "vue";
import { getFlagUrl, isMobileWidth } from "@/util/util";
import ProfileCard from "@/components/common/ProfileCard.vue";
import { track } from "@/util/tracking";

let currentSlide = ref(0);
let autoplay = ref(8000);
let toggledMore = ref(false);
let quoteBox = ref(null);
let quoteBoxPadding = ref();

function toggleMore() {
  toggledMore.value = !toggledMore.value;

  if (toggledMore.value) {
    // @ts-ignore (complains about quoteBox and then classList possibly not being there, it will be)
    quoteBox.value.classList.remove("quote-bg");
    quoteBoxPadding.value = "1rem 2rem 1rem 1rem !important";
  } else {
    // @ts-ignore (complains about quoteBoxand then classList possibly not being there, it will be)
    quoteBox.value.classList.add("quote-bg");
    quoteBoxPadding.value = "0 1rem 0 1rem !important";
  }
  autoplay.value = toggledMore.value ? 0 : 5000;
}

function toggledMoreText() {
  return toggledMore.value
    ? "Back"
    : "More on " + bots[currentSlide.value].name.split(" ")[0];
}

function itemsToShow() {
  if (isMobileWidth()) {
    return 2.5;
  } else {
    return 3.6;
  }
}

function quoteBoxHeight() {
  if (isMobileWidth()) {
    return 14.5;
  } else {
    return 10;
  }
}

let bots = [
  {
    text: "Oh my gosh, I tried Chessiverse and seriously? It's like, sooo basic. I mean, I won like, a million games on there because everyone else is just bad. If you want to feel good about yourself, sure, go play on it. But if you're as awesome as me, it's kinda boring. 😜🙄🇨🇦",
    name: "Ethan Snide",
    occupation: "Obnoxious kid",
    images: "2",
    age: "8",
    country: { name: "Canada", code: "ca" },
    rating: 205,
    openings:
      "the Ponziani, Open Sicilian, Tarrasch, Dragon, and Old Indian Defence",
  },
  {
    text: "G'day! Came across Chessiverse between my botany expeditions. Brilliant blend of chess and tropical nature's beauty. Not just a game, it's a call to cherish and protect our environment. A true gem for nature-lovers and chess enthusiasts. 🌿🌺🌴🌸",
    name: "Maddy Bishantly",
    occupation: "Botanist",
    images: "26",
    age: "25",
    country: { name: "Australia", code: "au" },
    rating: 1331,
    openings: "the Reti, Taimanov, and King's Indian Defence",
  },
  {
    text: "Ah, Chessiverse! A fine digital forest for chess lovers. Its interface is as clear as a Norwegian fjord, but I wish they had more tutorials on forest-themed openings. Good for sharpening the axe, though. Skål!",
    name: "Geir Sjakk",
    occupation: "Lumberjack",
    images: "136",
    age: "53",
    country: { name: "Norway", code: "no" },
    rating: 452,
    openings: "Jobava London, Giuoco Piano, and the Budapest Gambit",
  },
  {
    text: "What a delightful platform! Gave me inspiration for a new boardgame prototype. Their innovative approach? Simply ingenious. I just wish they'd implement some of my quirky tech ideas. The future is bright for them! Eureka! 🌟🔧🇿🇦",
    name: "Pat Pending",
    occupation: "Inventor",
    images: "75",
    age: "58",
    country: { name: "South Africa", code: "za" },
    rating: 1880,
    openings:
      "the Vienna Game, Open Sicilian, Paulsen, Caro-Kann Advance Variation, Kan, and Ragozin Defence",
  },
  {
    text: "Chessiverse! Una meraviglia! Its interface is sleek, with a touch of modern design. The backend, molto efficiente. But, they could optimize some scripts for smoother gameplay. Truly a blend of art and technology. Bravo! 🖥🇮🇹",
    name: "Ana Logica",
    occupation: "Computer Technician",
    images: "142",
    age: "35",
    country: { name: "Italy", code: "it" },
    rating: 1708,
    openings:
      "the English, Sicilian Classical Variation, and the Old Indian Defence",
  },
  {
    text: "Chessiverse... Like the ebb and flow of the sea, the games take me on a journey. Each piece reminds me of the ships I've watched over. A digital beacon in the vast internet sea. Brings warmth on lonely nights. 🌌🌊",
    name: "Bianck Castlinga",
    occupation: "Lighthouse Keeper",
    images: "117",
    age: "70",
    country: { name: "Latvia", code: "lv" },
    rating: 1524,
    openings:
      "the Taimanov, Indian Defense: Knights Variation, and Ragozin Defence",
  },
  {
    text: "Chessiverse? Impressive server distribution, great latency. Their mobile optimization is top-notch, but they could improve CDN deployment for faster content delivery. Connectivity-wise? Muito bom! 👍📱",
    name: "Dane Roaming",
    occupation: "Network Engineer",
    images: "162",
    age: "29",
    country: { name: "Brazil", code: "br" },
    rating: 1983,
    openings: "the Trompowsky, Kalashnikov, and the Queen's Gambit Accepted",
  },
  {
    text: "Oh man, Chessiverse? Super innovative platform! Though, they should totally tokenize their ranking system – massive potential there! Always on the lookout for the next big trend, and Chessiverse is it! 🚀",
    name: "Coins",
    occupation: "Cryptocurrency Broker",
    images: "94",
    age: "28",
    country: { name: "USA", code: "us" },
    rating: 812,
    openings:
      "the Najdorf, Indian Defense: Normal Variation, and the King's Indian Defence",
  },
];

function onSlideEnd(data: any) {
  currentSlide.value = data.currentSlideIndex;
}
</script>

<style scoped>
.carousel__slide {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  z-index: 10;
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
.quote-bg {
  background-image: url("/src/assets/images/quote.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  width: 40rem;
}

@media (max-width: 768px) {
  .carousel-wrapper {
    width: 20rem;
  }
}
</style>
