<template>
  <div :style="background"></div>
  <header>
    <HeaderMenu />
  </header>
  <main class="container top-bottom-padding">
    <RouterView />
  </main>
  <footer>
    <FooterMenu />
  </footer>
  <LoginModal v-if="showing == 'login'" />
  <BotProfileModal v-if="showing == 'botProfile'" />
  <GameFeedbackModal v-if="showing == 'gameFeedback'" />
  <FeedbackModal v-if="showing == 'feedback'" />
  <PuzzleInfoModal v-if="showing == 'puzzleInfo'" />
  <PracticePreviewModal v-if="showing == 'practicePreview'" />
</template>

<script setup lang="ts">
import GameFeedbackModal from "@/components/modals/GameFeedbackModal.vue";
import { RouterView } from "vue-router";
import HeaderMenu from "@/components/HeaderMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import { useModalsStore } from "@/stores/modalsStore";
import { useBackgroundStore } from "@/stores/backgroundStore.js";
import LoginModal from "@/components/modals/LoginModal.vue";
import { storeToRefs } from "pinia";
import BotProfileModal from "@/components/modals/BotProfileModal.vue";
import FeedbackModal from "@/components/modals/FeedbackModal.vue";
import PuzzleInfoModal from "@/components/modals/PuzzleInfoModal.vue";
import { ref } from "vue";
import PracticePreviewModal from "@/components/modals/PracticePreviewModal.vue";

const ms = useModalsStore();
const { showing } = storeToRefs(ms);
const backgroundDefault =
  "linear-gradient(180deg,hsla(0, 0%, 100%, 1) 0%,hsla(200, 100%, 97%, 1) 50%,hsla(0, 0%, 100%, 1) 100%)";

let background = ref<any>({
  background: backgroundDefault,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  minHeight: "",
  minWidth: "100vw",
  position: "fixed",
  zIndex: "-1",
});

useBackgroundStore().$subscribe((mutation, state) => {
  if (!state.background) {
    document.body.style.background = backgroundDefault;
    background.value.minHeight = "";
  } else {
    background.value.background = "url(" + state.background + ")";
    background.value.minHeight = "100vh";
  }
});
</script>

<style scoped>
@media (max-width: 800px) {
  .top-bottom-padding {
    padding-top: 75px;
    padding-bottom: 300px;
  }
}
@media (min-width: 800px) {
  .top-bottom-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
</style>
