<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-primary">
      <div class="container">
        <a
          class="navbar-brand ph-no-capture"
          href="/"
          @click="track('header', 'chessiverse_logo', 'click')"
        >
          <img
            src="@/assets/images/logo.svg"
            class="d-inline-block align-text-top header-size"
            alt="Chessiverse logo"
            style="
              border: 2px solid white;
              border-radius: 50%;
              margin-right: 0.5rem;
              margin-top: -0.4rem;
            "
          />Chessiverse
        </a>

        <div
          style="margin-right: 1rem; margin-left: auto"
          v-if="activeUser() && isMobileWidth()"
        >
          <a
            v-if="!getPremiumState()"
            class="btn btn-warning"
            href="/premium"
            @click="
              () => {
                track('header', 'get_premium', 'click');
              }
            "
          >
            <i class="fa-solid fa-crown" /> Premium
          </a>
        </div>
        <button
          class="navbar-toggler ph-no-capture"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="track('header', 'account_menu', 'click')"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav">
            <a
              v-if="
                isMobileWidth() &&
                active != null &&
                currentRoute.name !== 'game'
              "
              type="button"
              class="btn btn-info position-relative me-3 ph-no-capture"
              style="width: 8rem; margin-bottom: 1rem"
              @click="
                () => {
                  // @ts-ignore
                  gotoGame(active.id);
                  track('header', 'goto_active_game_button', 'click');
                }
              "
              >Ongoing game <i class="fa-solid fa-arrow-right"
            /></a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('dashboard')"
              href="/dashboard"
              @click="track('header', 'dashboard_link', 'click')"
            >
              Dashboard
            </a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('play')"
              href="/play"
              @click="track('header', 'play_link', 'click')"
            >
              Play
            </a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('practice')"
              href="/practice"
              @click="track('header', 'practice_link', 'click')"
            >
              <span>Practice</span>
            </a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('puzzles')"
              href="/puzzles"
              @click="track('header', 'puzzles_link', 'click')"
            >
              <span>Puzzles</span>
            </a>
            <a
              class="nav-link ph-no-capture"
              v-if="activeUser() && isMobileWidth()"
              @click="
                () => {
                  ms.showFeedback();
                  track('header', 'feedback_modal_icon', 'click');
                }
              "
            >
              <i class="fa-solid fa-message-pen" /> Give feedback
            </a>
            <a
              v-if="
                !isMobileWidth() &&
                active != null &&
                currentRoute.name !== 'game'
              "
              type="button"
              class="btn btn-info position-relative me-3 ph-no-capture"
              style="width: 8rem; margin-left: 1rem"
              @click="
                () => {
                  // @ts-ignore
                  gotoGame(active.id);
                  track('header', 'goto_active_game_button', 'click');
                }
              "
              >Ongoing game <i class="fa-solid fa-arrow-right"
            /></a>
          </div>
          <div
            class="d-flex align-items-center justify-content-end flex-grow-1"
            style="gap: 1rem"
            v-if="activeUser() && !isMobileWidth()"
          >
            <a
              v-if="!getPremiumState()"
              class="btn btn-warning"
              href="/premium"
              @click="
                () => {
                  track('header', 'get_premium', 'click');
                }
              "
            >
              <i class="fa-solid fa-crown" /> Get premium
            </a>
            <div
              title="Leave feedback"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <a
                class="ph-no-capture"
                @click="
                  () => {
                    ms.showFeedback();
                    track('header', 'feedback_modal_icon', 'click');
                  }
                "
                ><img
                  v-if="activeUser()"
                  alt="Feedback icon"
                  @mouseover="feedbackImage = feedbackImageWhite"
                  @mouseleave="feedbackImage = feedbackImageGrey"
                  :src="feedbackImage"
                  class="mx-2"
                  style="cursor: pointer; height: 1.2rem"
              /></a>
            </div>
          </div>
          <div class="navbar-nav ms-md-auto">
            <button
              v-if="!userStore.user.loggedIn"
              type="button"
              class="btn btn-info me-2 ph-no-capture"
              @click="
                () => {
                  ms.showLogin(LoginModalState.Signup);
                  track('header_landing', 'play_now_button', 'click');
                }
              "
            >
              Play now for Free!
            </button>
            <a
              v-if="!userStore.user.loggedIn"
              class="nav-link ph-no-capture"
              @click="
                () => {
                  ms.showLogin(LoginModalState.Login);
                  track('header_landing', 'signup_button', 'click');
                }
              "
              style="cursor: pointer"
            >
              Log In
            </a>
            <div v-else>
              <div class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle ph-no-capture"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  @click="track('header', 'account_menu', 'click')"
                >
                  <span>Account</span>
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style="width: 50%"
                >
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      v-if="activeUser()"
                      href="/games"
                      @click="track('header', 'game_history', 'click')"
                    >
                      <i
                        class="fa-solid fa-clock-rotate-left"
                        style="color: var(--clr-accent)"
                      ></i>
                      Game history
                    </a>
                  </li>
                  <li><hr class="dropdown-divider" v-if="activeUser()" /></li>
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      v-if="activeUser()"
                      href="/account"
                      @click="track('header', 'settings', 'click')"
                      ><i
                        class="fa-solid fa-gear"
                        style="color: var(--clr-accent)"
                      ></i>
                      All settings</a
                    >
                  </li>
                  <li><hr class="dropdown-divider" v-if="activeUser()" /></li>
                  <li>
                    <div
                      class="dropdown-item"
                      style="display: flex; gap: 0.5rem"
                    >
                      <SoundsToggle
                        style="margin-top: -0.2rem"
                        soundType="master"
                      />
                    </div>
                  </li>
                  <li><hr class="dropdown-divider" v-if="activeUser()" /></li>
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      role="button"
                      @click="
                        () => {
                          userStore.logout();
                          track('header', 'logout_button', 'click');
                        }
                      "
                      ><i
                        class="fa-solid fa-right-from-bracket"
                        style="color: var(--clr-accent)"
                      ></i>
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import feedbackImageWhite from "@/assets/images/feedback_white.svg";
import feedbackImageGrey from "@/assets/images/feedback_grey.svg";
import { useGameStore } from "@/stores/gameStore";
import { useUserStore } from "@/stores/userStore";
import { useModalsStore } from "@/stores/modalsStore";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { Tooltip as BsTooltip } from "bootstrap";
import { LoginModalState } from "@/types/internaltypes";
import { isMobileWidth } from "@/util/util";
import { track } from "@/util/tracking";
import SoundsToggle from "@/components/sounds/SoundsToggle.vue";
import { getPremiumState } from "@/util/premium";

const router = useRouter();
const currentRoute = ref(router.currentRoute);
let userStore = useUserStore();
let gameStore = useGameStore();
let ms = useModalsStore();
let active = ref();
new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
const feedbackImage = ref(feedbackImageGrey);

// Bit hacky, but this closes the navbar on click
document.addEventListener("click", function (event) {
  var isClickInside = document!
    .getElementById("navbarSupportedContent")!
    .contains(event.target as Node);
  var navbarToggler = document.querySelector(".navbar-toggler") as HTMLElement;

  if (
    !isClickInside &&
    navbarToggler!.getAttribute("aria-expanded") == "true"
  ) {
    navbarToggler.click();
  }
});

const activeUser = (): boolean => {
  return userStore.user.loggedIn;
};

const getNavLinkClass = (activePath: string) => {
  let classString = "nav-link ph-no-capture";
  classString += activePath == router.currentRoute.value.name ? " active" : "";

  return classString;
};

onMounted(() => {
  if (useUserStore().user.loggedIn) {
    gameStore.refreshActiveGame().then((game) => {
      active.value = game;
    });
  }
});

const gotoGame = (id: string) => {
  router.push({
    name: "game",
    query: { id: id, type: "continue" },
  });
};
</script>

<style scoped>
.newtag {
  position: relative;
}

.newtag::before {
  content: "New";
  position: absolute;
  top: -0.8rem;
  right: -0.2rem;
  color: var(--clr-rect-5);
  font-size: 0.7rem;
}

.dropdown-divider {
  --bs-dropdown-divider-bg: #ddd;
}
.header-size {
  height: 2rem;
}
</style>
