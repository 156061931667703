<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <div
      id="start-game-text"
      class="draw-text"
      :style="{
        color: animationColor,
      }"
    >
      Begin!
    </div>
  </div>
</template>

<script setup lang="ts">
import { hexToComplimentary } from "@/util/util";
import { onMounted, ref } from "vue";
import { usePageStore } from "@/stores/pageStore";

const props = defineProps({
  color: {
    type: String,
    default: "#fff",
  },
});

let animationColor = ref("#fff");

const start = () => {
  usePageStore().setPlaySound("start");

  if (props.color != "#fff") {
    animationColor.value = hexToComplimentary(props.color);
  }
  let innerTextElement = document.getElementById("start-game-text");

  innerTextElement!.classList.add("scale-in-center");

  innerTextElement!.addEventListener("animationend", function () {
    innerTextElement!.classList.remove("scale-in-center");
    // @ts-ignore
    innerTextElement.classList.add("puff-out-center");
  });
};

onMounted(() => {
  start();
});
</script>

<style scoped>
@media (max-width: 359px) {
  .draw-text {
    font-size: 3rem;
  }
}

@media (max-width: 410px) {
  .draw-text {
    font-size: 5.5rem;
  }
}
</style>

<style>
.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.puff-out-center {
  -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-10 12:48:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-10-10 12:50:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
</style>
