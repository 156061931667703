// @ts-nocheck
import type { BoardApi } from "vue3-chessboard";
import { Chess, SQUARES } from "chess.js";
import type { Key } from "chessground/types";

/*
There is a problem with vue3-chessboard where when picking up and hovering a piece on the opponents move there is
a small delay so the piece stutters. This becomes worse and worse as the game progresses. This is a workaround
that avoids using the chessjs part of the vue3-chessboard which seems to cause the problem. I wasn't able to find
a full workaround for this, and there's still a very tiny stutter which seems to be caused by the game.move function
in the beginning which I couldn't work around.

There full workarounds would be to either replace vue3-chessboard with only Chessground and internal chessjs which we
can control better. Or to somehow avoid the stutter with webworkers or something similar (but that might be a dead end)
 */
export function makeChessgroundMove(
  boardAPI: BoardApi,
  engineMoveJustMade: string,
  currentPosition: string
) {
  boardAPI.game.move(engineMoveJustMade);
  boardAPI.boardState.historyViewerState = { isEnabled: false };
  boardAPI.board.set({ fen: boardAPI.game.fen() });
  boardAPI.board.state.turnColor =
    boardAPI.game.turn() === "w" ? "white" : "black";
  boardAPI.board.state.movable.color =
    boardAPI.props.playerColor || boardAPI.board.state.turnColor;
  boardAPI.board.state.movable.dests = possibleMoves(
    new Chess(currentPosition)
  );

  boardAPI.displayInCheck(
    boardAPI.game.inCheck(),
    boardAPI.board.state.turnColor
  );

  boardAPI.board.set({
    lastMove: [
      engineMoveJustMade.substring(0, 2),
      engineMoveJustMade.substring(2, 4),
    ],
  });

  boardAPI.emitEvents();
}
// @ts-check

function possibleMoves(game: Chess): Map<Key, Key[]> {
  const dests: Map<Key, Key[]> = new Map();

  for (const square of SQUARES) {
    // const moves = game.moves({ square, verbose: true });
    const moves = game.moves({ square, verbose: true });

    if (moves.length) {
      dests.set(
        moves[0].from,
        moves.map((m) => m.to)
      );
    }
  }

  return dests;
}
