<template>
  <div style="display: flex; justify-content: center">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      "
      v-if="loading"
    >
      <Loader size="3rem" />
      <span v-if="displayStillLooking"
        >Still waiting, give it a few more seconds...</span
      >
      <span v-else>Waiting for subscription status, please hold...</span>
    </div>
    <div
      style="display: flex; flex-direction: column; align-items: center"
      v-else-if="getPremiumState()"
    >
      <ConfettiExplosion
        :colors="['#FFDFB7', '#FFD49D', '#FFC741', '#FFC139', '#FFA849']"
        :force="0.7"
        :stageHeight="getViewportSize().height"
        :stageWidth="getViewportSize().width"
        :duration="3500"
        :particleCount="150"
        style=""
      />
      <h1>You're now a premium user! 🎉</h1>
      <span>Go and find your favorite bots and play some chess!</span>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 16rem;
        gap: 1rem;
        text-align: center;
      "
      v-else
    >
      <h3>
        No premium found just yet <i class="fa-regular fa-face-frown-open" />
      </h3>
      <span
        >. Sometimes it can take a while for the premium status to apply, try to
        retrieve it again by clicking the button below.</span
      >
      <button class="btn btn-info" @click="reloadPage()">
        Re-check premium status
      </button>
      <span>
        If you're still not getting premium and you think this is an error,
        please
        <a
          href="/contact"
          @click="track('checkout', 'contact_us_link', 'click')"
          >contact</a
        >
        us and we'll get right on it!</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import Loader from "@/components/util/LoaderNew.vue";
import { onMounted, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { getViewportSize } from "@/util/util";
import ConfettiExplosion from "vue-confetti-explosion";
import { track } from "@/util/tracking";
import {
  getPremiumPeriod,
  getPremiumState,
  getPremiumType,
} from "@/util/premium";
import { useRouter } from "vue-router";

const router = useRouter();

let loading = ref(true);
let displayStillLooking = ref(false);

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function refreshProfileWithRetry(retries: number, delayTime: number) {
  const userStore = useUserStore();

  for (let i = 0; i < retries; i++) {
    if (i > 5) {
      displayStillLooking.value = true;
    }

    // Make sure we have the latest premium state loaded
    await userStore.refreshUserProfile();

    if (getPremiumState()) {
      if (getPremiumType() == "earlyadopter") {
        // Don't go to any success or anything for earlyadpoter, they shouldn't be here
        await router.push({
          name: "dashboard",
        });
        break;
      }

      let planid = "lifetime";

      if (getPremiumType() == "subscription") {
        planid = getPremiumPeriod() ?? "subscription";
      }

      await router.push({
        name: "checkout-success",
        params: { planid: planid },
      });
      break;
    }
    await delay(delayTime);
  }

  if (!getPremiumState()) {
    await router.push({
      name: "checkout-missing",
    });
  }

  displayStillLooking.value = false;
  loading.value = false;
}

async function reloadPage() {
  window.location.reload();
}

onMounted(() => {
  refreshProfileWithRetry(10, 1000);
});
</script>

<style scoped></style>
