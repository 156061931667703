<template>
  <div>
    <MosaicLeft />
    <MosaicRight v-if="!isMobileWidth()" />
    <div
      class="row justify-content-center flex-column"
      :style="{
        marginBottom: '2rem',
      }"
    >
      <div class="mx-auto" style="width: 1rem">
        <ConfettiExplosion
          v-if="!hasPlayedConfetti()"
          :stageHeight="1600"
          :stageWidth="1600"
        />
      </div>
      <div>
        <div style="display: flex; justify-content: center">
          <ProfileCard
            :botMock="{
              name: 'Sir Reginald Worth',
              countryCode: 'gb',
              countryName: 'United Kingdom',
              rating: 2165,
              botId: '5',
            }"
          />
        </div>
        <div class="mx-auto mt-3" style="width: 16rem; text-align: center">
          <TypeWriter
            :targetText="'&quot;Ah, splendid to have you on board, old bean! In a jiffy, we\'ll be indulging in a riveting match of chess. Chin up and stay tuned, chap!&quot;'"
            :style="'height: 6rem; width: 100%; margin: auto; margin-top: 1rem; cursor: pointer'"
            :pClasses="['card-text', 'text-center']"
            :newTextDelay="0"
          />
        </div>
      </div>
    </div>
    <div>
      <button
        role="button"
        class="btn btn-info ph-no-capture"
        style="display: block; margin: auto"
        @click="
          () => {
            letsGo();
            track('signed_up_view', 'lets_go', 'click');
          }
        "
      >
        Let's go!
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import TypeWriter from "@/components/util/TypeWriter.vue";
import MosaicLeft from "@/components/landing/MosaicLeft.vue";
import MosaicRight from "@/components/landing/MosaicRight.vue";
import ConfettiExplosion from "vue-confetti-explosion";
import { useUserStore } from "@/stores/userStore";
import { ref } from "vue";
import { isMobileWidth } from "@/util/util";
import ProfileCard from "@/components/common/ProfileCard.vue";
import { useRouter } from "vue-router";
import { track } from "@/util/tracking";

const userStore = useUserStore();
const router = useRouter();

let refreshingUser = ref(true);

let resent = ref(false);

function sendVerification() {
  userStore.triggerVerificationEmail();
  resent.value = true;
}

async function refreshUser() {
  await userStore.reloadFirebaseUser();
  await userStore.refreshUserProfile();
  refreshingUser.value = false;
}

refreshUser();

function letsGo() {
  router.push({
    name: "dashboard",
  });
}

function hasPlayedConfetti() {
  return localStorage.getItem("signUpConfettiPlayed") == "true";
}

// Hacky, but ensures that the confetti is played once, putting it in onMounted (too early) or onBeforeUnmount (doesn't work on page reload) doesn't work
// so maybe we need the component itself to set this once it's been loaded, but the we have to make a custom confetti just for this. So going
// with the hacky solution for now
setTimeout(() => {
  localStorage.setItem("signUpConfettiPlayed", "true");
}, 2000);
</script>

<style scoped>
.background-card {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
</style>
