import { defineStore } from "pinia";
import type {
  AllBotsResponse,
  Bot,
  ChatTriggerId,
  GetBotProfileResponse,
  GetGeneralChatResponse,
  NextOpponentSuggestion,
} from "@/types/apitypes";
import * as api from "@/services/rest";
import { getClosestBot, normalDistribution } from "@/util/ratings";
import { useUserStore } from "@/stores/userStore";
import { getPremiumState } from "@/util/premium";

const unknownBot: any = {
  status_code: 1,
  live_date: new Date(0),
  id: "unknown",
  name: "Unknown",
  age: "0",
  country: {
    name: "Antarctica",
    code: "aq",
  },
  occupation: "Unknown",
  introduction: "Unknown",
  openings: {
    white: [],
    black: [],
  },
  strength: {
    estimated_elo: 0,
    openings: 1,
    openings_prep: 0,
    results: {
      maia9: 0,
      all: 0,
    },
  },
  config: {
    boardbg: "#fff",
  },
};

export const useBotsStore = defineStore("bots", {
  state: () => ({
    fetched: false,
    bots: {} as { [key: string]: Bot },
  }),
  actions: {
    isLocked(bot: Bot | null): boolean {
      if (bot == null || getPremiumState() || bot.id == "unknown") {
        return false;
      }

      return bot.premium == "closed";
    },
    getBot(botId: string): Bot {
      let bot = this.bots[botId];

      if (bot == null) {
        bot = unknownBot;
      }

      return bot;
    },
    async getNextOpponent(gameId: string): Promise<NextOpponentSuggestion> {
      return (await api.getNextOpponentSuggestion(gameId)).data;
    },
    getRandomBot(rating: number | null): Bot | undefined {
      const usableBots: { [key: string]: Bot } = JSON.parse(
        JSON.stringify(this.bots)
      );
      if (!getPremiumState()) {
        for (const key of Object.keys(usableBots)) {
          if (this.isLocked(usableBots[key])) {
            delete usableBots[key];
          }
        }
      }

      if (rating == null) {
        const keys = Object.keys(usableBots);
        const randomIndex = Math.floor(Math.random() * keys.length);
        const randomKey = keys[randomIndex];
        return usableBots[randomKey];
      } else {
        return getClosestBot(normalDistribution(rating), usableBots);
      }
    },
    async getChat(
      botId: string,
      triggerId: ChatTriggerId,
      triggerData: Record<string, string> = {},
      anonymous: boolean = false
    ): Promise<string> {
      return (
        (await this.pollChat(botId, triggerId, triggerData, anonymous)).data
          .chat ?? ""
      );
    },
    async pollChat(
      botId: string,
      triggerId: ChatTriggerId,
      triggerData: Record<string, string>,
      anonymous: boolean = false
    ): Promise<GetGeneralChatResponse> {
      const initialResposne = await api.getChat(
        botId,
        triggerId,
        triggerData,
        anonymous
      );

      if (initialResposne.data.type == "cache") {
        return initialResposne;
      }

      const timeout = Date.now() + 5000; // 50ms timeout

      async function poll(): Promise<GetGeneralChatResponse> {
        if (Date.now() > timeout) {
          return {
            error: false,
            status: 200,
            data: {
              type: "none",
              chat: "",
            },
          };
        }

        const response = await api.getChat(
          botId,
          triggerId,
          triggerData,
          anonymous,
          true
        );

        if (response.data.type == "cache" || response.data.type == "fresh") {
          return response;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          return poll();
        }
      }

      return poll();
    },
    async getUserBotProfile(
      botId: string,
      anonymous: boolean = false
    ): Promise<GetBotProfileResponse> {
      if (botId == "unknown") {
        return {
          data: {
            bot: unknownBot,
            gameStats: { wins: 0, losses: 0, draws: 0 },
          },
        } as GetBotProfileResponse;
      }
      // Retrieves bot profile from server which will decorate it will user data (e.g. wins)
      const botResponse = await api.getBotProfile(botId, anonymous);

      if (botResponse.status == 404) {
        return {
          data: {
            bot: unknownBot,
            gameStats: { wins: 0, losses: 0, draws: 0 },
          },
        } as GetBotProfileResponse;
      }

      return botResponse;
    },
    async refresh() {
      const fetchedBots: AllBotsResponse = await api.getBots();

      this.bots = fetchedBots.data.bots.reduce((result: any, obj: Bot) => {
        result[obj.id!] = obj;
        return result;
      }, {});
      this.fetched = true;
    },
    async getBotsOnDate(date: string) {
      return (await api.getBotsFromDate(date)).data.bots;
    },
  },
  getters: {
    list(): Bot[] {
      const botList = [] as Bot[];
      for (const key of Object.keys(this.bots)) {
        botList.push(this.bots[key]);
      }

      return botList;
    },
  },
  persist: true,
});
