<template>
  <div
    class="modal"
    id="botProfileModal"
    tabindex="-1"
    aria-labelledby="botProfileModalLabel"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" :style="getStyle()">
        <div
          class="modal-header"
          style="border-bottom: 0px; justify-content: right"
        >
          <a
            type="button"
            id="botProfileModalClose"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="z-index: 10"
          >
            <img
              alt="Cross icon"
              src="https://storage.googleapis.com/chessiverse1/images/elements/cross.webp"
              style="width: 2rem; height: 2rem"
            />
          </a>
        </div>
        <BotProfileComponent
          v-if="botProfileModalBotId != null"
          :botId="botProfileModalBotId"
          @challenge-bot="closeModal()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Modal } from "bootstrap";
// @ts-ignore
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useModalsStore } from "@/stores/modalsStore";
import { usePageStore } from "@/stores/pageStore";
import { storeToRefs } from "pinia";
import { ImageType } from "@/types/internaltypes";
import { isMobileWidth } from "@/util/util";
import BotProfileComponent from "@/components/modals/BotProfileComponent.vue";

const ms = useModalsStore();
const ps = usePageStore();

const { botProfileModalBotId } = storeToRefs(ms);

const getStyle = () => {
  return {
    background:
      botProfileModalBotId.value == null
        ? ""
        : "url(" +
          ps.img(
            botProfileModalBotId.value,
            ImageType.BotBackground,
            isMobileWidth() ? "410_landscape" : null
          ) +
          ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
  };
};

const modalElement = ref(null);
onMounted(() => {
  new Modal(modalElement.value);
  if (modalElement.value != null) {
    // @ts-ignore
    modalElement.value?.addEventListener("shown.bs.modal", () => {
      // How do we use this listener now when it's separated?
      // if (props.botId) {
      //   init(props.botId);
      // }
    });
  }
});

function closeModal() {
  ms.closeCurrentModal();
}
</script>

<style lang="scss" scoped>
.bot-profile-wrapper > div {
  text-align: center;
}

.bot-image img {
  width: min(100%, 20rem);
}

@media (max-width: 992px) {
}

// Cursor blinking CSS Starts...
.blinking-cursor {
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
// Cursor blinking CSS Ends...
</style>
