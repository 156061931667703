<template>
  <div>
    <div
      v-if="loadingBots || (loadingGames && games.length == 0)"
      class="text-center"
    >
      <Loader />
    </div>
    <div v-else>
      <div v-if="fixedViewType == null" style="text-align: right">
        <img
          :src="viewType == 'grid' ? listIcon : gridIcon"
          :alt="viewType == 'grid' ? 'A grid icon' : 'A list icon'"
          class="bounce-top ph-no-capture"
          style="cursor: pointer"
          @click="
            () => {
              toggleViewType();
              track('game_history_table', 'toggle_view_type', 'click');
            }
          "
        />
      </div>
      <div
        v-if="viewType == 'grid'"
        class="d-flex flex-wrap justify-content-center"
      >
        <div v-for="(game, index) in games" :key="index">
          <GameHistoryCard :bot="bs.getBot(game.bot.id)" :game="game" />
        </div>
      </div>
      <div v-if="viewType == 'list'" class="overflow-auto">
        <table
          class="table table-striped table-responsive caption-top"
          style="width: 0%; margin: 0 auto 2rem auto"
        >
          <caption>
            Games
          </caption>
          <thead class="table-light">
            <tr>
              <th scope="col" class="col-auto">Played</th>
              <th scope="col" class="col-auto">Opponent</th>
              <th scope="col" class="col-auto">Country</th>
              <th scope="col" class="col-auto">Elo</th>
              <th scope="col" class="col-auto">Result</th>
              <th scope="col" class="col-auto">Rating change</th>
              <th scope="col" class="col">Link</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(game, index) in games" :key="index">
              <td
                style="white-space: nowrap"
                :title="game.startedAt.toLocaleString()"
                :data-bs-original-title="game.startedAt.toLocaleString()"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
              >
                {{ dateToDateMonthWithOptionalYear(game.startedAt) }}
              </td>
              <td
                :style="{
                  background: bs.getBot(game.bot.id).config.boardbg,
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                }"
                class="ph-no-capture"
                @click="
                  () => {
                    ms.showBotProfile(game.bot.id);
                    track('game_history_table', 'show_bot_profile', 'click');
                  }
                "
              >
                <img
                  style="
                    height: 2rem;
                    margin-right: 1rem;
                    background: white;
                    border-radius: 50%;
                  "
                  :src="ps.img(game.bot.id, ImageType.BotProfile, '80')"
                />{{ bs.getBot(game.bot.id).name }}
              </td>
              <td
                style="white-space: nowrap"
                :title="bs.getBot(game.bot.id).country.name"
                :data-bs-original-title="bs.getBot(game.bot.id).country.name"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
              >
                <img
                  :src="getFlagUrl(bs.getBot(game.bot.id).country.code)"
                  :alt="bs.getBot(game.bot.id).country.name"
                  style="height: 0.7rem; margin: -0.2rem 0.2rem 0 0"
                />
              </td>
              <td style="white-space: nowrap">
                {{ bs.getBot(game.bot.id).strength.estimated_elo }}
              </td>
              <td style="white-space: nowrap">
                <div v-if="game.result != null">
                  <a
                    class="btn btn-info"
                    :style="{
                      cursor: 'auto',
                      background: resultData(game.result, game.userSide).color,
                      color: 'black',
                      height: '1.5rem',
                      width: '4rem',
                      padding: '0',
                    }"
                    >{{ resultData(game.result, game.userSide).message }}
                    <img
                      alt="Golden star"
                      v-if="resultData(game.result, game.userSide).userWon"
                      :style="{
                        height: '1rem',
                        marginTop: '-0.25rem',
                      }"
                      src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
                    />
                  </a>
                </div>
                <div class="text-center" v-else>-</div>
              </td>
              <td style="white-space: nowrap; text-align: center">
                <span
                  v-if="
                    game.result == null ||
                    !game.rated ||
                    game.ratingChange == null ||
                    game.ratingChange.new == null ||
                    game.ratingChange.old == null
                  "
                  >-</span
                >
                <span
                  v-else-if="getRatingChangeNumber(game.ratingChange) >= 0"
                  style="color: var(--clr-accent4)"
                >
                  +{{ getRatingChangeNumber(game.ratingChange!) }}
                </span>
                <span v-else style="color: var(--clr-rect-4)">
                  {{ getRatingChangeNumber(game.ratingChange!) }}
                </span>
              </td>
              <td style="white-space: nowrap; text-align: center">
                <span v-if="game.termination == GameTermination.Aborted"
                  >Aborted</span
                >
                <button
                  v-else-if="game.result == null"
                  type="button"
                  class="btn btn-outline-info to-game-button to-game-button-ongoing ph-no-capture"
                  @click="
                    () => {
                      gotoGame(game.id);
                      track('game_history_table', 'ongoing_button', 'click');
                    }
                  "
                >
                  {{ "Ongoing" }}
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-info to-game-button ph-no-capture"
                  @click="
                    () => {
                      gotoAnalysis(game.id);
                      track('game_history_table', 'view_button', 'click');
                    }
                  "
                >
                  {{ "View" }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!endOfGames" class="text-center">
        <Loader v-if="loadingGames" />
        <a
          v-else
          @click="
            () => {
              getMore();
              track('game_history_table', 'load_more_games', 'click');
            }
          "
          style="cursor: pointer"
          class="ph-no-capture"
          >Load more...</a
        >
      </div>
      <div
        v-if="!loadingGames && endOfGames && games.length == 0"
        class="text-center"
      >
        No games played yet
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import listIcon from "@/assets/images/list.svg";
import gridIcon from "@/assets/images/grid.svg";
import { useGameStore } from "@/stores/gameStore";
import { useModalsStore } from "@/stores/modalsStore";
import { useBotsStore } from "@/stores/botStore";
import { usePageStore } from "@/stores/pageStore";
import { ImageType } from "@/types/internaltypes";
import { type PropType, ref, type Ref } from "vue";
import Loader from "@/components/util/Loader.vue";
import { Tooltip as BsTooltip } from "bootstrap";
import "vue3-chessboard/style.css";
import { dateToDateMonthWithOptionalYear, getFlagUrl } from "@/util/util";
import {
  type Game,
  GameTermination,
  type Rating,
  Result,
  Side,
} from "@/types/apitypes";
import GameHistoryCard from "@/components/history/GameHistoryCard.vue";
import { useRouter } from "vue-router";
import { track } from "@/util/tracking";

const gs = useGameStore();
const bs = useBotsStore();
const ps = usePageStore();
const ms = useModalsStore();

const router = useRouter();

const props = defineProps({
  fixedViewType: {
    type: String as PropType<"grid" | "list">,
    default: null,
  },
  maxGames: {
    type: Number,
    default: null,
  },
});

let games: Ref<Game[]> = ref([]);

let viewType = ref(props.fixedViewType == null ? "grid" : props.fixedViewType);
let loadingGames = ref(true);
let loadingBots = ref(true);
let endOfGames = ref(false);

bs.refresh().then(() => {
  loadingBots.value = false;
});
getMore();

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

function getRatingChangeNumber(ratingChange: {
  potential: {
    win: number;
    draw: number;
    loss: number;
  };
  old?: Rating;
  new?: Rating;
}) {
  if (ratingChange.new == null || ratingChange.old == null) {
    // This shouldn't happen since we null-check in the template, so just least bit of harm return 0
    return 0;
  }
  return Math.round(ratingChange.new.rating - ratingChange.old.rating);
}

function toggleViewType() {
  if (props.fixedViewType != null) {
    viewType.value = props.fixedViewType;
  } else {
    viewType.value = viewType.value == "grid" ? "list" : "grid";
  }
}

function getMore() {
  loadingGames.value = true;
  let fromDate =
    games.value.length > 0
      ? games.value[games.value.length - 1].startedAt
      : new Date();

  const getNGames =
    props.maxGames != null && props.maxGames < 20 ? props.maxGames : 20;

  gs.getGames(fromDate, getNGames).then((g) => {
    endOfGames.value = g.length < getNGames;
    games.value.push(...g);

    if (props.maxGames != null && games.value.length >= props.maxGames) {
      games.value = games.value.slice(0, props.maxGames);
      endOfGames.value = true;
    }

    loadingGames.value = false;
  });
}

function resultData(
  result: Result | null,
  userSide: Side
): { message: string; color: string; userWon: boolean } {
  if (result == null) {
    return { message: "", color: "#f00", userWon: false };
  } else if (result === Result.Draw) {
    return { message: "Draw", color: "#ccc", userWon: false };
  } else if (
    (result === Result.White && userSide === Side.White) ||
    (result === Result.Black && userSide === Side.Black)
  ) {
    return { message: "Win", color: "#469C14", userWon: true };
  } else {
    return { message: "Loss", color: "#9C2614", userWon: false };
  }
}
const gotoAnalysis = (gameId: string) => {
  router.push({
    name: "analysis",
    query: { id: gameId },
  });
};
const gotoGame = (gameId: string) => {
  router.push({
    name: "game",
    query: { id: gameId, type: "continue" },
  });
};
</script>

<style scoped>
.table th,
.table td {
  text-align: left;
  border: 1px solid #dee2e6;
}

.table th.col-auto,
.table td.col-auto {
  width: 1%;
  white-space: nowrap;
}

.table th.col,
.table td.col {
  width: 100%;
}
</style>
