<template>
  <div class="card mainpractice rect-gradient-2">
    <div>
      <div class="header-icon-container">
        <i class="header-icon fa-solid fa-puzzle-piece" />
      </div>
      <div>
        <h1>Puzzles</h1>
        <span style="text-align: center">
          Complete puzzles presented by the bots
        </span>
      </div>
    </div>
    <div class="categoryheader-points">
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Number of beaten bots"
        class="categoryheader-points-item"
      >
        <div>
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
          />
          <span>Completed</span>
        </div>
        <div v-if="puzzles.length <= 0" style="width: 9rem; height: 1.8rem">
          <Loader size="1.8rem" />
        </div>
        <span
          v-else
          style="
            font-size: 1.8rem;
            height: 1.8rem;
            width: 9rem;
            text-align: center;
          "
          class="fade-in"
          >{{ beaten }} / {{ puzzles.length }}</span
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type PropType, watch } from "vue";
import { ref } from "vue";
import type { Bot, PracticePosition, Puzzle } from "@/types/apitypes";
import Loader from "@/components/util/LoaderNew.vue";

const props = defineProps({
  puzzles: {
    type: Object as PropType<Puzzle[]>,
    required: true,
  },
});

let beaten = ref(0);

watch(
  () => props.puzzles,
  () => updateStats(),
  { immediate: true }
);

function updateStats() {
  beaten.value = 0;
  for (let puzzle of props.puzzles) {
    if (puzzle.user_result != null) {
      beaten.value++;
    }
  }
}
</script>

<style scoped>
.mainpractice {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.mainpractice > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.header-icon {
  color: var(--clr-rect-2);
  font-size: 2rem;
}
.header-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  min-width: 5rem;
  height: 5rem;
}

.categoryheader-points {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  padding: 0.5rem;
}

.categoryheader-points-item img {
  width: 2rem;
  aspect-ratio: 1;
}

.categoryheader-points-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.categoryheader-points-item > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem;
}

@media (max-width: 992px) {
  .mainpractice > div {
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: start;
  }

  .categoryheader-points {
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
  }

  .categoryheader-points-item {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
}
</style>
