<template>
  <div v-if="!loading && challenge == null">
    <!-- No daily position available -->
  </div>
  <div class="card daily-position-container" v-else>
    <div v-if="loading || !bs.fetched || challenge == null">
      <h2
        style="font-size: 1.5rem; color: var(--clr-background-detail)"
        :data-bs-original-title="getDisplay().tooltip"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        {{ getDisplay().title
        }}<i
          class="fa-solid fa-comment-exclamation"
          style="vertical-align: super; font-size: 0.8rem"
        ></i>
      </h2>
    </div>
    <div v-else>
      <h2
        style="font-size: 1.5rem; color: var(--clr-background-detail)"
        :data-bs-original-title="getDisplay().tooltip"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        {{ getDisplay().title
        }}<i
          class="fa-solid fa-comment-exclamation"
          style="vertical-align: super; font-size: 0.8rem"
        ></i>
      </h2>

      <div
        style="
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          justify-content: center;
          margin-bottom: 1rem;
        "
      >
        <div class="chessboard card">
          <TheChessboard
            :board-config="{
            fen: challenge!.position,
            orientation: challenge!.userSide == Side.White ? 'white' : 'black',
            blockTouchScroll: false,
            coordinates: false,
            viewOnly: true,
          }"
          />
        </div>
        <div
          style="
            text-align: center;
            font-size: 0.9rem;
            color: white;
            margin: 0rem 0 1rem 0;
          "
        >
          <span style="color: var(--clr-main)">{{
            challenge!.extra.title
          }}</span>
          <br />
          <span style="color: var(--clr-main); opacity: 0.5">{{
            challenge!.extra.subtitle
          }}</span>
        </div>
        <div style="margin-top: -0.5rem; margin-bottom: -0.5rem">
          <h5
            v-if="getPremiumState()"
            style="color: grey; text-align: center"
            class="btn-shine"
          >
            Play the challenge
          </h5>
          <h5 v-else style="color: white; text-align: center">
            <i
              class="fa-solid fa-crown"
              style="color: var(--clr-premium-yellow)"
              v-if="!getPremiumState()"
            />
            Unlock premium to play
          </h5>
        </div>
        <div
          class="difficulties"
          style="position: relative"
          v-if="challenge != null"
        >
          <div
            v-for="(b, i) in defaultDifficultiesOrder"
            :key="'botdiffiuclty' + i"
            class="difficulty zoom-hover"
            @click="
            () => {
              if (getPremiumState()) {

              startDailyPosition(b);
              } else {
                router.push({name: 'premium'});
              }
              track('daily_position_box', 'start_challenge' + (getPremiumState() ? '' : 'locked'), 'click', {
                // @ts-ignore
                botId: challenge!.difficulties[b].botId,
                difficulty: b,
                type: props.positionType,
              });
            }
          "
          >
            <div style="position: relative">
              <img
                alt="Bot profile image"
                class="profile-image-circle"
                :style="{
                  backgroundColor: bots[b].config.boardbg,
                  filter: getPremiumState() ? '' : 'saturate(30%) opacity(50%)',
                }"
                :src="ps.img(bots[b].id, ImageType.BotProfile, '175')"
              />
              <span
                v-if="!getPremiumState()"
                style="
                  font-size: 1.2rem;
                  position: absolute;
                  opacity: 1;
                  left: 0.75rem;
                  bottom: 0.1rem;
                "
                ><i class="fa-solid fa-lock"
              /></span>
            </div>
            <span
              style="
                text-transform: capitalize;
                font-weight: 100;
                color: var(--clr-main);
              "
              >{{ b }}</span
            >
            <span
              style="
                margin-top: -0.75rem;
                font-weight: 100;
                color: var(--clr-main);
                opacity: 0.5;
              "
              >({{ bots[b].strength.estimated_elo }})</span
            >
            <img
              v-if="challenge!.difficulties[b as 'simple' | 'easy' | 'balanced' | 'hard' | 'intense'].userWon"
              alt="Golden star outline"
              style="
                filter: hue-rotate(180deg);
                height: 2rem;
                margin-top: -0.25rem;
              "
              src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
            />
            <img
              v-else
              alt="Golden star outline"
              class="pulsate-load-strong"
              style="
                filter: hue-rotate(180deg);
                height: 2rem;
                margin-top: -0.25rem;
              "
              src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref } from "vue";
import { useGeneralStore } from "@/stores/generalStore";
import { useBotsStore } from "@/stores/botStore";
import { ImageType } from "@/types/internaltypes";
import { usePageStore } from "@/stores/pageStore";
import { track } from "@/util/tracking";
import { useModalsStore } from "@/stores/modalsStore";
import { useRouter } from "vue-router";
import { Tooltip as BsTooltip } from "bootstrap";
import { TheChessboard } from "vue3-chessboard";
import { type DailyPosition, Side } from "@/types/apitypes";
import { useUserStore } from "@/stores/userStore";
import { getPremiumState } from "@/util/premium";

const bs = useBotsStore();
const ms = useModalsStore();
let gs = useGeneralStore();
const ps = usePageStore();
const router = useRouter();

// We know that this will be the keys of difficulties, but need them in this order so instead of doing Object.keys, take them from here
const defaultDifficultiesOrder = [
  "simple",
  "easy",
  "balanced",
  "hard",
  "intense",
];

const props = defineProps({
  positionType: {
    type: String as PropType<"dailyendgame" | "dailymaster">,
    required: true,
  },
});

let loading = ref(true);
let challenge = ref<DailyPosition | null>();
let bots = ref();

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

initPosition();

function getDisplay() {
  if (props.positionType === "dailyendgame") {
    return {
      title: "Classic Endgame",
      tooltip: "Win this classic endgame position",
    };
  } else if (props.positionType === "dailymaster") {
    return {
      title: "New Master Position",
      tooltip: "Can you win this position from a recently played top game",
    };
  } else {
    return {
      title: "Unknown",
      tooltip: "Unknown position type, this is a bug",
    };
  }
}

function startDailyPosition(difficulty: string) {
  router.push({
    name: "game",
    query: { type: props.positionType, difficulty: difficulty },
  });
}

async function initPosition(): Promise<void> {
  let d = await gs.getDailyPosition(props.positionType);
  challenge.value = d;

  if (d == null) {
    loading.value = false;
    return;
  }

  if (!challenge.value!.extra) {
    challenge.value!.extra = { title: "", subtitle: "" };
  } else {
    challenge.value!.extra = JSON.parse(challenge.value!.extra);
  }

  const retrievedBots = await Promise.all([
    bs.getUserBotProfile(d.difficulties.simple.botId),
    bs.getUserBotProfile(d.difficulties.easy.botId),
    bs.getUserBotProfile(d.difficulties.balanced.botId),
    bs.getUserBotProfile(d.difficulties.hard.botId),
    bs.getUserBotProfile(d.difficulties.intense.botId),
  ]);

  bots.value = {};
  bots.value["simple"] = retrievedBots[0].data.bot;
  bots.value["easy"] = retrievedBots[1].data.bot;
  bots.value["balanced"] = retrievedBots[2].data.bot;
  bots.value["hard"] = retrievedBots[3].data.bot;
  bots.value["intense"] = retrievedBots[4].data.bot;

  loading.value = false;
}
</script>

<style scoped>
:deep(cg-board) {
  background-color: var(--clr-accent); /* Override the board background color */
}

.chessboard {
  box-sizing: content-box;
  width: 15rem;
  aspect-ratio: 1;
  margin: auto;
}

h2 {
  text-align: center;
  margin-block: 1rem;
}

.daily-position-container {
  min-width: 18rem;
  min-height: 35rem;
}

.profile-image-circle {
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
  border-radius: 50%;
  border: 1.5px solid #444;
}

.difficulties {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: var(--clr-background-detail);
}

.difficulty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--clr-background-detail);
}

.zoom-hover {
  transition: all 0.2s ease;
  cursor: pointer;
}

.zoom-hover:hover {
  color: var(--clr-accent);
}

.zoom-hover:hover .profile-image-circle {
  transform: scale(1.1) !important;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.8);
}
</style>
