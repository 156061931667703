<template>
  <div
    class="modal fade"
    id="feedbackModal"
    tabindex="-1"
    aria-labelledby="feedbackModalLabel"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div
          class="modal-header"
          style="border-bottom: 0px; justify-content: right; z-index: 10"
        >
          <a
            type="button"
            id="feedbackModalClose"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="ph-no-capture"
            @click="
              () => {
                errorText = '';
                track('feedback_modal', 'cancel_feedback', 'click', {
                  feedbackText: feedbackText,
                });
              }
            "
          >
            <img
              alt="Cross icon"
              src="https://storage.googleapis.com/chessiverse1/images/elements/cross.webp"
              style="width: 1rem; height: 1rem"
            />
          </a>
        </div>
        <div class="modal-body">
          <h3 style="margin-top: -3rem; margin-bottom: 2rem">
            Let us know what you think
          </h3>
          <textarea
            style="width: 100%; height: 15rem; padding: 1rem"
            maxlength="2000"
            placeholder="Suggestions, improvements, bugs, features..."
            v-model="feedbackText"
          ></textarea>
          <span style="color: red; margin-top: -0.2rem; font-size: 0.8rem">{{
            errorText
          }}</span>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-info ph-no-capture"
            style="height: 2.5rem; width: 5rem"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="
              () => {
                errorText = '';
                track('feedback_modal', 'cancel_feedback', 'click', {
                  feedbackText: feedbackText,
                });
              }
            "
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-info ph-no-capture"
            style="height: 2.5rem; width: 5rem"
            :disabled="feedbackText.length == 0"
            @click="
              () => {
                sendFeedback(feedbackText);
                track('feedback_modal', 'send_feedback', 'click', {
                  feedbackText: feedbackText,
                });
              }
            "
          >
            {{ sendingFeedback ? "" : "Send" }}
            <Loader v-if="sendingFeedback" size="1.5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Loader from "@/components/util/Loader.vue";
import { sendFeedback as apiSendFeedback } from "@/services/rest";
import { useModalsStore } from "@/stores/modalsStore";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";
import { track } from "@/util/tracking";

const ms = useModalsStore();

let feedbackText = ref("");
let errorText = ref("");
let sendingFeedback = ref(false);

const doneWithModal = () => {
  ms.closeCurrentModal();
  sendingFeedback.value = false;
};

const sendFeedback = (text: string) => {
  sendingFeedback.value = true;
  apiSendFeedback(text)
    .then(() => {
      doneWithModal();
      useToast().success("Feedback sent. Thanks!");
    })
    .catch(() => {
      errorText.value = "Problem when submitting feedback, try again later";
      sendingFeedback.value = false;
    });
};
</script>

<style scoped></style>
