<template>
  <div>
    <Toggle
      v-model="soundOn"
      class="toggle-sound ph-no-capture"
      @click="toggleSounds($event)"
      :disabled="disabled"
    >
      <template v-slot:label="{ checked, classList }">
        <span v-if="checked" :class="classList.label"
          ><i class="fa-solid fa-volume"></i
        ></span>
        <span v-else :class="classList.label"
          ><i class="fa-solid fa-volume-off"></i
        ></span>
      </template>
    </Toggle>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref, watch } from "vue";
import Toggle from "@vueform/toggle";
import { usePageStore } from "@/stores/pageStore";
import { track } from "@/util/tracking";

const props = defineProps({
  soundType: {
    type: String as PropType<"master" | "moves" | "other">,
    required: true,
  },
});

const pageStore = usePageStore();
let soundOn = ref<boolean>(!isMuted());
let disabled = ref<boolean>(isDisabled());

watch(
  () => {
    switch (props.soundType) {
      case "master":
        return pageStore.sounds.master.muted;
      case "moves":
        return pageStore.sounds.moves.muted;
      case "other":
        return pageStore.sounds.other.muted;
    }
  },
  (newVal) => {
    soundOn.value = !newVal;
  }
);

watch(
  () => pageStore.sounds.master.muted,
  (newVal) => {
    disabled.value = isDisabled();
  }
);

function isMuted() {
  switch (props.soundType) {
    case "master":
      return pageStore.sounds.master.muted;
    case "moves":
      return pageStore.sounds.moves.muted;
    case "other":
      return pageStore.sounds.other.muted;
  }
}

function isDisabled() {
  switch (props.soundType) {
    case "master":
      return false;
    case "moves":
      return pageStore.sounds.master.muted;
    case "other":
      return pageStore.sounds.master.muted;
  }
}

function toggleSounds(e: Event) {
  switch (props.soundType) {
    case "master":
      pageStore.sounds.master.muted = !soundOn.value;
      break;
    case "moves":
      pageStore.sounds.moves.muted = !soundOn.value;
      break;
    case "other":
      pageStore.sounds.other.muted = !soundOn.value;
      break;
  }

  e.stopPropagation(); // Prevents the click event from being captured by the parent so the Toggle works
  track("header", "toggle_sound", "toggle", {
    sound: soundOn,
    soundType: props.soundType,
  });
}
</script>
<style scoped>
.toggle-sound {
  outline: none;
  --toggle-width: 3rem;
  --toggle-font-size: 1rem;
  --toggle-bg-on: var(--clr-accent);
  --toggle-bg-off: var(--clr-main-lighter);
  --toggle-text-on: white;
  --toggle-text-off: white;
  --toggle-border-on: var(--clr-accent);
  --toggle-border-off: var(--clr-main-lighter);
  --toggle-handle-enabled: var(--clr-rect-2);
}
</style>
