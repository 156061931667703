<template>
  <div class="modal-body">
    <div v-if="botProfile == null">
      <Loader />
    </div>
    <div v-else class="bot-profile-wrapper">
      <div class="bot-image" style="min-height: 20rem">
        <img
          v-if="!loadingProfile"
          alt="Bot profile image"
          :src="ps.img(botProfile.bot.id, ImageType.BotProfile, '320')"
        />
      </div>
      <div class="card bot-presentation">
        <div class="card-body" v-if="!loadingProfile">
          <h1 class="card-title text-center" style="font-size: 1.5rem">
            <strong>{{ botProfile.bot.name }}</strong>
          </h1>
          <div>
            <img
              alt="Country flag"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="botProfile.bot.country.name"
              :data-bs-original-title="botProfile.bot.country.name"
              :src="getFlagUrl(botProfile.bot.country.code)"
              style="width: 2rem"
            />
            <span class="mx-2">|</span>
            <span>Age {{ botProfile.bot.age }}</span>
            <span class="mx-2">|</span>
            <span> {{ botProfile.bot.occupation }}</span>
          </div>
          <div style="margin-top: 1rem">
            <span
              >"<em>{{ botProfile.bot.favorite_quote }}</em
              >"</span
            >
          </div>
          <hr class="hr" />
          <BlinkingCursor v-if="loadingPresentationText" />
          <TypeWriter
            v-else
            :targetText="presentationText"
            :pClasses="['card-text', 'text-center']"
            :newTextDelay="100"
          />
        </div>
      </div>
      <div class="card bot-stats">
        <div class="card-body" style="text-align: left">
          <h5 class="card-title">Playing strength</h5>
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Estimated FIDE Elo rating based on performance against all other bots"
          >
            <span><strong>Estimated Elo: </strong></span
            >{{ botProfile.bot.strength.estimated_elo
            }}<img
              alt="Tooltip icon"
              style="height: 0.8rem; margin-left: 0.2rem; margin-top: -0.6rem"
              src="https://storage.googleapis.com/chessiverse1/images/elements/tooltip.webp"
            />
          </div>
          <div v-if="botProfile.bot.strength.results.all >= 0">
            <div
              class="mt-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Performance against all other bots. Low = lost most games, high = won most games"
            >
              <span class="mt-2"><strong>Against all</strong></span
              ><img
                alt="Tooltip icon"
                style="height: 0.8rem; margin-left: 0.2rem; margin-top: -0.6rem"
                src="https://storage.googleapis.com/chessiverse1/images/elements/tooltip.webp"
              />
            </div>
            <div class="d-flex flex-row mt-1">
              <div class="me-2" style="width: 2.2rem">
                {{ botProfile.bot.strength.results.all + "%" }}
              </div>
              <div class="progress flex-grow-1" style="height: 1.3rem">
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  :title="botProfile.bot.strength.results.all + '%'"
                  :data-bs-original-title="
                    botProfile.bot.strength.results.all + '%'
                  "
                  class="progress-bar"
                  role="progressbar"
                  :style="getProgress(botProfile.bot.strength.results.all)"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>

          <div v-if="botProfile.bot.strength.results.maia9 >= 0">
            <div
              class="mt-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Performance against maia9 Lichess bot. Low = lost most games, high = won most games"
            >
              <span><strong>Against maia9</strong></span
              ><img
                alt="Tooltip icon"
                style="height: 0.8rem; margin-left: 0.2rem; margin-top: -0.6rem"
                src="https://storage.googleapis.com/chessiverse1/images/elements/tooltip.webp"
              />
            </div>

            <div class="d-flex flex-row mt-1">
              <div class="me-1" style="width: 2.2rem">
                {{ botProfile.bot.strength.results.maia9 + "%" }}
              </div>
              <div class="progress flex-grow-1" style="height: 1.3rem">
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  :title="botProfile.bot.strength.results.maia9 + '%'"
                  :data-bs-original-title="
                    botProfile.bot.strength.results.maia9 + '%'
                  "
                  class="progress-bar"
                  role="progressbar"
                  :style="getProgress(botProfile.bot.strength.results.maia9)"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <hr class="hr" style="margin: 1.5rem" />

          <h5 class="card-title">Openings</h5>
          <div v-if="botProfile.bot.strength.openings >= 0">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="How strong games the opening book is based on. 0=beginners, 14=masters"
            >
              <span class="mt-2"><strong>Quality</strong></span
              ><img
                alt="Tooltip icon"
                style="height: 0.8rem; margin-left: 0.2rem; margin-top: -0.6rem"
                src="https://storage.googleapis.com/chessiverse1/images/elements/tooltip.webp"
              />
            </div>
            <div class="d-flex flex-row mt-1">
              <div class="me-1" style="width: 2.2rem">
                {{ botProfile.bot.strength.openings - 1 + "/14" }}
              </div>
              <div class="progress flex-grow-1" style="height: 1.3rem">
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  :title="botProfile.bot.strength.openings - 1 + '/14'"
                  :data-bs-original-title="
                    botProfile.bot.strength.openings - 1 + '/14'
                  "
                  class="progress-bar"
                  role="progressbar"
                  :style="
                    getProgress(
                      ((botProfile.bot.strength.openings - 1) / 14) * 100
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
          <div v-if="botProfile.bot.strength.openings_prep >= 0">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              class="mt-1"
              title="Likelihood to follow the prep. 0=never, 10=always"
            >
              <span><strong>Prep</strong></span
              ><img
                alt="Tooltip icon"
                style="height: 0.8rem; margin-left: 0.2rem; margin-top: -0.6rem"
                src="https://storage.googleapis.com/chessiverse1/images/elements/tooltip.webp"
              />
            </div>
            <div class="d-flex flex-row mt-1">
              <div class="me-2" style="width: 2.2rem">
                {{ botProfile.bot.strength.openings_prep + "/10" }}
              </div>
              <div class="progress flex-grow-1" style="height: 1.3rem">
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  :title="botProfile.bot.strength.openings_prep + '/10'"
                  :data-bs-original-title="
                    botProfile.bot.strength.openings_prep + '/10'
                  "
                  class="progress-bar"
                  role="progressbar"
                  :style="
                    getProgress(
                      (botProfile.bot.strength.openings_prep / 10) * 100
                    )
                  "
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="15"
                ></div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="mb-2 mt-3">
              <a
                type="button"
                class="btn btn-info"
                style="
                  background: white;
                  color: black;
                  height: 1.5rem;
                  width: 4rem;
                  padding: 0;
                "
                >As white
              </a>
              {{
                botProfile.bot.openings.white.length == 0
                  ? "A bit of everything"
                  : botProfile.bot.openings.white.join(", ")
              }}
            </div>
            <div>
              <a
                type="button"
                class="btn btn-info"
                style="
                  background: black;
                  height: 1.5rem;
                  width: 4rem;
                  padding: 0;
                "
                >As black
              </a>
              {{
                botProfile.bot.openings.black.length == 0
                  ? "A bit of everything"
                  : botProfile.bot.openings.black.join(", ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="card bot-challenge">
        <div
          class="card-body"
          style="display: flex; flex-direction: column; align-items: center"
          v-if="!loadingProfile"
        >
          <h5 class="card-title">Challenge</h5>
          <div
            v-if="active != null"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <span>You're already playing a game</span>
            <button
              v-if="active != null"
              type="button"
              class="btn btn-info bounce-top ph-no-capture"
              @click="
                () => {
                  gotoGame();
                  track('bot_profile_modal', 'continue_game', 'click', {
                    isActive: active != null,
                  });
                }
              "
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="'You already have an active game, go finish that!'"
            >
              Go to game
            </button>
          </div>
          <div
            v-else-if="!noUser && useBotsStore().isLocked(botProfile.bot)"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <span>Unlock with premium</span>
            <div style="display: flex; gap: 1rem">
              <button
                type="button"
                class="btn btn-warning bounce-top ph-no-capture"
                @click="
                  () => {
                    gotoPremium();
                    track('bot_profile_modal', 'go_premium', 'click', {
                      isActive: active != null,
                    });
                  }
                "
              >
                <i class="fa-solid fa-crown" /> Get premium
              </button>
            </div>
          </div>
          <div v-else>
            <button
              type="button"
              class="btn btn-info position-relative me-3 bounce-top ph-no-capture"
              @click="
                () => {
                  gotoGame();
                  track('bot_profile_modal', 'play_game', 'click', {
                    isActive: active != null,
                  });
                }
              "
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :data-bs-original-title="'Play against ' + botProfile.bot.name"
            >
              <i class="fa-duotone fa-swords"></i> Play
            </button>
          </div>
        </div>
      </div>
      <div class="card bot-history">
        <div class="card-body">
          <h5 class="card-title">Your match history</h5>
          <Pie
            :data="winLossData"
            :options="options"
            style="width: 15rem; margin: auto"
          />
          <p class="card-text" style="text-align: center; margin-top: -1rem">
            {{ againstScoreString(botProfile.gameStats) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Loader from "@/components/util/Loader.vue";
import { useRouter } from "vue-router";
import { usePageStore } from "@/stores/pageStore";
import { Pie } from "vue-chartjs";
import { useGameStore } from "@/stores/gameStore";
import { useBotsStore } from "@/stores/botStore";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import type { Bot } from "@/types/apitypes";
import type { Game } from "@/types/apitypes";
import { Modal, Tooltip as BsTooltip } from "bootstrap";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { ImageType, LoginModalState } from "@/types/internaltypes";
import { getFlagUrl, isMobileWidth } from "@/util/util";
import { track } from "@/util/tracking";
import BlinkingCursor from "@/components/util/BlinkingCursor.vue";
import TypeWriter from "@/components/util/TypeWriter.vue";
import { useUserStore } from "@/stores/userStore";
import { useModalsStore } from "@/stores/modalsStore";

const props = defineProps({
  botId: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const ps = usePageStore();

const gameStore = useGameStore();
const bs = useBotsStore();
const ms = useModalsStore();
const { active } = storeToRefs(gameStore);

let presentationText = ref("");
let loadingProfile = ref(true);
let loadingPresentationText = ref(true);
const screenWidth = ref(window.innerWidth);
const winLossData = ref(getWinLossData({ wins: 0, draws: 0, losses: 0 }));
const noUser = ref(
  useUserStore().user == null || !useUserStore().user.loggedIn
);

const emit = defineEmits(["challengeBot"]);

let botProfile = ref<{
  bot: Bot;
  games?: Game[];
  gameStats: {
    wins: number;
    losses: number;
    draws: number;
  };
}>({ bot: bs.getBot("unknown"), gameStats: { wins: 0, losses: 0, draws: 0 } });

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

ChartJS.register(ArcElement, Tooltip, Legend);

const options = ref({
  responsive: false,
  maintainAspectRatio: false,
  layout: {
    padding: 30,
  },
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      display: false,
    },
  },
});

watch(
  () => props.botId,
  (newValue) => {
    init(props.botId);
  }
);

onMounted(() => {
  init(props.botId);
});

window.addEventListener(
  "resize",
  () => (screenWidth.value = window.innerWidth)
);

async function init(botId: string) {
  loadingProfile.value = true;
  loadingPresentationText.value = true;

  presentationText.value = "";
  bs.getChat(botId, "introduction", {}, noUser.value)
    .then((data) => {
      presentationText.value = data;
      loadingPresentationText.value = false;
    })
    .catch(() => {
      // If no presentation text is found, just leave it empty for now
      loadingPresentationText.value = false;
    });

  let r = await bs.getUserBotProfile(botId, noUser.value);
  botProfile.value = r.data;

  winLossData.value = getWinLossData(botProfile.value?.gameStats!);

  loadingProfile.value = false;
}

const getProgress = (strength: number) => {
  return "height: 1.3rem; width: " + strength + "%";
};

function gotoPremium() {
  emit("challengeBot", null); // This just triggers the close modal if the modal was used, should probably rename it
  router.push({
    name: "premium",
  });
}

function gotoGame() {
  if (noUser.value) {
    ms.showLogin(LoginModalState.Signup);
  } else {
    const query =
      active.value != null
        ? { id: active.value.id, type: "continue" }
        : { bid: props.botId, type: "casual" };

    emit("challengeBot", query);
    router
      .push({
        name: "game",
        query: query,
      })
      .then(() => {
        router.go(0);
      });
  }
}

function getWinLossData(score: {
  wins: number;
  draws: number;
  losses: number;
}): any {
  if (score.wins + score.draws + score.losses == 0) {
    return {
      labels: ["No games yet"],
      datasets: [
        {
          backgroundColor: ["#CCCCCC"],
          data: [1],
          hoverOffset: 30,
          borderWidth: 0,
        },
      ],
    };
  }

  const numberOfZeros =
    (score.wins > 0 ? 0 : 1) +
    (score.losses > 0 ? 0 : 1) +
    (score.draws > 0 ? 0 : 1);

  return {
    labels: ["Wins", "Losses", "Draws"],
    datasets: [
      {
        backgroundColor: ["#52B817", "#B82D17", "#CCCCCC"],
        data: [score.wins, score.losses, score.draws],
        hoverOffset: numberOfZeros == 2 ? 30 : 30,
        borderWidth: numberOfZeros == 2 ? 0 : 3,
      },
    ],
  };
}

const againstScoreString = (score: {
  wins: number;
  draws: number;
  losses: number;
}): string => {
  if (score.wins + score.draws + score.losses == 0) {
    return "No games played";
  }

  return (
    " Win/draw/loss: " + score.wins + "/" + score.draws + "/" + score.losses
  );
};
</script>
<style lang="scss" scoped>
.bot-profile-wrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  margin-top: -4rem;
}

.bot-profile-wrapper > div {
  text-align: center;
}

.bot-image {
  display: flex;
  justify-content: center;
  grid-area: 1 / 1 / 2 / 4;
  margin-bottom: -1rem;
}

.bot-image img {
  width: min(100%, 20rem);
}

.bot-presentation {
  grid-area: 2 / 2 / 4 / 3;
}

.bot-stats {
  grid-area: 2 / 1 / 4 / 2;
}

.bot-challenge {
  grid-area: 2 / 3 / 3 / 4;
}

.bot-history {
  grid-area: 3 / 3 / 4 / 4;
}

@media (max-width: 992px) {
  .bot-profile-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
  }

  .bot-image {
    grid-area: 1 / 1 / 2 / 2;
  }

  .bot-challenge {
    grid-area: 2 / 1 / 3 / 2;
  }

  .bot-presentation {
    grid-area: 3 / 1 / 4 / 2;
  }

  .bot-stats {
    grid-area: 4 / 1 / 5 / 2;
  }

  .bot-history {
    grid-area: 5 / 1 / 6 / 2;
  }
}

// Cursor blinking CSS Starts...
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}

// Cursor blinking CSS Ends...
</style>
