import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAqbEV-j7edaiXHrFLyRnS3UgS1tN8TACI",
  authDomain: "narochess.firebaseapp.com",
  projectId: "narochess",
  storageBucket: "narochess.appspot.com",
  messagingSenderId: "241305620106",
  appId: "1:241305620106:web:c9edf697d1cd87b5fb6773",
  measurementId: "G-22BDE8T00K",
};

const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

export const auth = getAuth(app);
