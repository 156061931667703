<template>
  <div style="text-align: center; min-height: 3rem">
    <div v-if="challengeState == ChallengeState.ViewingPuzzle">
      <a
        type="button"
        class="btn btn-info bounce-top ph-no-capture"
        @click="
          () => {
            emit('userInput', { type: UserInput.StartPuzzle });
            track('question_box', 'start_puzzle', 'click', {
              continuing: true,
            });
          }
        "
        >Continue
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.FinishedPuzzleSet &&
        (unbeatenBot != null || unbeatenBotNext != null)
      "
      style="display: flex; gap: 0.5rem; justify-content: center"
    >
      <a
        v-if="unbeatenBot != null"
        type="button"
        class="btn btn-info ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.MorePuzzles,
              bot: unbeatenBot,
            });
            track('question_box', 'first_unbeaten_puzzle', 'click', {
              botId: unbeatenBot!.id,
            });
          }
        "
      >
        "First unbeaten"
      </a>
      <a
        v-if="unbeatenBotNext != null"
        type="button"
        class="btn btn-info ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.MorePuzzles,
              bot: unbeatenBotNext,
            });
            track('question_box', 'next_unbeaten_puzzle', 'click', {
              botId: unbeatenBot?.id,
            });
          }
        "
      >
        "Next unbeaten"
      </a>
    </div>
    <div
      v-if="challengeState == ChallengeState.PlayingPuzzle"
      style="display: flex; gap: 1rem; justify-content: center"
    >
      <a
        v-if="puzzleHintAvailable"
        type="button"
        class="btn btn-info ph-no-capture"
        @click="
          () => {
            resignOrHintDoubleCheckInput(UserInput.UsePuzzleHint, useHintText);
            track('question_box', 'use_puzzle_hint', 'click');
          }
        "
      >
        {{ resignOrHintDoubleCheckText }}
      </a>
      <HintsBox :opponent-color="opponentColor" />
    </div>
    <div
      v-if="
        (challengeState == ChallengeState.FinishedCasualWin ||
          challengeState == ChallengeState.FinishedCasualNotWin ||
          challengeState == ChallengeState.FinishedRatedWin ||
          challengeState == ChallengeState.FinishedRatedNotWin) &&
        !gaveFeedback
      "
    >
      <div v-if="askForBotFeedback">
        <span class="blue-text">How was the game?</span>
        <a
          type="button"
          class="btn btn-info ph-no-capture"
          style="padding: 0.2rem"
          @click="
            () => {
              initialFeedback(FeedbackType.Happy);
              track('question_box', 'game_feedback', 'click', {
                rating: 'happy',
              });
            }
          "
        >
          <img
            alt="Happy face icon"
            style="height: 2rem"
            src="@/assets/images/happy.svg"
          />
        </a>
        <a
          type="button"
          class="btn btn-info mx-2 ph-no-capture"
          style="padding: 0.2rem"
          @click="
            () => {
              initialFeedback(FeedbackType.Neutral);
              track('question_box', 'game_feedback', 'click', {
                rating: 'neutral',
              });
            }
          "
        >
          <img
            alt="Neutral face icon"
            style="height: 2rem"
            src="@/assets/images/neutral.svg"
          />
        </a>
        <a
          type="button"
          class="btn btn-info ph-no-capture"
          style="padding: 0.2rem"
          @click="
            () => {
              initialFeedback(FeedbackType.Sad);
              track('question_box', 'game_feedback', 'click', {
                rating: 'sad',
              });
            }
          "
        >
          <img
            alt="Sad face icon"
            style="height: 2rem"
            src="@/assets/images/sad.svg"
          />
        </a>
      </div>
    </div>
    <div
      v-if="
        (challengeState == ChallengeState.FinishedCasualWin ||
          challengeState == ChallengeState.FinishedCasualNotWin ||
          challengeState == ChallengeState.FinishedRatedWin ||
          challengeState == ChallengeState.FinishedRatedNotWin) &&
        gaveFeedback &&
        !gaveMoreFeedback
      "
    >
      <div v-if="askForMoreFeedback">
        <span class="blue-text">Thanks! Want to add more detail?</span>
        <div style="display: flex; gap: 0.5rem; justify-content: center">
          <a
            type="button"
            class="btn btn-info ph-no-capture"
            v-if="askForBotFeedback"
            @click="
              () => {
                ms.showGameFeedback(
                  initialFeedbackRating,
                  gameId,
                  sentFeedback
                );
                track(
                  'question_box',
                  'show_more_game_feedback_dialog',
                  'click'
                );
              }
            "
          >
            Give feedback
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="
        (challengeState == ChallengeState.FinishedCasualWin ||
          challengeState == ChallengeState.FinishedCasualNotWin ||
          challengeState == ChallengeState.FinishedRatedWin ||
          challengeState == ChallengeState.FinishedRatedNotWin) &&
        gaveFeedback &&
        gaveMoreFeedback
      "
    >
      <!-- Nothing in the question box here for now -->
    </div>
    <div
      v-if="challengeState == ChallengeState.FinishedChallengeDifficultyNotWin"
    >
      <a
        type="button"
        class="btn btn-info me-2 ph-no-capture"
        @click="
          () => {
            emit('userInput', { type: UserInput.RetryChallenge });
            track('question_box', 'retry_challenge', 'click', {
              botId: unbeatenBot?.id,
            });
          }
        "
      >
        "Try again"
      </a>
    </div>
    <div v-if="challengeState == ChallengeState.FinishedChallengeDifficultyWin">
      <a
        v-if="unbeatenChallenge != null"
        type="button"
        class="btn btn-info ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.PlayNext,
              unbeatenChallenge: unbeatenChallenge,
            });
            // @ts-ignore
            track('question_box', 'next_difficulty_challenge', 'click', {
              challenge: unbeatenChallenge,
            });
          }
        "
      >
        "Next difficulty"
      </a>
      <a
        v-else
        type="button"
        class="btn btn-info ph-no-capture"
        href="/play"
        @click="track('question_box', 'play_other_opponent_challenge', 'click')"
      >
        "Play other"
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.FinishedPracticeWin ||
        challengeState == ChallengeState.FinishedPracticeNotWin
      "
    >
      <a
        type="button"
        class="btn btn-info me-2 ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.RetryPractice,
              switchOpponent: false,
            });
            // @ts-ignore
            track('question_box', 'retry_practice_same_opponent', 'click');
          }
        "
      >
        "Try again"
      </a>
      <a
        type="button"
        class="btn btn-info me-2 ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.RetryPractice,
              switchOpponent: true,
            });
            // @ts-ignore
            track('question_box', 'retry_practice_switch_opponent', 'click');
          }
        "
      >
        "New opponent"
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.FinishedDailyMatchupWin ||
        challengeState == ChallengeState.FinishedDailyMatchupNotWin
      "
    >
      <a
        type="button"
        class="btn btn-info me-2"
        href="/play"
        @click="
          () => {
            // @ts-ignore
            track(
              'question_box',
              'retry_play_other_after_daily_matchup',
              'click'
            );
          }
        "
      >
        "Play other"
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.FinishedChallengeCustomWin ||
        challengeState == ChallengeState.FinishedChallengeCustomNotWin
      "
    >
      <a
        type="button"
        class="btn btn-info me-2 ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.RetryCustomChallenge,
              switchOpponent: false,
            });
            // @ts-ignore
            track(
              'question_box',
              'retry_custom_practice_same_opponent',
              'click'
            );
          }
        "
      >
        "Try again"
      </a>
      <a
        type="button"
        class="btn btn-info me-2 ph-no-capture"
        @click="
          () => {
            emit('userInput', {
              type: UserInput.RetryCustomChallenge,
              switchOpponent: true,
            });
            // @ts-ignore
            track(
              'question_box',
              'retry_custom_practice_switch_opponent',
              'click'
            );
          }
        "
      >
        "New opponent"
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.PlayingCasual ||
        challengeState == ChallengeState.FirstMoveCasual ||
        challengeState == ChallengeState.FirstMoveRated ||
        challengeState == ChallengeState.PlayingRated
      "
      style="display: flex; gap: 0.5rem; justify-content: center"
    >
      <div>
        <a
          type="button"
          :class="
            'btn ph-no-capture ' +
            (clickedResignOrHintDoubleCheck ? 'btn-danger' : 'btn-info')
          "
          @click="
            () => {
              resignOrHintDoubleCheckInput(UserInput.Resign, resignText);
              track(
                'question_box',
                clickedResignOrHintDoubleCheck
                  ? 'second_resign'
                  : 'first_resign',
                'click'
              );
            }
          "
        >
          {{ resignOrHintDoubleCheckText }}
        </a>
        <button
          type="button"
          class="btn btn-info ph-no-capture"
          v-if="clickedResignOrHintDoubleCheck"
          @click="
            () => {
              clickedResignOrHintDoubleCheck = false;
              resignOrHintDoubleCheckText = resignText;
              track('question_box', 'cancel_resign', 'click');
            }
          "
        >
          <i class="fa-solid fa-xmark" color="white" />
        </button>
      </div>
      <div v-if="canAbort()">
        <a
          type="button"
          :class="
            'btn ph-no-capture ' +
            (clickedAbortDoubleCheck ? 'btn-danger' : 'btn-info')
          "
          @click="
            () => {
              abortDoubleCheckInput();
              track(
                'question_box',
                clickedAbortDoubleCheck ? 'second_abort' : 'first_abort',
                'click'
              );
            }
          "
        >
          {{ abortDoubleCheckText }}
        </a>
        <button
          type="button"
          class="btn btn-info ph-no-capture"
          v-if="clickedAbortDoubleCheck"
          @click="
            () => {
              clickedAbortDoubleCheck = false;
              abortDoubleCheckText = abortText;
              track('question_box', 'cancel_abort', 'click');
            }
          "
        >
          <i class="fa-solid fa-xmark" color="white" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref, type Ref, watch } from "vue";
import { ChallengeState, UserInput } from "@/types/internaltypes";
import {
  type Bot,
  type ChallengeFromPosition,
  FeedbackType,
} from "@/types/apitypes";
import { useGameStore } from "@/stores/gameStore";
import { useUserStore } from "@/stores/userStore";
import { useModalsStore } from "@/stores/modalsStore";
import HintsBox from "@/components/games/HintsBox.vue";
import { track } from "@/util/tracking";
import { useToast } from "vue-toast-notification";

const props = defineProps({
  challengeState: {
    type: Number as PropType<ChallengeState | null>,
    default: null,
  },
  gameId: { type: String, default: null },
  opponentColor: { type: String, default: "#fff" },
  puzzleHintAvailable: { type: Boolean, default: false },
});

let askForBotFeedback = true; // Initial quick feedback
let askForMoreFeedback = false; // When quick feedback is given, ask for more feedback in a dialog

const us = useUserStore();
const ms = useModalsStore();
const gameStore = useGameStore();
const useHintText = "Use hint";
const resignText = "I resign";
const abortText = "Abort game";
const emit = defineEmits(["userInput"]);

let unbeatenBot: Ref<Bot | null> = ref(null);
let unbeatenBotNext: Ref<Bot | null> = ref(null);
let unbeatenChallenge: Ref<{
  challenge: ChallengeFromPosition;
  unbeatenDifficulty: string;
} | null> = ref(null);

let clickedResignOrHintDoubleCheck = ref(false);
let resignOrHintDoubleCheckText = ref(
  props.challengeState == ChallengeState.PlayingPuzzle
    ? useHintText
    : resignText
);

let clickedAbortDoubleCheck = ref(false);
let abortDoubleCheckText = ref(abortText);

let gaveFeedback = ref(false);
let gaveMoreFeedback = ref(false);
let initialFeedbackRating: Ref<FeedbackType> = ref(FeedbackType.Neutral); // This will be reset to whatever the user picks, just need some initial value since we don't want to do unnecessary null checks downstream

watch(props, () => {
  resignOrHintDoubleCheckText.value =
    props.challengeState == ChallengeState.PlayingPuzzle
      ? useHintText
      : resignText;

  refreshUnbeaten();
});

refreshUnbeaten();

function refreshUnbeaten() {
  if (props.challengeState == ChallengeState.FinishedCasualWin) {
    us.getNextunbeatenBot().then((bot) => {
      unbeatenBot.value = bot;
    });
  }
  if (props.challengeState == ChallengeState.FinishedChallengeDifficultyWin) {
    us.getNextUnbeatenChallenge().then((nextUnbeatenChallenge) => {
      unbeatenChallenge.value = nextUnbeatenChallenge;
    });
  }
  if (props.challengeState == ChallengeState.FinishedPuzzleSet) {
    us.getNextUnfinishedPuzzleSet(props.gameId).then((unfinishedPuzzleSets) => {
      unbeatenBot.value =
        unfinishedPuzzleSets?.first === undefined
          ? null
          : unfinishedPuzzleSets.first;
      unbeatenBotNext.value =
        unfinishedPuzzleSets?.next === undefined
          ? null
          : unfinishedPuzzleSets.next;
    });
  }
}

function rematch() {
  emit("userInput", { type: UserInput.Rematch });
}

const sentFeedback = (feedback: any) => {
  gaveMoreFeedback.value = feedback != null;
};

const initialFeedback = (feedback: FeedbackType) => {
  if (props.gameId == null) {
    console.error("No gameId while submitting feedback");
    return;
  }
  initialFeedbackRating.value = feedback;
  gaveFeedback.value = true;
  gameStore.sendFeedback(props.gameId, { initialRating: feedback }); // Not waiting for feedback actually sent, fire or forget since we won't retry
  if (!askForMoreFeedback) {
    // If we're not going to ask for more feedback we should notify that the feedback was sent here.
    if (feedback == FeedbackType.Happy) {
      useToast().success("Feedback sent. Thanks!");
    } else {
      useToast().success(
        "Feedback sent! You can add more detail with the feedback button in the menu."
      );
    }
  }
};

let resignOrHintDoubleCheckTimeout: number | null = null;
function resignOrHintDoubleCheckInput(action: UserInput, defaultText: string) {
  if (clickedResignOrHintDoubleCheck.value) {
    emit("userInput", { type: action });
  } else {
    clickedResignOrHintDoubleCheck.value = true;
    resignOrHintDoubleCheckText.value = "Confirm";

    if (resignOrHintDoubleCheckTimeout) {
      clearTimeout(resignOrHintDoubleCheckTimeout);
    }

    resignOrHintDoubleCheckTimeout = setTimeout(() => {
      resignOrHintDoubleCheckText.value = defaultText;
      clickedResignOrHintDoubleCheck.value = false;
    }, 5000);
  }
}

let abortDoubleCheckTimeout: number | null = null;
function abortDoubleCheckInput() {
  if (clickedAbortDoubleCheck.value) {
    emit("userInput", { type: UserInput.Abort });
  } else {
    clickedAbortDoubleCheck.value = true;
    abortDoubleCheckText.value = "Confirm";

    if (abortDoubleCheckTimeout) {
      clearTimeout(abortDoubleCheckTimeout);
    }

    abortDoubleCheckTimeout = setTimeout(() => {
      abortDoubleCheckText.value = abortText;
      clickedAbortDoubleCheck.value = false;
    }, 5000);
  }
}

function canAbort() {
  return (
    props.challengeState == ChallengeState.FirstMoveCasual ||
    props.challengeState == ChallengeState.FirstMoveRated ||
    localStorage.getItem("gamesettings_flash") == "0"
  );
}
</script>

<style scoped>
.blue-text {
  position: absolute;
  display: block;
  margin: auto;
  top: -1.5rem;
  right: 0;
  left: 0;
  color: var(--clr-dark-accent);
}

@media (max-width: 1200px) {
  .blue-text {
    top: 3rem;
  }
}
</style>
