export enum ChatPromptType {
  EndOfGame = 1, // Game is over
  StartOfGame, // Before first move
  StartOfChallenge, // Before first move in a challenge
  StartOfPuzzle, // First puzzle in a puzzle set, will include some sort of introduction too
  StartOfPuzzleSet, // Other puzzle in a puzzle set, will include refernce to previous puzzles
  ContinuingPuzzleSet, // Other puzzle in a puzzle set, will include refernce to previous puzzles
  FinishedPuzzleSet, // Finished a puzzle that was not the last in a puzzle set, will talk about the result of this puzzle
  LostCastling, // One side lost castling rights somehow (moved king or similar)
  OutOfBook, // This is the point the engine takes over
  NewOpeningVariation, // A named opening variation was reached (e.g. Sicilian)
  SpecialMove, // A special move (castling, en passant, promption) was played
  ChangedPhaseToMiddle, // The game moved from opening to middle game (determined by # of moves and pieces)
  ChangedPhaseToEnding, // The game moved from middle to ending game (determined by # of moves and pieces)
  BlunderUser, // The evaluation (according to our strongest bot), changed a lot in favor of the bot
  BlunderOpponent, // The evaluation changed a lot in favor of the user
  NoMessagePeriod, // A certain number of moves were played without any chats being sent
  AskForColor, // Awaiting user to pick color
  SaidGoodGame, // User inputed "Good game"
  Introduction, // The user asked for an introduction
}

export enum GameTermination {
  Resign,
  Checkmate,
  Stalemate,
  Threefold,
  InsufficientMaterial,
  FiftyMove,
  Time, // A side ran out of time
  Aborted, // The game was aborted by the user
}

export enum GamePhase {
  Opening,
  Middle,
  Ending,
}

export enum Side {
  White,
  Black,
}

export function getSide(input: string): Side {
  switch (input) {
    case "w":
    case "white":
      return Side.White;
    case "b":
    case "black":
      return Side.Black;
    default:
      throw "No side matching " + input;
  }
}

export enum Result {
  White,
  Black,
  Draw,
}

export function getResult(input: string): Result | null {
  // Remove all whitespaces so we accept input as "1 - 0" also
  input = input.replace(/\s/g, "");

  switch (input) {
    case "1-0":
      return Result.White;
    case "0-1":
      return Result.Black;
    case "0.5-0.5":
    case "1/2-1/2":
      return Result.Draw;
    default:
      throw "No result matching " + input;
  }
}

export type ChatPrompt = {
  message: string;
  type: ChatPromptType;
};

export type Chat = {
  chat: string;
  type: ChatPromptType;
};

export type ChatTriggerId =
  | "end_of_game_bot_lost"
  | "end_of_game_draw_repetition"
  | "end_of_game_draw_50"
  | "end_of_game_draw_stalemate"
  | "end_of_game_draw_material"
  | "end_of_game_bot_won_resign"
  | "end_of_game_bot_won_checkmate"
  | "ask_for_color"
  | "start_of_game_rated"
  | "start_of_game_casual"
  | "start_of_game_chose_white"
  | "start_of_game_chose_black"
  | "start_of_game_random_white"
  | "start_of_game_random_black"
  | "lost_castling_bot"
  | "lost_castling_user"
  | "check_user"
  | "check_bot"
  | "out_of_book"
  | "named_opening_variation"
  | "castle_short_bot"
  | "castle_long_bot"
  | "castle_short_user"
  | "castle_long_user"
  | "en_passant_bot"
  | "en_passant_user"
  | "promption_queen_bot"
  | "promotion_queen_user"
  | "underpromotion_bot"
  | "underpromotion_user"
  | "trade_bot"
  | "trade_user"
  | "capture_gain_bot"
  | "capture_gain_user"
  | "capture_loss_bot"
  | "capture_loss_user"
  | "entered_middlegame"
  | "entered_endgame"
  | "blunder_bot"
  | "blunder_user"
  | "start_of_puzzleset"
  | "continuing_puzzleset"
  | "start_of_challenge"
  | "introduction"
  | "start_of_puzzle"
  | "finished_puzzleset"
  | "end_of_challenge_succeeded"
  | "end_of_challenge_failed"
  | "end_of_daily_matchup_succeeded"
  | "end_of_daily_matchup_failed"
  | "daily_matchup_ongoing"
  | "daily_matchup_finished"
  | "no_message_period";

export type ChatTrigger = {
  id: ChatTriggerId; // Identfier
  name: string; // Short name used to show it in admin
  description: string; // More detail and also explain how it's triggered
  prompt: string; // The prompt that's used to build the chatgpt prompt
  params?: {
    id: string; // Identifiered to be referenced inside curly bracers in the trigger prompt
    description: string; // Explanation on what the parameter is
    example: string; // Example parameter to understand what it is, but also what will be used in admin
  }[];
  cache: number; // How many times this needs to be stored in the cache before starting to reuse from cache. 0 means never cache. For example introduction might have 1, so it will be generated only one time, and things that need more variance something like 50 or so.
  in_use: boolean; // If this trigger is currently being called anywhere in the frontend
};

export type TimeControl = {
  initial: number; // Initial time in milliseconds
  increment: number; // Increment in milliseconds
};

export type TimeSpeed = "instant" | "quick" | "average" | "extended" | "long";
export type TimeMoveType =
  | "first_move" // First move of the game, should be quick, this is mainly here to make the bot move quickly on the first move of setup positions
  | "book" // Still using the book moves, might want to limit how long we consider it book (even the weakest bots can have deep books, should probably use the book strength to determine when we consider the bot out fo book for time usage purposes)
  | "out_of_book" // First move out of book, it's common that you use more time on this move since now you actually have to start thinking
  | "thinking" // Normal move, simulating thinking about a new idea or combination
  | "followup" // After a normal thinking move, a few quicker moves are expected, simulating the bot thinking about an idea, and then executing it for a couple of moves
  | "recapture" // Recapturing a piece that was just taken is usually a reflex and should be quick
  | "castle_reaction" // Castling after the opponent just castled is commonly a reflex move (the user castling very rarely poses a new threat, so castling is usually safe)
  | "one_legal" // Only one legal move, should be quick (a human might spend time thinking on the next move, but that's annoying so no point simulating that bad behaviour)
  | "one_good" // If one move is clearly better than the rest and the bot is about to play it, it's probably safe to be a quick move (things like stopping a mate, or taking a piece or similar)
  | "mate_in_1" // Mate in 1 should be quick, could potentially put some variance here to simulate the bot missing it at first, but safe to just make it quick
  | "unknown"; // Just a fallback, hopefully won't happen

export type TimeDelay = {
  delay: number; // Delay in milliseconds
  moveType: TimeMoveType; // Move type for the delay
  speed: TimeSpeed; // Speed of the move, determined by the move type
};

export type Game = {
  id: string;
  type?: // It's set to optional for legacy reasons only, it should always be set going forward, we can calculate the type from other fields if it's missing and we really need it
  | "challenge"
    | "practice"
    | "custom"
    | "rated"
    | "casual"
    | "dailymatchup"
    | "dailyendgame"
    | "dailymaster";
  userId: string;
  isActive: boolean; // If the game finished yet or not, might be redundant since result=null would mean the same as isActive=false
  userSide: Side; // Side the user is playing
  startPosition?: string; // FEN of the start position, only used for some types of games
  positions: string[]; // FEN of the positions after each move, wheren 0 is move 1 and so on
  moves: string[]; // UCI moves, so e2e4, and e7e8q for promotion
  movesSan: string[]; // SAN moves, so e4, and e8=Q for promotion
  times?: Date[]; // Server time when the move was received (for user moves), and calculated future server time response time for bot moves (so when returned to the client, the bot move server time might be in the future)
  timesInfo?: (TimeDelay | null)[];
  lastMoveSpentTime?: number; // If it's the user's turn, this is the time spent on the last move (this is needed sice the client can't calculate this with the server timestamps only)
  infos: string[]; // Result from the info coming from the engine, like score, depth, nodes, time etc. This is where we get the move from and put in moves and movesSan, storing it just in case
  termination: GameTermination | null; // How the game ended, if it ended
  result: Result | null; // Which side won (note, to see if the user won we need to compare this with userSide)
  timeControl?: TimeControl; // Time control of the game, if it's a timed game
  rated?: boolean; // If the game was rated or not
  ratingChange?: {
    // If rated, rating change after the game, and also potential rating change before game is finished
    potential: {
      win: number;
      draw: number;
      loss: number;
    };
    old: Rating;
    new?: Rating;
  };
  bot: {
    id: string;
    chats: (Chat | null)[]; // Chats sent at certain half-move numbers (index 0 is before game starts)
  };
  startedAt: Date; // When this entry was created, which is the same as the game started for now
  endedAt?: Date; // If the game ended, this is when
  feedback?: DetailedFeedback; // Feedback from user after the game
  challenge?: {
    id: string;
    difficulty: string;
    winCondition?: "win" | "draw" | "lose"; // Used when recording beaten practices, since a practice can be to hold a draw for example
  };
};

export type Rating = {
  rating: number;
  ratingDeviation: number;
  volatility: number;
};

export type User = {
  id: string;
  roles?: string[];
  rating?: Rating;
  ratingHistory?: { rating: number; msSinceEpoch: number }[]; // For now a way to give some rating history, the real history is stored in the games, but will be costly to get out on each call. We should improve this with a real time series database in the future for more stats
  authUser?: UserRecord;
  playedGames?: number;
  beatenBots: string[];
  beatenChallenges: Record<
    string,
    ("beginner" | "novice" | "intermediate" | "skilled" | "advanced")[]
  >; // Challenge-id to diffuculties, we could type this to only contain one of each difficulty, but we'll remove duplicates runtime instead, won't have a big impact
  beatenPractices: Record<
    string,
    {
      id: string;
      botId: string;
      botRating: number;
      points: number;
    }
  >;
  dailyMatchupStreaks?: {
    current: number; // Current number of daily wins in a row
    best: number; // Best number of daily wins in a row
  };
};

export type UserFeedback = {
  message: string;
  created: Date;
};

export enum FeedbackType {
  Happy,
  Sad,
  Neutral,
}

export type FeedbackEntry = {
  rating: FeedbackType;
  comment?: string;
};

export type DetailedFeedback = {
  initialRating: FeedbackType;
  generalComment?: string;
  opening?: FeedbackEntry;
  humanLike?: FeedbackEntry;
  chats?: FeedbackEntry;
  created?: Date; // Set on server
};

// Represents the history of a puzzle attempt, only the user's move (index 0 = first user move, index 1 second etc)
// For example a puzzle with 2 moves where the user uses one hint on the
// first move and then gets it correct, then two hints on the second move and still gets it wrong would be
// [["hint1", "correct_move"], ["hint1", "hint2", "wrong_move"]]
export type PuzzleSolveHistory = (
  | "wrong_move" // User played the wrong move on the board
  | "correct_move" // User played the correct move on the board
  | "hint1" // User requested the first hint
  | "hint2" // User requested the second hint
  | "solution" // User requested and got the solution
  | "failed"
)[][]; // User made a wrong move and didn't have any hint points left so the puzzle failed // User requested the solution // User requested the solution

export type ChallengeFromPosition = {
  id: string;
  type: "from_position";
  start?: Date;
  end?: Date;
  customBot?: {
    botId: string;
    previousBestBeatenBot?: {
      id: string;
      rating: number;
      gainedPoints: number;
    };
  };
  difficulties?: {
    beginner: { botId: string; userWon: boolean };
    novice: { botId: string; userWon: boolean };
    intermediate: { botId: string; userWon: boolean };
    skilled: { botId: string; userWon: boolean };
    advanced: { botId: string; userWon: boolean };
  };
  start_position: string;
  initial_moves?: string; // Moves to get to the start position (mainly used to create links to Lichess)
  user_side: string;
  presentation?: {
    title: string;
    subtitle: string;
    description: string;
  };
};

// Keeping the structure flat so it's easier to store in Google Sheets
export type PracticePosition = {
  id: string; // Unique id for this practice position
  section_type: "endgame" | "opening" | "middlegame" | "masters"; // Section type, e.g. endgame
  section_title: string; // Section name, e.g. Endgames for beginners
  section_description: string; // Section description, e.g. Endgames you really should now
  category_title: string; // Category name, e.g.  Basic Checkmates
  category_description: string; // Category description, e.g. Mating with overwhelming material
  category_icon: string; // For now this is font awesome class names, like "fa-solid fa-chess"
  name: string; // Position name, e.g. Two Queens
  difficulty: "beginner" | "novice" | "intermediate" | "skilled" | "advanced"; // Difficulty of the practice position
  start_position: string; // Fen representing the start position
  side: "white" | "black"; // Side to move
  goal: "win" | "draw" | "lose"; // Goal of the practice
  user_achieved?: boolean; // User finished this practice position (with any bot), if true the below values will be set, otherwise they'll be omitted
  user_points?: number; // Scored points at the time of finishing against the best bot (since bot ratings might change, it's important to not have this dynamic on the bot rating for example)
  user_botId?: string; // What bot was beaten
  user_botRatingAtWin?: number; // The bot rating as of the time of winning (the bot rating might've changed later)
};

export type Puzzle = {
  id: string; // Unique id for this puzzle
  source_id: string; // Id in the source
  source: "lichess"; // Source of the puzzle
  bot_id: string; // Id of the bot this puzzle belongs to (which is also the group id)
  fen: string; // Fen representing the puzzle position, NOTE: this is one move before the puzzle starts and the first opponent move need to be made first. Which also means that the side to move in the fen, is the opposite of the side the puzzle is for
  side: "white" | "black"; // Side of the user to play, this is the opposite of the to move in the fen
  moves: string; // All moves in the puzzle, starting with the opponent's initial move
  opponent_moves: string; // Only the opponent moves in order
  user_moves: string; // Only the user moves in order
  themes: string; // Themes this puzzle involves, from the Lichess db
  hints: { hint1: string; hint2: string; solution: string }[]; // Pregenerated hints
  // Result of the user, if null the user hasn't played this puzzle yet, if not defined there's no user in the request
  user_result?: null | PuzzleSolveHistory;
};

export enum BotStatus {
  Enabled,
  Disabled,
  WIP,
  TextDone,
  Done,
  Unknown,
}

export type Bot = {
  status_code: BotStatus;
  live_date: Date;
  premium: string;
  id: string;
  name: string;
  short_name: string;
  age: string;
  gender: string;
  country: {
    name: string;
    code: string;
  };
  occupation: string;
  introduction: string; // Clear description in plain text
  favorite_quote: string;
  ongoing_game_quote: string;
  backstory: string;
  tonality: string;
  lucky_charm: string;
  openings: {
    white: string[];
    black: string[];
  };
  strength: {
    estimated_elo: number;
    playstyle: number;
    openings:
      | -1
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | 12
      | 13
      | 14
      | 15; // 1=100-800 rating, 2=900 ... 14=2100 and 15=2200-3000. -1 means it shouldn't be used (e.g. for custom bots)
    openings_prep: -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10; // Likelihood that the forced line is being followed, directly translated to percent 0=0% 10 = 100%. -1 means it shouldn't be used (e.g. for custom bots)
    openings_depth: number; // How long to follow the opening book in percent. 50% means there's 50% chance of leaving opening book for every move
    blunder_likelihood: number; // Technically this is the likelihood that the bot will not get saved by a stronger engine if about to blunder (0.95 mean the bot will use a stronger bot's move 5% of the time if about to blunder)
    results: {
      maia9: number; // Win percentage against maia9
      all: number; // Win percentage against all bots
    };
  };
  config: {
    boardbg: string; // Main background color for the board
  };
  user?: {
    hasWon: boolean; // Optionally added by the backend to certain queries
  };
  hidden?: {
    introduction_override?: string; // Used if the normal introduction needs to be tweaked when calling chatgpt.
    engine: {
      name: string; // Identifier of the engine, which corresponds to the engine defition in the lc0wrapper project
    };
    openings: {
      force_lines_white: string[];
      force_lines_black: string[];
      file_white?: string;
      file_black?: string;
    };
  };
};

export type DailyMatchup = {
  botId: string; // The bot in the matchup, generated around the users rating
  dayId: string; // On the form YYYY-MM-DD and will be used as an id, if for some reason the matchup is generated twice on the same day, it will reference the same matchup. It's unique together with the user id
  userSide: Side; // The side the user is playing
  result: "success" | "fail" | null; // Success means the user played and won, fail they played and lost or drew, and null means they haven't played yet
  gameId: string | null; // Not really needed, but since we're going to store the result of the game, we might as well chuck the game id in there too
};

export type DailyPosition = {
  type: "dailyendgame" | "dailymaster"; // Type of position. This is used to choose correct storage in db, and for presentation, other than that it's just a position
  dayId: string; // On the form YYYY-MM-DD and will be used as an id, if for some reason the matchup is generated twice on the same day, it will reference the same matchup. It's unique together with the user id
  difficulties: {
    // Bot ids for the different difficulties, this is related to the user rating
    simple: { botId: string; userWon: boolean };
    easy: { botId: string; userWon: boolean };
    balanced: { botId: string; userWon: boolean };
    hard: { botId: string; userWon: boolean };
    intense: { botId: string; userWon: boolean };
  };
  userSide: Side; // The side the user is playing
  position: string; // Fen string of the start position
  extra: any; // Some dailyposition might have some extra display data, like who played a game or a description or similar
};

export type NextOpponentSuggestion = {
  lastGame: Game;
  bots: {
    suggested: Bot;
    suggestedFree: Bot;
    random: Bot;
    rematch: Bot;
  };
};

// Copy pasted firebase types so we don't need to import the whole firebase-admin package when using this type file

/*! firebase-admin v11.10.1 */
/*!
 * @license
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export interface MultiFactorInfoResponse {
  mfaEnrollmentId: string;
  displayName?: string;
  phoneInfo?: string;
  totpInfo?: TotpInfoResponse;
  enrolledAt?: string;
  [key: string]: unknown;
}
export interface TotpInfoResponse {
  [key: string]: unknown;
}
export interface ProviderUserInfoResponse {
  rawId: string;
  displayName?: string;
  email?: string;
  photoUrl?: string;
  phoneNumber?: string;
  providerId: string;
  federatedId?: string;
}
export interface GetAccountInfoUserResponse {
  localId: string;
  email?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  displayName?: string;
  photoUrl?: string;
  disabled?: boolean;
  passwordHash?: string;
  salt?: string;
  customAttributes?: string;
  validSince?: string;
  tenantId?: string;
  providerUserInfo?: ProviderUserInfoResponse[];
  mfaInfo?: MultiFactorInfoResponse[];
  createdAt?: string;
  lastLoginAt?: string;
  lastRefreshAt?: string;
  [key: string]: any;
}
/**
 * Interface representing the common properties of a user-enrolled second factor.
 */
export declare abstract class MultiFactorInfo {
  /**
   * The ID of the enrolled second factor. This ID is unique to the user.
   */
  readonly uid: string;
  /**
   * The optional display name of the enrolled second factor.
   */
  readonly displayName?: string;
  /**
   * The type identifier of the second factor.
   * For SMS second factors, this is `phone`.
   * For TOTP second factors, this is `totp`.
   */
  readonly factorId: string;
  /**
   * The optional date the second factor was enrolled, formatted as a UTC string.
   */
  readonly enrollmentTime?: string;
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns A JSON-serializable representation of this object.
   */
  toJSON(): object;
  /**
   * Initializes the MultiFactorInfo object using the provided server response.
   *
   * @param response - The server side response.
   */
  private initFromServerResponse;
}
/**
 * Interface representing a phone specific user-enrolled second factor.
 */
export declare class PhoneMultiFactorInfo extends MultiFactorInfo {
  /**
   * The phone number associated with a phone second factor.
   */
  readonly phoneNumber: string;
  /**
   * {@inheritdoc MultiFactorInfo.toJSON}
   */
  toJSON(): object;
}
/**
 * `TotpInfo` struct associated with a second factor
 */
export declare class TotpInfo {}
/**
 * Interface representing a TOTP specific user-enrolled second factor.
 */
export declare class TotpMultiFactorInfo extends MultiFactorInfo {
  /**
   * `TotpInfo` struct associated with a second factor
   */
  readonly totpInfo: TotpInfo;
  /**
   * {@inheritdoc MultiFactorInfo.toJSON}
   */
  toJSON(): object;
}
/**
 * The multi-factor related user settings.
 */
export declare class MultiFactorSettings {
  /**
   * List of second factors enrolled with the current user.
   * Currently only phone and TOTP second factors are supported.
   */
  enrolledFactors: MultiFactorInfo[];
  /**
   * Returns a JSON-serializable representation of this multi-factor object.
   *
   * @returns A JSON-serializable representation of this multi-factor object.
   */
  toJSON(): object;
}
/**
 * Represents a user's metadata.
 */
export declare class UserMetadata {
  /**
   * The date the user was created, formatted as a UTC string.
   */
  readonly creationTime: string;
  /**
   * The date the user last signed in, formatted as a UTC string.
   */
  readonly lastSignInTime: string;
  /**
   * The time at which the user was last active (ID token refreshed),
   * formatted as a UTC Date string (eg 'Sat, 03 Feb 2001 04:05:06 GMT').
   * Returns null if the user was never active.
   */
  readonly lastRefreshTime?: string | null;
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns A JSON-serializable representation of this object.
   */
  toJSON(): object;
}
/**
 * Represents a user's info from a third-party identity provider
 * such as Google or Facebook.
 */
export declare class UserInfo {
  /**
   * The user identifier for the linked provider.
   */
  readonly uid: string;
  /**
   * The display name for the linked provider.
   */
  readonly displayName: string;
  /**
   * The email for the linked provider.
   */
  readonly email: string;
  /**
   * The photo URL for the linked provider.
   */
  readonly photoURL: string;
  /**
   * The linked provider ID (for example, "google.com" for the Google provider).
   */
  readonly providerId: string;
  /**
   * The phone number for the linked provider.
   */
  readonly phoneNumber: string;
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns A JSON-serializable representation of this object.
   */
  toJSON(): object;
}
/**
 * Represents a user.
 */
export declare class UserRecord {
  /**
   * The user's `uid`.
   */
  readonly uid: string;
  /**
   * The user's primary email, if set.
   */
  readonly email?: string;
  /**
   * Whether or not the user's primary email is verified.
   */
  readonly emailVerified: boolean;
  /**
   * The user's display name.
   */
  readonly displayName?: string;
  /**
   * The user's photo URL.
   */
  readonly photoURL?: string;
  /**
   * The user's primary phone number, if set.
   */
  readonly phoneNumber?: string;
  /**
   * Whether or not the user is disabled: `true` for disabled; `false` for
   * enabled.
   */
  readonly disabled: boolean;
  /**
   * Additional metadata about the user.
   */
  readonly metadata: UserMetadata;
  /**
   * An array of providers (for example, Google, Facebook) linked to the user.
   */
  readonly providerData: UserInfo[];
  /**
   * The user's hashed password (base64-encoded), only if Firebase Auth hashing
   * algorithm (SCRYPT) is used. If a different hashing algorithm had been used
   * when uploading this user, as is typical when migrating from another Auth
   * system, this will be an empty string. If no password is set, this is
   * null. This is only available when the user is obtained from
   * {@link BaseAuth.listUsers}.
   */
  readonly passwordHash?: string;
  /**
   * The user's password salt (base64-encoded), only if Firebase Auth hashing
   * algorithm (SCRYPT) is used. If a different hashing algorithm had been used to
   * upload this user, typical when migrating from another Auth system, this will
   * be an empty string. If no password is set, this is null. This is only
   * available when the user is obtained from {@link BaseAuth.listUsers}.
   */
  readonly passwordSalt?: string;
  /**
   * The user's custom claims object if available, typically used to define
   * user roles and propagated to an authenticated user's ID token.
   * This is set via {@link BaseAuth.setCustomUserClaims}
   */
  readonly customClaims?: {
    [key: string]: any;
  };
  /**
   * The ID of the tenant the user belongs to, if available.
   */
  readonly tenantId?: string | null;
  /**
   * The date the user's tokens are valid after, formatted as a UTC string.
   * This is updated every time the user's refresh token are revoked either
   * from the {@link BaseAuth.revokeRefreshTokens}
   * API or from the Firebase Auth backend on big account changes (password
   * resets, password or email updates, etc).
   */
  readonly tokensValidAfterTime?: string;
  /**
   * The multi-factor related properties for the current user, if available.
   */
  readonly multiFactor?: MultiFactorSettings;
  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns A JSON-serializable representation of this object.
   */
  toJSON(): object;
}

// Api responses
export type ApiResponse = {
  error: boolean;
  status: number;
  message?: string;
};

export type AllBotsResponse = ApiResponse & {
  error: boolean;
  status: number;
  message?: string;
  data: {
    bots: Bot[];
  };
};

export type GetActiveChallengesResponse = ApiResponse & {
  data: {
    challenges: ChallengeFromPosition[];
  };
};

export type GetChallengeResponse = ApiResponse & {
  data: {
    challenge: ChallengeFromPosition;
  };
};

export type GetUnbeatenChallengeResponse = ApiResponse & {
  data: {
    challenge: ChallengeFromPosition;
    unbeatenDifficulty: string;
  };
};

export type GetUnbeatenPuzzleSetResponse = ApiResponse & {
  data: {
    first?: Bot;
    next?: Bot;
  };
};

export type GetPracticesResponse = ApiResponse & {
  data: {
    practices: PracticePosition[];
  };
};

export type GetPuzzlesResponse = ApiResponse & {
  data: {
    puzzles: Puzzle[];
  };
};

export type GetPracticeResponse = ApiResponse & {
  data: {
    practice: PracticePosition;
  };
};

export type GetAdminChatResponse = ApiResponse & {
  data: {
    prompt: string;
    chat: string;
  };
};

export type GetGeneralChatResponse = ApiResponse & {
  data: {
    chat?: string;
    type: "cache" | "fresh" | "triggered" | "none" | "error";
  };
};

export type RequestGameChatResponse = ApiResponse & {
  data: {
    chat: string; // Not returning the type here since it's very internal
  };
};

export type GameUpdateResponse = ApiResponse & {
  data: {
    game: Game;
    engineTakenTime?: number; // Time the bot used for this move
  };
};

export type NewGameResponse = ApiResponse & {
  data: {
    game: Game;
  };
};

export type GetActiveGamesResponse = ApiResponse & {
  data: {
    games: Game[];
  };
};

export type GetAllUserGamesResponse = ApiResponse & {
  data: {
    games: Game[];
  };
};

export type GetBotProfileResponse = ApiResponse & {
  data: {
    bot: Bot;
    gameStats: { wins: number; losses: number; draws: number };
  };
};

export type GetGameStateResponse = ApiResponse & {
  data: {
    game: Game;
  };
};

export type GetUserDataResponse = ApiResponse & {
  data: {
    user: User;
  };
};

export type GetAllUsersResponse = ApiResponse & {
  data: {
    users: User[];
  };
};

export type GetAllFeedbacksResponse = ApiResponse & {
  data: {
    feedbacks: {
      feedback: UserFeedback;
      userId: string;
      userEmail: string;
    }[];
  };
};

export type GetBotFeedbacksResponse = ApiResponse & {
  data: {
    bots: {
      botId: {
        gameCount: number;
        feedbacks: any[];
      };
    };
  };
};

export type GetGamesPerDayResponse = ApiResponse & {
  data: { date: Date; count: number }[];
};

export type RemoteConfig = {
  openbeta: { value: boolean; description: string };
};

export type GetRemoteConfigResponse = ApiResponse & {
  data: RemoteConfig;
};

export type GetNextUnbeatenBotResponse = ApiResponse & {
  data: {
    first: Bot | null;
    next: Bot | null;
  };
};

export type GetUserQuickStatsResponse = ApiResponse & {
  data: {
    stars: number;
    gamesAll: number;
    gamesToday: number;
    winStreak: number;
    beatenChallenges: number;
    beatenDailyPositions: number;
    practicePoints: number;
    ratingInfo: {
      currentRating: Rating | null; // Current rating of the user
      historyChange: {
        lastGame: number | null; // Difference from last game. E.e. 12 if the user gained 12 rating in the last game
        lastDay: number | null; // Difference from last day. E.e. 12 if the user gained 12 rating in the last day
        lastWeek: number | null; // Difference from last week. E.e. 12 if the user gained 12 rating in the last week
        lastMonth: number | null;
      };
    };
  };
};

export type DashboardBannerDetails = {
  id: string;
  text: string;
  ends: string | null;
  condition: string | null;
  onlyPremium: boolean;
  onlyFree: boolean;
  onlyAdmin: boolean;
};

export type DashboardBanner = {
  id: string;
  text: string;
};

export type GetBannerResponse = ApiResponse & {
  data: {
    banner?: DashboardBanner | DashboardBannerDetails;
  };
};

export type GetChatTriggersResponse = ApiResponse & {
  data: ChatTrigger[];
};

export type GeneralStringResponse = ApiResponse & {
  data: string;
};

export type GetDailyMatchupResponse = ApiResponse & {
  data: DailyMatchup;
};

export type GetDailyPositionResponse = ApiResponse & {
  data: DailyPosition | null;
};

export type GetNextOpponentResponse = ApiResponse & {
  data: NextOpponentSuggestion;
};
