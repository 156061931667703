<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <div id="draw-text" class="draw-text">Draw</div>
    <div
      id="draw-animation-sub-text"
      class="draw-animation-sub-text"
      style="white-space: nowrap"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, type PropType, ref } from "vue";
import { usePageStore } from "@/stores/pageStore";
import { GameTermination } from "@/types/apitypes";

const props = defineProps({
  termination: {
    type: Object as PropType<GameTermination>,
  },
});

const emit = defineEmits(["isRunning"]);

function getDrawText(termination: GameTermination | undefined) {
  switch (termination) {
    case GameTermination.Threefold:
      return "by repetition";
    case GameTermination.Stalemate:
      return "by stalemate";
    case GameTermination.InsufficientMaterial:
      return "by material";
    case GameTermination.FiftyMove:
      return "by 50 moves";
    default: // Should never happen since the only draw conditions are above for now (when draw by agreement comes it needs to be added here)
      return "";
  }
}

function start() {
  emit("isRunning", { isRunning: true });
  usePageStore().setPlaySound("draw");

  let innerTextElement = document.getElementById("draw-text");
  let subTextElement = document.getElementById("draw-animation-sub-text");

  innerTextElement!.classList.add("tracking-in-expand");
  subTextElement!.classList.add("swing-in-top-fwd");
  subTextElement!.innerText = getDrawText(props.termination);

  innerTextElement!.addEventListener("animationend", function () {
    innerTextElement!.classList.remove("tracking-in-expand");
    subTextElement!.classList.remove("swing-in-top-fwd");
    innerTextElement!.classList.add("tracking-out-contract");
    subTextElement!.classList.add("tracking-out-contract");
    emit("isRunning", { isRunning: false });
  });
}

onMounted(() => {
  start();
});
</script>

<style scoped>
@media (max-width: 359px) {
  .draw-text {
    font-size: 3rem;
  }
}

@media (max-width: 410px) {
  .draw-text {
    font-size: 6rem;
  }
}
</style>

<style>
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.tracking-out-contract {
  -webkit-animation: tracking-out-contract 0.7s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:36:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}
@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:37:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

#text-spot {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.draw-text {
  display: inline-block;
  font-size: 120px;
  color: #d4d4d4;
  letter-spacing: 10px;
  font-family: "Luckiest Guy";
  font-weight: 100;
  -webkit-text-stroke: 2px black;
}

.draw-animation-sub-text {
  display: inline-block;
  font-size: 2rem;
  color: #d4d4d4;
  font-family: "Luckiest Guy";
  font-weight: 100;
  -webkit-text-stroke: 1.5px black;
  position: absolute;
  margin-top: 7rem;
}

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
  animation: swing-in-top-fwd 1s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
</style>
