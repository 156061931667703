<template>
  <GameAnalysis :initialGameId="gameId" />
</template>

<script setup lang="ts">
import GameAnalysis from "@/components/games/GameAnalysis.vue";
import { useRoute } from "vue-router";
import { ref, watchEffect } from "vue";

const route = useRoute();
let gameId = ref(route.query.id as string);

watchEffect(() => {
  gameId.value = route.query.id as string;
});
</script>
