import { defineStore } from "pinia";
import type {
  ChallengeFromPosition,
  DailyMatchup,
  DailyPosition,
  PracticePosition,
  Puzzle,
  PuzzleSolveHistory,
} from "@/types/apitypes";
import * as api from "@/services/rest";

export const useGeneralStore = defineStore("general", {
  state: () => ({}),
  actions: {
    async getDailyPosition(
      type: "dailyendgame" | "dailymaster"
    ): Promise<DailyPosition | null> {
      return (await api.getDailyPosition(type)).data;
    },
    async getDailyMatchup(): Promise<DailyMatchup> {
      return (await api.getDailymatchup()).data;
    },
    async refreshActiveChallenges(): Promise<ChallengeFromPosition[]> {
      return (await api.getActiveChallenges()).data.challenges;
    },
    async getChallenge(challengeId: string): Promise<ChallengeFromPosition> {
      return (await api.getChallenge(challengeId)).data.challenge;
    },
    async getPractice(practiceId: string): Promise<PracticePosition> {
      return (await api.getPractice(practiceId)).data.practice;
    },
    async getPractices(): Promise<PracticePosition[]> {
      return (await api.getPractices()).data.practices;
    },
    async getPuzzles(): Promise<Puzzle[]> {
      return (await api.getPuzzles()).data.puzzles;
    },
    async getPuzzleSet(botId: string): Promise<Puzzle[]> {
      return (await api.getPuzzleSet(botId)).data.puzzles;
    },
    async getDashboardBanner(): Promise<{
      banner?: {
        id: string;
        text: string;
      };
    }> {
      return (await api.getDashboardBanner()).data;
    },
    async solvePuzzle(
      botId: string,
      puzzleId: string,
      solveHistory: PuzzleSolveHistory
    ): Promise<void> {
      await api.solvePuzzle(botId, puzzleId, solveHistory);
    },
  },
});
