<template>
  <div>
    <MosaicLeft />
    <MosaicRight v-if="!isMobileWidth()" />
    <div style="text-align: center" :class="getMainDivClass()">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdHjDJbNH0ufsmDJG7W8SOF0YowOtGdfOZMgRUbMUiIaBqZyQ/viewform?embedded=true"
        width="640"
        height="2000"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        >Loading…</iframe
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import MosaicLeft from "@/components/landing/MosaicLeft.vue";
import MosaicRight from "@/components/landing/MosaicRight.vue";
import { useModalsStore } from "@/stores/modalsStore";
import { isMobileWidth } from "@/util/util";
import { ref } from "vue";
import { track } from "@/util/tracking";

let ms = useModalsStore();
let loading = ref(true);

function getMainDivClass() {
  return isMobileWidth() ? "mx-auto w-100" : "mx-auto w-50";
}
</script>

<style scoped>
.roadmap-icon {
  height: 1rem;
  margin-right: 0.4rem;
  margin-top: -0.1rem;
}
</style>
