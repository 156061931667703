<template>
  <div class="landing-wrapper">
    <div class="main-hero-wrapper">
      <div class="main-hero-left">
        <div class="imgheader">
          <img src="@/assets/images/logo.svg" alt="Chessiverse logo" />
          <h1>
            Enjoy Stress-Free
            <span style="color: var(--clr-dark-accent)"
              >Chess Against Human-Like Bots</span
            >
          </h1>
        </div>
        <span
          >Play, practice, and puzzle with over 500 of the world’s most
          realistic chess bots — free of human distractions
        </span>
        <button
          type="button"
          class="btn btn-info btn-lg"
          @click="
            () => {
              ms.showLogin(LoginModalState.Signup);
              track('landing', 'play_now_button_upper', 'click');
            }
          "
        >
          Play now for Free!
        </button>
      </div>
      <div v-if="showYoutube === true" class="main-hero-right card">
        <iframe
          class="responsive-iframe"
          src="https://www.youtube.com/embed/vuqr3jT0R54?si=4qzGoZTW1SIFNbV8&amp;controls=0&rel=0&modestbranding=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div v-else-if="showYoutube === false" class="main-hero-right">
        <div class="card responsive-iframe" style="background: black">
          <iframe
            src="https://player.vimeo.com/video/962063026?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=0&loop=0&title=0&byline=0&portrait=0"
            frameborder="0"
            class="responsive-iframe"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="Discover Chessiverse: The Ultimate Chess Experience with Human-like Bots!"
          ></iframe>
        </div>
      </div>
      <div v-else class="main-hero-right">
        <div class="card responsive-iframe" style="background: black"></div>
      </div>
    </div>

    <UsagesList class="full-width-band" />

    <FeatureList />

    <div class="full-width-band">
      <LandingCarousel style="margin: auto" />
    </div>

    <div class="card hundreds">
      <h3>
        <span style="color: var(--clr-dark-accent)">Hundreds</span> of Unique
        Bots
      </h3>
      <p style="font-size: 1rem">
        Each with their own backgrounds and personalities, distinct play styles,
        favored openings, and unpredictable strategies.
      </p>
      <p>
        Play against
        <span style="color: var(--clr-accent)"
          >Sir Reginald Worth (2165 Elo)</span
        >
        a british upper-class gentleman,
        <span style="color: var(--clr-accent)">Pawnt Perry (600 Elo)</span> a
        sheep farmer from New Zealand, or
        <span style="color: var(--clr-accent)">Donna Vinchi (1027 Elo)</span> a
        painter from Spain. Or anything inbetween.
      </p>
      <img
        v-if="isMobileWidth()"
        class="w-100 mx-auto"
        src="https://storage.googleapis.com/chessiverse1/images/elements/many_bots_high_300.webp"
      />
      <img
        v-else
        class="w-100 mx-auto"
        src="https://storage.googleapis.com/chessiverse1/images/elements/many_bots_high_540.webp"
      />
      <span class="mx-auto">(and many many more...)</span>
    </div>

    <FAQ />

    <div class="full-width-band">
      <div class="sveinn-recommend">
        <div>
          <ProfileCard
            :botMock="{
              name: 'Sveinn Gudmundsson',
              countryCode: 'is',
              countryName: 'Iceland',
              rating: 869,
              botId: 'LMUwL',
              occupation: 'Fisherman',
            }"
            :display-occupation="true"
          />
        </div>
        <div class="card sveinn-signup">
          <span>
            <i
              >"Hoist yer sails and sign on for a chess voyage full of lore and
              strategy. Climb aboard!"</i
            ></span
          >
          <button
            type="button"
            class="btn btn-info me-2"
            @click="
              () => {
                track('landing', 'play_now_button_lower', 'click');
                ms.showLogin(LoginModalState.Signup);
              }
            "
          >
            Sign Up for Free
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import "vue3-carousel/dist/carousel.css";
import { LoginModalState } from "@/types/internaltypes";
import { useModalsStore } from "@/stores/modalsStore";
import ProfileCard from "@/components/common/ProfileCard.vue";
import FeatureList from "@/components/landing/FeatureList.vue";
import UsagesList from "@/components/landing/UsagesList.vue";
import { isMobileWidth } from "@/util/util";
import LandingCarousel from "@/components/landing/LandingCarousel.vue";
import FAQ from "@/components/landing/FAQ.vue";
import PostHog from "posthog-js";
import { ref } from "vue";
import { track } from "@/util/tracking";

const ms = useModalsStore();

let showYoutube = ref<boolean | null>(null);

isYoutube();

function isYoutube() {
  try {
    PostHog.onFeatureFlags(function () {
      if (PostHog.getFeatureFlag("youtube-vs-vimeo") === "vimeo") {
        showYoutube.value = false;
      } else {
        showYoutube.value = true;
      }
    });
  } catch (error) {
    console.error("Error in PostHog feature flags", error);
    showYoutube.value = true;
  }
}
</script>

<style scoped>
.landing-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
}

.main-hero-wrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.main-hero-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  max-width: 30rem;
}

.imgheader > img {
  border: 5px solid white;
  border-radius: 50%;
  width: 10rem;
}

.main-hero-left > span {
  font-size: 1.3rem;
}

.cta-top {
  width: 75%;
  text-wrap: balance;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-hero-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.responsive-iframe {
  width: 40rem;
  aspect-ratio: 16 / 9;
}

@media (max-width: 1400px) {
  .responsive-iframe {
    width: 30rem;
  }
}

@media (max-width: 1200px) {
  .responsive-iframe {
    width: 25rem;
  }
}

@media (max-width: 1040px) {
  .main-hero-wrapper {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: -2rem;
  }

  .main-hero-wrapper h1 {
    font-size: 1.5rem;
  }

  .main-hero-left {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .main-hero-left > span {
    font-size: 1rem;
  }

  .main-hero-left > button {
    margin: 0 auto 2rem auto;
  }

  .imgheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-wrap: balance;
    gap: 1rem;
  }

  .imgheader > img {
    border: 2px solid white;
    width: 7rem;
  }
  .main-hero-right {
    flex-direction: row;
  }

  .main-hero-right {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

h1,
h2 {
  font-size: 2.7rem;
}

.full-width-band {
  background-color: var(--clr-accent);
  width: 100vw;
}

.hundreds {
  padding: 3rem;
  text-align: center;
  max-width: 40rem;
  gap: 1rem;
  text-wrap: balance;
}

.sveinn-recommend {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.sveinn-signup {
  max-width: 15rem;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}
</style>
