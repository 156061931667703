<template>
  <div>
    <div style="text-align: center; margin-top: 2rem; color: white">
      <h2>
        <span style="color: #ddd">Thousands</span>
        of chess games played every day!
      </h2>
    </div>

    <div class="usages-list">
      <div>
        <span
          ><i class="fa-solid fa-quote-left quote-mark"></i> I love that the
          bots have distinct playing styles, which leads to unexpected games
          <i class="fa-solid fa-quote-right quote-mark"></i>
        </span>
        <br />
        <span class="quote-text">- geoff_batko</span>
      </div>
      <div>
        <span
          ><i class="fa-solid fa-quote-left quote-mark"></i> I can tell this is
          a work of love. Good job!
          <i class="fa-solid fa-quote-right quote-mark"></i>
        </span>
        <br />
        <span class="quote-text">- thenakesingularity10</span>
      </div>
      <div>
        <span
          ><i class="fa-solid fa-quote-left quote-mark"></i> Wow! What an
          amazing website :)
          <i class="fa-solid fa-quote-right quote-mark"></i>
        </span>
        <br />
        <span class="quote-text">- lehrerb42</span>
      </div>
      <div>
        <span
          ><i class="fa-solid fa-quote-left quote-mark"></i> The functionality
          is great and the bots are much more interesting than what we get on
          Chess.com.
          <i class="fa-solid fa-quote-right quote-mark"></i>
        </span>
        <br />
        <span class="quote-text">- Material_Front_8819</span>
      </div>
      <div>
        <span
          ><i class="fa-solid fa-quote-left quote-mark"></i> The features, user
          interface, and overall experience were top-notch. It's evident that a
          lot of thought and effort has been put into creating such a fantastic
          platform for chess enthusiasts like me.
          <i class="fa-solid fa-quote-right quote-mark"></i>
        </span>
        <br />
        <span class="quote-text">- Armaan Alam</span>
      </div>
      <div>
        <span
          ><i class="fa-solid fa-quote-left quote-mark"></i> My son is learning
          advanced French as white, and faced novel but seemingly logical moves
          from black (C4, F6) that you don’t get to see often in teaching
          material or online games, and the refutations are not trivial.
          <i class="fa-solid fa-quote-right quote-mark"></i>
        </span>
        <br />
        <span class="quote-text">- U</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.quote-mark {
  vertical-align: super;
  opacity: 0.8;
  color: white;
  padding-inline: 0.5rem;
}

.usages-list {
  display: grid;
  gap: 0.5rem;
  padding-block: 0.5rem;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: min(80%, 50rem);
  margin: 0 auto;
}

.usages-list > div {
  padding: 1rem;
  text-align: center;
}

.usages-list span {
  font-size: 1rem;
}

.quote-text {
  margin-top: 0.5rem;
  opacity: 0.5;
  font-size: 1rem !important;
}

@media (max-width: 768px) {
  .usages-list {
    grid-template-columns: 1fr;
  }
}
</style>
