<template>
  <div
    class="card"
    style="width: 100%; padding: 0rem 1rem 1rem 1rem; display: flex; gap: 1rem"
  >
    <h2 class="title" style="color: white !important">Stats</h2>
    <UserStatsRatingBox
      v-if="ratingInfo != null && ratingInfo.currentRating != null"
      :ratingInfo="ratingInfo"
    />
    <div :class="'card container' + (loading ? ' pulsate-load' : '')">
      <div v-if="loading" class="stat-list">
        <div
          class="stat"
          :title="stat.description"
          :data-bs-original-title="stat.description"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          v-for="(stat, index) in stats.slice(0, 3)"
          :key="'stat' + index"
        >
          <img
            :class="stat.imgClasses"
            :src="stat.image"
            :alt="stat.imageAlt"
          />
          <span class="stat-title">{{ stat.title }}</span>
          <span class="stat-value">-</span>
        </div>
      </div>
      <div v-else class="stat-list">
        <div
          class="stat"
          :title="stat.description"
          :data-bs-original-title="stat.description"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          v-for="(stat, index) in stats.slice(0, 3)"
          :key="'stat' + index"
        >
          <img
            :class="stat.imgClasses"
            :src="stat.image"
            :alt="stat.imageAlt"
          />
          <span class="stat-title">{{ stat.title }}</span>
          <span class="stat-value">{{ stat.value }}</span>
        </div>
      </div>
    </div>
    <div :class="'card container' + (loading ? ' pulsate-load' : '')">
      <div v-if="loading" class="stat-list">
        <div
          class="stat"
          :title="stat.description"
          :data-bs-original-title="stat.description"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          v-for="(stat, index) in stats.slice(3)"
          :key="'stat' + index"
        >
          <img
            :class="stat.imgClasses"
            :src="stat.image"
            :alt="stat.imageAlt"
          />
          <span class="stat-title">{{ stat.title }}</span>
          <span class="stat-value">-</span>
        </div>
      </div>
      <div v-else class="stat-list">
        <div
          class="stat"
          :title="stat.description"
          :data-bs-original-title="stat.description"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          v-for="(stat, index) in stats.slice(3)"
          :key="'stat' + index"
        >
          <img
            :class="stat.imgClasses"
            :src="stat.image"
            :alt="stat.imageAlt"
          />
          <span class="stat-title">{{ stat.title }}</span>
          <span class="stat-value">{{ stat.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { useModalsStore } from "@/stores/modalsStore";
import { Tooltip as BsTooltip } from "bootstrap";
import { ref, type Ref } from "vue";
import type { Rating } from "@/types/apitypes.js";
import UserStatsRatingBox from "@/components/dashboard/UserStatsRatingBox.vue";

const us = useUserStore();
const ms = useModalsStore();
let loading = ref(true);

let stats: Ref<
  {
    image: string;
    imageAlt: string;
    title: string;
    description: string;
    value?: number;
    imgClasses: string;
  }[]
> = ref([
  {
    image:
      "https://storage.googleapis.com/chessiverse1/images/elements/star.webp",
    imageAlt: "Golden star",
    title: "Golden stars",
    description: "Collect golden stars by beating different bots",
    imgClasses: "stat-image",
  },
  {
    image:
      "https://storage.googleapis.com/chessiverse1/images/elements/star.webp",
    imageAlt: "Blue star",
    title: "Blue stars",
    description: "Collect blue stars by finishing challenges",
    imgClasses: "stat-image blue",
  },
  {
    image:
      "https://storage.googleapis.com/chessiverse1/images/elements/star.webp",
    imageAlt: "Green star",
    title: "Green stars",
    description: "Collect green stars by finishing practice positions",
    imgClasses: "stat-image green",
  },
  {
    image:
      "https://storage.googleapis.com/chessiverse1/images/elements/crossed_swords2.webp",
    imageAlt: "Two crossed swords",
    title: "Total games",
    description: "Total games played",
    imgClasses: "stat-image",
  },
  {
    image:
      "https://storage.googleapis.com/chessiverse1/images/elements/sword.webp",
    imageAlt: "A sword",
    title: "Games today",
    description: "Games played today",
    imgClasses: "stat-image",
  },
  {
    image:
      "https://storage.googleapis.com/chessiverse1/images/elements/flame.webp",
    imageAlt: "A flame",
    title: "Win streak",
    description: "Current win streak",
    imgClasses: "stat-image",
  },
]);

let ratingInfo = ref<{
  currentRating: Rating | null;
  historyChange: {
    lastGame: number | null;
    lastDay: number | null;
    lastWeek: number | null;
    lastMonth: number | null;
  };
}>();

us.getQuickStats().then((q) => {
  ratingInfo.value = q.ratingInfo;

  stats.value[0].value = q.stars;
  stats.value[1].value = q.beatenChallenges + q.beatenDailyPositions;
  stats.value[2].value = q.practicePoints;
  stats.value[3].value = q.gamesAll;
  stats.value[4].value = q.gamesToday;
  stats.value[5].value = q.winStreak;
  loading.value = false;
});

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});
</script>

<style scoped>
.title {
  color: var(--clr-accent) !important;
}
.container {
  display: flex;
  justify-content: center;
  padding-block: 1rem;
}
.stat-list {
  display: grid;
  grid-template-columns: repeat(3, 4rem);
  justify-content: center;
}
.blue {
  filter: hue-rotate(180deg);
}
.green {
  filter: hue-rotate(45deg);
}
.stat {
  display: grid;
  grid-template-rows: 2rem 2.5rem 2rem;
  justify-items: center;
}

.stat-image {
  height: 1.5rem;
  aspect-ratio: 1;
}

.stat-title {
  font-size: 1rem;
  text-align: center;
  color: var(--clr-main);
  line-height: 1rem;
}

.stat-value {
  font-size: 2rem;
  color: var(--clr-accent);
  flex-grow: 1;
}
</style>
