<template>
  <p
    :class="[...pClasses, 'overflow', 'ph-no-capture']"
    :style="pStyle"
    @click="
      () => {
        stopTyping();
        track('type_writer', 'text', 'click');
      }
    "
    id="typewriterBox"
    v-html="currentText"
  ></p>
</template>

<script setup lang="ts">
import { ref, type Ref, type PropType, watch, nextTick } from "vue";
import { marked } from "marked";
import { track } from "@/util/tracking";

const props = defineProps({
  pClasses: { type: Array as PropType<string[]> },
  pStyle: { type: String },
  targetText: { type: String, required: true },
  newTextDelay: { type: Number, required: true },
  fadeawayMillis: { type: Number, default: -1 },
});

let currentText = ref("");

let typingSpeed = 15;
let finishTextNow = ref(false);

watch(finishTextNow, (newValue) => {
  if (!newValue) {
    return;
  }
  currentText.value = marked(props.targetText) as string;
  if (newValue && props.fadeawayMillis > 0) {
    fadeaway(props.fadeawayMillis, props.targetText);
  }
});

watch(
  () => props.targetText,
  () => {
    finishTextNow.value = false;
    currentText.value = "";
    startTyping();
  }
);

const fadeaway = (delay: number, textToFadeAway: String) => {
  setTimeout(function () {
    if (props.targetText != textToFadeAway) {
      // While this timeout was going, the text changed, so we shouldn't fade away
      return;
    }
    // start a delay
    var fade = document.getElementById("typewriterBox"); // get required element
    if (fade == null) {
      return;
    }

    fade.style.opacity = "1"; // set opacity for the element to 1
    var timerId = setInterval(function () {
      if (fade == null) {
        return;
      }
      // start interval loop
      var opacity = parseFloat(fade.style.opacity); // get current opacity
      if (opacity == 0.0) {
        // check if its 0 yet
        clearInterval(timerId); // if so, exit from interval loop
      } else {
        fade.style.opacity = (opacity - 0.05).toString(); // else remove 0.05 from opacity
      }
    }, 100); // run every 0.1 second
  }, delay); // wait to run after 5 seconds
};
const stopTyping = () => {
  finishTextNow.value = true;
  currentText.value = marked(props.targetText) as string;
};

const typeTextInit = (text: string, typeValue: Ref<String>) => {
  let charIndex = 0;
  let typeText = () => {
    if (props.targetText != text) {
      // Target text is something different so stop typing this old text
      return;
    }
    if (finishTextNow.value || text == null) {
      return;
    }
    if (charIndex < text.length) {
      if (text.charAt(charIndex) != "*" && text.charAt(charIndex) != "_") {
        typeValue.value += text.charAt(charIndex);
      }
      charIndex += 1;
      setTimeout(typeText, typingSpeed);
    } else {
      finishTextNow.value = true;
      return;
    }
  };
  setTimeout(typeText, typingSpeed);
};

const startTyping = () => {
  setTimeout(
    typeTextInit.bind(null, props.targetText, currentText),
    props.newTextDelay + 200
  );
};
startTyping();
</script>

<style lang="scss" scoped>
.overflow {
  overflow-wrap: anywhere;
}

.scaled-image {
  width: 20rem;
  height: 20rem;
}
// Cursor blinking CSS Starts...
.blinking-cursor {
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #2c3e50;
  }
}
// Cursor blinking CSS Ends...
</style>
