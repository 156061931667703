<template>
  <GameArena
    v-if="type == 'intro'"
    :type="type"
    :botId="botId"
    :autoStart="autoStart == 'true'"
  />
  <GameArena
    v-if="type == 'casual'"
    :type="type"
    :botId="botId"
    :autoStart="autoStart == 'true'"
  />
  <GameArena
    v-if="type == 'custom'"
    :type="type"
    :custom-challenge="customChallenge"
  />
  <GameArena
    v-if="type == 'challenge'"
    :type="type"
    :challengeId="challengeId"
    :difficulty="difficulty as Difficulty"
  />
  <GameArena
    v-if="type == 'practice'"
    :type="type"
    :practiceId="practiceId"
    :botId="botId"
  />
  <GameArena v-if="type == 'puzzle'" :type="type" :puzzleId="puzzleId" />
  <GameArena v-if="type == 'continue'" :type="type" :initialGameId="gameId" />
  <GameArena
    v-if="type == 'dailymatchup'"
    :type="type"
    :challengeId="challengeId"
  />
  <GameArena
    v-if="type == 'dailyendgame' || type == 'dailymaster'"
    :type="type"
    :challengeId="challengeId"
    :relative-difficulty="difficulty as RelativeDifficulty"
  />
</template>

<script setup lang="ts">
import GameArena from "@/components/games/GameArena.vue";
import { useRoute } from "vue-router";
import { ref } from "vue";
import type { ChallengeFromPosition } from "@/types/apitypes";
import type {
  Difficulty,
  PlayTypes,
  RelativeDifficulty,
} from "@/types/internaltypes";

const route = useRoute();

let type = ref(route.query.type as PlayTypes);
let challengeId = ref(route.query.challengeId as string);
let practiceId = ref(route.query.practiceId as string);
let puzzleId = ref(route.query.puzzleId as string);
let gameId = ref(route.query.id as string);
let botId = ref(route.query.bid as string);
let side = ref(route.query.side as string);
let fen = ref(route.query.fen as string);
let autoStart = ref(route.query.auto as string);

let difficulty = ref(route.query.difficulty as string);

let customChallenge = ref<ChallengeFromPosition>({
  id: "custom",
  type: "from_position",
  customBot: { botId: botId.value },
  start_position: fen.value,
  user_side: side.value,
});
</script>
