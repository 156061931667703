<template>
  <div>
    <div v-if="loadingGame" class="text-center">
      <Loader />
    </div>
    <div v-else class="container">
      <div
        class="left"
        :style="{
          justifyContent:
            challengeState != ChallengeState.StartingCustomChallenge &&
            challengeState != ChallengeState.StartingPractice &&
            challengeState != ChallengeState.StartingIntro
              ? 'end'
              : 'center',
        }"
      >
        <div
          ref="boardcontainer"
          class="boardcontainer expose-item-left"
          :style="
            timeControl != null && !isMobileWidth()
              ? { width: 'min(800px, 76vh)', height: 'min(800px, 76vh)' }
              : {}
          "
        >
          <div
            style="
              height: 100%;
              width: 100%;
              display: flex;
              gap: 0.2rem;
              flex-direction: column;
            "
          >
            <div style="display: flex; align-items: end">
              <MaterialView
                :fen="currentPosition"
                :side="boardConfig.orientation == 'white' ? 'white' : 'black'"
              />
              <div style="flex-grow: 1"></div>
              <TimeBox
                v-if="
                  timeControl != null && currentTimes != null && gameId != null
                "
                :bg="botBg"
                :side="boardConfig.orientation == 'white' ? 'black' : 'white'"
                :currentTimes="currentTimes"
                :active="sideToMove() == 'bot'"
                :isUser="false"
                :gameId="gameId"
                :timeControl="timeControl"
                :sideLostOnTime="
                  gameTermination == GameTermination.Time
                    ? gameResult == Result.White
                      ? 'black'
                      : 'white'
                    : undefined
                "
              />
            </div>
            <TheChessboard
              :board-config="boardConfig"
              reactive-config
              :player-color="playerColor as MoveableColor"
              @move="moveMade"
              @board-created="boardCreated"
              @click="
                () => {
                  resetIfHistory();
                }
              "
              style="font-size: 2rem"
            />
            <div style="display: flex">
              <MaterialView
                :fen="currentPosition"
                :side="boardConfig.orientation == 'white' ? 'black' : 'white'"
              />
              <div style="flex-grow: 1"></div>
              <TimeBox
                v-if="
                  timeControl != null && currentTimes != null && gameId != null
                "
                :bg="botBg"
                :pendingMove="pendingMove"
                :side="boardConfig.orientation == 'white' ? 'white' : 'black'"
                :isUser="true"
                :currentTimes="currentTimes"
                :timeControl="timeControl"
                :lastMoveTimeSpent="lastMoveTimeSpent"
                :active="sideToMove() == 'user'"
                :gameId="gameId"
                :sideLostOnTime="
                  gameTermination == GameTermination.Time
                    ? gameResult == Result.White
                      ? 'black'
                      : 'white'
                    : undefined
                "
              />
            </div>
          </div>
          <i
            v-if="type == 'puzzle'"
            :class="
              'fa-solid fa-circle side-indicator ' +
              (boardConfig.orientation == 'white'
                ? 'side-indicator-white'
                : 'side-indicator-black')
            "
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="
              'You\'re playing ' +
              (boardConfig.orientation == 'white' ? 'white' : 'black')
            "
            :data-bs-original-title="
              'You\'re playing ' +
              (boardConfig.orientation == 'white' ? 'white' : 'black')
            "
          />

          <StartGameBox
            v-if="
              viewingHistoryPly == null &&
              (challengeState == ChallengeState.Aborted ||
                challengeState == ChallengeState.FinishedCasualWin ||
                challengeState == ChallengeState.FinishedCasualNotWin ||
                challengeState == ChallengeState.FinishedRatedWin ||
                challengeState == ChallengeState.FinishedRatedNotWin ||
                challengeState == ChallengeState.StartingIntro ||
                challengeState == ChallengeState.StartingCasual ||
                challengeState == ChallengeState.StartingChallenge ||
                challengeState == ChallengeState.StartingCustomChallenge ||
                challengeState == ChallengeState.StartingPractice ||
                challengeState == ChallengeState.StartingPuzzleSet ||
                challengeState == ChallengeState.ContinuingPuzzleSet ||
                challengeState == ChallengeState.StartingDailyMatchup ||
                challengeState == ChallengeState.StartingDailyPosition)
            "
            :boardAnimationRunning="boardAnimationRunning"
            :bot="bot"
            :challengeState="challengeState"
            :gameId="gameId"
            @user-input="userInput"
            @set-board-bg="setBoardBg"
          />

          <StartGameAnimation
            v-if="
              challengeState == ChallengeState.FirstMoveCasual ||
              challengeState == ChallengeState.FirstMoveRated ||
              challengeState == ChallengeState.PlayingPuzzle
            "
            style="z-index: 100; pointer-events: none"
            :color="botBg"
          />
          <WinPuzzleAnimation
            v-if="endOfPuzzleAnimation == 'win'"
            style="z-index: 100"
          />
          <WinPuzzleWithHintAnimation
            v-if="endOfPuzzleAnimation == 'win_with_hint'"
            style="z-index: 100"
          />
          <LosePuzzleAnimation
            v-if="endOfPuzzleAnimation == 'lose'"
            style="z-index: 100"
          />

          <WinThenPointsGainedAnimation
            :points="improvedPracticePoints"
            v-if="playerWon != null && playerWon && improvedPracticePoints > 0"
            style="z-index: 100"
            :termination="gameTermination ?? undefined"
          />
          <WinThenStarAnimation
            :blue="
              challengeId != null ||
              thisDailyMatchup != null ||
              thisDailyPosition != null
            "
            v-if="playerWon != null && playerWon && firstWin"
            style="z-index: 100"
            :termination="gameTermination ?? undefined"
            @isRunning="boardAnimationState"
          />

          <WinAnimation
            v-if="
              playerWon != null &&
              playerWon &&
              !firstWin &&
              improvedPracticePoints <= 0
            "
            :termination="gameTermination ?? undefined"
            style="z-index: 100"
            @isRunning="boardAnimationState"
          />
          <LoseAnimation
            v-if="playerWon != null && !playerWon && gameResult != Result.Draw"
            :termination="gameTermination ?? undefined"
            style="z-index: 100"
            @isRunning="boardAnimationState"
          />
          <DrawAnimation
            v-if="gameResult == Result.Draw"
            style="z-index: 100"
            :termination="gameTermination ?? undefined"
            @isRunning="boardAnimationState"
          />
        </div>
      </div>
      <div
        class="right expose-item-right"
        v-if="
          challengeState != ChallengeState.StartingCustomChallenge &&
          challengeState != ChallengeState.StartingPractice &&
          challengeState != ChallengeState.StartingIntro
        "
      >
        <a
          type="button"
          class="btn btn-danger bounce-top ph-no-capture"
          v-if="failedEngineMove"
          @click="
            () => {
              reloadPage();
              track('game_arena', 'reload_page_after_error_button', 'click');
            }
          "
        >
          <img
            src="@/assets/images/danger.svg"
            alt="Red exclamation point in circle"
            class="error-icon"
          />Retry
        </a>

        <MoveNavigation
          v-if="currentViewingPuzzle != null && !isFullWidth()"
          :opponent-color="botBg"
          :moves="currentViewingPuzzle!.moves.split(' ').length"
          :viewing-history-ply="viewingHistoryPly"
          @user-input="userInput"
        />
        <OpponentBox
          v-if="
            challengeState as ChallengeState  !== ChallengeState.StartingCustomChallenge &&
            challengeState as ChallengeState !== ChallengeState.StartingPractice &&
            challengeState as ChallengeState !== ChallengeState.StartingIntro
          "
          class="opponent-box"
          :thinking="awaitingBotMove"
          :opponentId="bot.id"
          :opponentName="bot.name"
          :opponentRating="bot.strength.estimated_elo"
          :opponentImage="ps.img(bot.id, ImageType.BotProfile, '80')"
          :opponentCountryCode="bot.country.code"
          :opponentCountryName="bot.country.name"
          :opponentColor="botBg"
          :chatHistory="chatHistory"
          :loadingChat="loadingChat"
          :challengeState="challengeState"
          :puzzleHintAvailable="
            currentPuzzleHints >= 1 && showPuzzleHintIfAvailable
          "
          :gameId="type == 'puzzle' ? puzzleId : gameId"
          @user-input="userInput"
        />
        <RatedBox
          v-if="showRatedBox"
          style="margin-top: 2rem"
          :result="getResultFromUserPerspective()"
          :opponentColor="botBg"
          :challengeState="challengeState"
          :ratingBot="bot.strength.estimated_elo"
          :ratingInfo="ratingInfo"
        ></RatedBox>

        <MovesBox
          v-if="
            type != 'puzzle' &&
            challengeState as ChallengeState !== ChallengeState.StartingCustomChallenge &&
            challengeState as ChallengeState !== ChallengeState.StartingPractice &&
            challengeState as ChallengeState !== ChallengeState.StartingIntro
          "
          style="margin-top: 2rem"
          :moves="moves"
          :startPosition="thisChallenge?.challenge == null ? null : thisChallenge!.challenge.start_position"
          :movesToStartPosition="thisChallenge?.challenge == null ? null : thisChallenge!.challenge.initial_moves"
          :result="gameResultString"
          :viewingHistoryPly="viewingHistoryPly"
          :opponentColor="botBg"
          :challengeState="challengeState"
          :termination="gameTermination ?? undefined"
          :gameId="gameId"
          @user-input="userInput"
        />

        <PuzzleSetBox
          v-if="type == 'puzzle' && currentPuzzleSet != null"
          style="margin-top: 2rem"
          :puzzles="currentPuzzleSet!"
          :opponentColor="botBg"
          :hints="currentPuzzleHints"
          :currentPuzzleIndex="currentPuzzleIndex"
          :challengeState="challengeState"
          :currentViewingPuzzle="currentViewingPuzzle"
          :viewingHistoryPly="viewingHistoryPly"
          @user-input="userInput"
        />
        <pick-colors
          v-if="debug == 'cp'"
          v-model:value="boardBaseColor"
          width="200"
          height="200"
        />
        <div
          style="
            display: flex;
            gap: 1rem;
            justify-content: center;
            margin-top: 2rem;
          "
        >
          <SoundsToggle style="margin-top: -0.2rem" soundType="master" />
          <Toggle
            v-model="forceFlipBord"
            class="toggle-force-flip-board ph-no-capture"
            @click="
              () => {
                track('game_arena', 'force_flip_board', 'click');
              }
            "
          >
            <template v-slot:label="{ checked, classList }">
              <span v-if="checked" :class="classList.label"
                ><i class="fa-solid fa-repeat"></i
              ></span>
              <span v-else :class="classList.label"
                ><i class="fa-solid fa-repeat"></i
              ></span>
            </template>
          </Toggle>
        </div>
      </div>
    </div>
    <div style="text-align: right">
      <a
        v-if="isFullWidth()"
        @click="
          () => {
            exposeEffect();
            track('game_arena', 'expose', 'click', {
              reverse: reverseExpose,
            });
          }
        "
        class="ph-no-capture"
        title="Expose background"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        style="cursor: pointer"
      >
        <img
          src="@/assets/images/expose_white.svg"
          alt="Dotted box next to box"
      /></a>
    </div>
    <SoundsStorage />
  </div>
</template>

<script setup lang="ts">
import Loader from "@/components/util/Loader.vue";
import WinAnimation from "@/components/animations/WinAnimation.vue";
import WinPuzzleAnimation from "@/components/animations/WinPuzzleAnimation.vue";
import LosePuzzleAnimation from "@/components/animations/LosePuzzleAnimation.vue";
import WinPuzzleWithHintAnimation from "@/components/animations/WinPuzzleWithHintAnimation.vue";
import LoseAnimation from "@/components/animations/LoseAnimation.vue";
import DrawAnimation from "@/components/animations/DrawAnimation.vue";
import WinThenStarAnimation from "@/components/animations/WinThenStarAnimation.vue";
import WinThenPointsGainedAnimation from "@/components/animations/WinThenPointsGainedAnimation.vue";
import PickColors from "vue-pick-colors";
import OpponentBox from "@/components/games/OpponentBox.vue";
import MovesBox from "@/components/games/MovesBox.vue";
import {
  type BoardApi,
  type BoardConfig,
  type MoveableColor,
  TheChessboard,
} from "vue3-chessboard";
import "vue3-chessboard/style.css";
import { useGameStore } from "@/stores/gameStore";
import { useUserStore } from "@/stores/userStore";
import { useModalsStore } from "@/stores/modalsStore";
import { useBotsStore } from "@/stores/botStore";
import { usePageStore } from "@/stores/pageStore";
import { useGeneralStore } from "@/stores/generalStore";
import { useBackgroundStore } from "@/stores/backgroundStore.js";
// @ts-ignore
import { Chess, type Move, SQUARES } from "chess.js";
import {
  nextTick,
  onMounted,
  onUnmounted,
  type PropType,
  reactive,
  type Ref,
  ref,
  watch,
} from "vue";
import { Tooltip as BsTooltip } from "bootstrap";
import {
  type Bot,
  type ChallengeFromPosition,
  type ChatTriggerId,
  type DailyMatchup,
  type DailyPosition,
  type Game,
  GameTermination,
  type GetBotProfileResponse,
  type Puzzle,
  type PuzzleSolveHistory,
  type Rating,
  Result,
  Side,
  type TimeControl,
} from "@/types/apitypes";
import {
  ChallengeState,
  type Difficulty,
  ImageType,
  type PlayTypes,
  type RelativeDifficulty,
  UserInput,
} from "@/types/internaltypes";
import { useRoute, useRouter } from "vue-router";
import anime from "animejs/lib/anime.es.js";
import {
  isFullWidth,
  isMobileWidth,
  timeLeftPerSide,
  uciMoveToMove,
} from "@/util/util";
import StartGameAnimation from "../animations/StartGameAnimation.vue";
import { useToast } from "vue-toast-notification";
import { calculateRemainingHintsRaw, getResultText } from "@/util/puzzles";
import RatedBox from "@/components/games/RatedBox.vue";
import PuzzleSetBox from "@/components/games/PuzzleSetBox.vue";
import MoveNavigation from "@/components/games/MoveNavigation.vue";
import MaterialView from "@/components/games/MaterialView.vue";
import { getChatTriggerIdFromGame } from "@/util/chats";
import { track } from "@/util/tracking";
import TimeBox from "@/components/games/TimeBox.vue";
import StartGameBox from "@/components/games/startgame/StartGameBox.vue";
import type * as cg from "chessground/types";
import { makeChessgroundMove } from "@/util/chessgroundWorkaround";
import SoundsStorage from "@/components/sounds/SoundsStorage.vue";
import SoundsToggle from "@/components/sounds/SoundsToggle.vue";
import Toggle from "@vueform/toggle";

const route = useRoute();
const router = useRouter();
const gameStore = useGameStore();
const bs = useBotsStore();
const ps = usePageStore();
const generalStore = useGeneralStore();
const backgroundStore = useBackgroundStore();
const ms = useModalsStore();
const us = useUserStore();

const props = defineProps({
  type: {
    type: String as PropType<PlayTypes>,
  },
  initialGameId: { type: String },
  botId: { type: String },
  challengeId: {
    type: String,
  },
  practiceId: {
    type: String,
  },
  puzzleId: {
    type: String,
  },
  autoStart: {
    type: Boolean,
    default: false,
  },
  difficulty: { type: String as PropType<Difficulty> },
  relativeDifficulty: { type: String as PropType<RelativeDifficulty> },
  customChallenge: {
    type: Object as PropType<ChallengeFromPosition>,
  },
});

new BsTooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

// *********************************************************************************************************************
// Reactive variables
// *********************************************************************************************************************

let customChallengeSelectedBotId = ref<string | null>(null);
let loadingBotForChallenge = ref(false);
let loadingGame = ref(true);
let viewingHistoryPly: Ref<number | null> = ref(null);
let moves: Ref<string[]> = ref([]);
let gameId = ref(
  gameStore.active != null ? gameStore.active.id : props.initialGameId
);
let debug = ref(route.query.debug as string);
let boardBaseColor = ref("#ff4500");
let boardAPI: Ref<BoardApi | null> = ref(null);
let currentPosition = ref<string>(
  "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"
);
let chatHistory = ref<string[]>([]);
let loadingChat = ref(false);
let challengeState = ref<ChallengeState>();
let bot: Ref<Bot>;
let gameResultString: Ref<string | null> = ref(null);
let gameResult: Ref<Result | null> = ref(null);
let gameTermination = ref<GameTermination | null>(null);
let pendingMove = ref(false); // Will be true if the user has made a local move that hasn't been confirmed by the server yet
let currentTimes = ref<{ white: number; black: number } | null>(null);
let lastMoveTimeSpent = ref<number>(0); // Used to signal how much time the user spent on their current move
let timeControl = ref<TimeControl | null>(null);
let playerWon: Ref<boolean | null> = ref(null);
let firstWin: Ref<boolean | null> = ref(null);
let improvedPracticePoints = ref<number>(0);
let botBg = ref("#3cbfe0");
let chessBoardBg = ref("#3cbfe0");
let reverseExpose = false; // Keeps track of if the expose button should animate in or out
let thisChallenge = ref<{
  challenge: ChallengeFromPosition;
  difficulty: string;
} | null>(null);
let thisDailyMatchup = ref<DailyMatchup | null>(null);
let thisDailyPosition = ref<DailyPosition | null>(null);

let playerColor: MoveableColor | undefined;
let boardConfig = reactive({
  fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  blockTouchScroll: true,
  orientation: "white",
  coordinates: usePageStore().showBoardCoordinates,
  viewOnly: true,
  premovable: {
    enabled: true,
    events: {
      set: (orig: cg.Key, dest: cg.Key, metadata?: cg.SetPremoveMetadata) => {
        pendingPremove = {
          from: orig,
          to: dest,
          promotion: "q", // TODO Premoves always picks queen (and we send it with every move which doesn't hurt, and allows us to not have to check if it's a pawn moving to the last rank, it will be picked up by chess.js if it is)
        };
      },
      unset: () => {
        pendingPremove = null;
      },
    },
  },
  predroppable: {
    enabled: true,
  },
} as BoardConfig);
let pendingPremove: {
  from: string;
  to: string;
  promotion?: string;
} | null = null;

let failedEngineMove = ref(false);
let awaitingBotMove = ref(false);
let forceFlipBord = ref(false);

let currentPuzzleSet = ref<Puzzle[]>();
let currentPuzzleIndex = ref(-1);
let currentPuzzle = ref<Puzzle>();
let currentPuzzleMoveIndex = ref<number>(0);
let currentPuzzleSolveHistory = ref<PuzzleSolveHistory>();
let boardcontainer = ref();
let currentPuzzleHints = ref<number>(-1);
let showPuzzleHintIfAvailable = ref<boolean>(true);
let currentViewingPuzzle = ref<Puzzle | null>(null);

let endOfPuzzleAnimation = ref<"win" | "win_with_hint" | "lose" | null>(null);
let boardAnimationRunning = ref<boolean>(false);

let showRatedBox = ref<boolean>(false); // It's almost possible to use challengeState, but the HandlingInput state breaks it, and rather than working around that I think it's better to be very clear if the rated box should show or not§
let ratingInfo = ref<{
  // If rated, rating change after the game, and also potential rating change before game is finished
  potential: {
    win: number;
    draw: number;
    loss: number;
  };
  old: Rating;
  new?: Rating;
}>({
  potential: {
    win: 0,
    draw: 0,
    loss: 0,
  },
  old: {
    rating: 0,
    ratingDeviation: 0,
    volatility: 0,
  },
});

// *********************************************************************************************************************
// Set up the game depending on type
// *********************************************************************************************************************

// We need to start with getting the active game if any (this use to be cached and retrieved earlier, but now we need to actively get i)
gameStore.refreshActiveGame().then((game) => {
  gameId.value = game != null ? game.id : props.initialGameId;

  if (props.type === undefined) {
    console.error("Missing type");
    returnToHome();
  } else if (
    props.type === "continue" ||
    (props.type !== "puzzle" && gameId.value !== undefined)
  ) {
    // Type is continue, or there's already an ongoing game (except if it's a puzzle, since that doesn't start a new game in the backend)

    if (gameId.value === undefined) {
      console.error("Missing gameId");
      returnToHome();
    } else {
      if (
        props.type !== "continue" ||
        (props.type === "continue" &&
          gameStore.active != null &&
          props.initialGameId !== gameStore.active.id)
      ) {
        // Usually the toast doesn't work in the setup context, but since we're in a promise here it works,
        // If we ever remove the refreshActiveGame call above, we need to use a flag for this and call the toast the
        // the onMount method instead (now we can't use a flag, since onMount gets called before the resule of this promise)
        useToast().success("There was an ongoing game, continuing that");
      }

      initContinue(gameId.value);
    }
  } else if (props.type === "intro") {
    if (props.botId === undefined) {
      challengeState.value = ChallengeState.StartingIntro;
      loadingGame.value = false;
    } else {
      initCasual(props.botId);
    }
  } else if (props.type === "casual") {
    if (props.botId === undefined) {
      console.error("Missing botId");
      returnToHome();
    } else {
      initCasual(props.botId);
    }
  } else if (props.type === "puzzle") {
    if (props.puzzleId === undefined) {
      console.error("Missing puzzleId");
      returnToHome();
    } else {
      initPuzzleSet(props.puzzleId);
    }
  } else if (props.type === "dailyendgame" || props.type === "dailymaster") {
    if (props.relativeDifficulty === undefined) {
      console.error("Missing relativeDifficulty");
      returnToHome();
    } else {
      initDailyPosition(props.type, props.relativeDifficulty);
    }
  } else if (props.type === "dailymatchup") {
    initDailyMatchup();
  } else if (props.type === "challenge") {
    if (props.challengeId === undefined || props.difficulty === undefined) {
      console.error("Missing challengeId or difficulty");
      returnToHome();
    } else {
      initChallenge(props.challengeId, props.difficulty);
    }
  } else if (props.type === "practice") {
    if (props.practiceId === undefined) {
      console.error("Missing challengeId");
      returnToHome();
    } else {
      initPractice(props.practiceId, props.botId);
    }
  } else if (props.type === "custom") {
    if (props.customChallenge === undefined) {
      console.error("Missing customChallenge");
      returnToHome();
    } else {
      initCustomChallenge(props.customChallenge);
    }
  } else {
    // Shouldn't happen since we have a check above too, but just to be on the safe side let's bail out if it's not of the above
    router.push({
      name: "home",
    });
  }
});
// *********************************************************************************************************************
// Watches and hooks
// *********************************************************************************************************************

onMounted(() => {
  updateChessboardClasses();
  window.addEventListener("keydown", function (e) {
    if (e.key === "f") {
      e.preventDefault();
      boardConfig.orientation =
        boardConfig.orientation === "white" ? "black" : "white";
    }
  });
});

const boardCreated = (api: BoardApi) => {
  boardAPI.value = api;

  if (props.type == "puzzle") {
    // Now that the board is available, make the initial move in the puzzle
    if (currentPuzzle.value != null) {
      // If the puzzle is null here we probably ran out of puzzles for this set so shouldn't make a move
      makeEnginePuzzleMove();
    }
  } else {
    updateBoard();
  }
};

watch(
  () => forceFlipBord.value,
  () => {
    if (boardAPI.value != null) {
      if (forceFlipBord.value) {
        boardConfig.orientation = playerColor === "white" ? "black" : "white";
      } else {
        boardConfig.orientation = playerColor === "white" ? "white" : "black";
      }
    }
  }
);

watch(boardBaseColor, (color) => {
  let boardElement: Element = document.getElementsByTagName("cg-board")[0];
  if (boardElement instanceof HTMLElement) {
    boardElement.style.backgroundColor = color;
  }
});

watch(
  () => {
    if (gameId.value == null) {
      return null;
    } else {
      return gameStore.games[gameId.value];
    }
  },
  (game: Game | null) => {
    if (game == null || game.result == null) {
      return;
    }
    playerColor =
      gameStore.side(gameId.value!) == Side.White ? "white" : "black";
    boardConfig.orientation =
      gameStore.side(gameId.value!) == Side.White ? "white" : "black";

    setEndOfGameState(game);

    gameStore.games[gameId.value!].userSide == Side.White
      ? (playerWon.value = game.result == Result.White)
      : (playerWon.value = game.result == Result.Black);

    if (
      thisChallenge.value == null &&
      thisDailyMatchup.value == null &&
      thisDailyPosition.value == null
    ) {
      bs.getUserBotProfile(game.bot.id).then(
        (response: GetBotProfileResponse) => {
          // We're checking the number of wins after the current game is over, so if the number is 1, it means this is the first win
          // should not be possible to be 0 here
          if (playerWon.value && response.data.gameStats.wins <= 1) {
            firstWin.value = true;
            ps.wonAgainstBotIdForTheFirstTime = game.bot.id;
          } else {
            firstWin.value = false;
          }
        }
      );
    } else if (thisDailyPosition.value != null) {
      let difficulty = gameStore.games[gameId.value!].challenge?.difficulty as
        | "simple"
        | "easy"
        | "balanced"
        | "hard"
        | "intense"
        | undefined;
      if (difficulty != null) {
        if (
          playerWon.value &&
          !thisDailyPosition.value.difficulties[difficulty].userWon
        ) {
          firstWin.value = true;
        }
      }
    } else if (thisChallenge.value != null) {
      if (thisChallenge.value.difficulty === "practice") {
        const currentChallenge = thisChallenge.value; // Storing in a local variable so the async method can assume it's not null (and doesn't change while the call is running)
        generalStore.getPractice(currentChallenge.challenge.id).then((p) => {
          if (p.user_achieved) {
            improvedPracticePoints.value =
              p.user_points! -
              (currentChallenge.challenge.customBot?.previousBestBeatenBot
                ?.gainedPoints ?? 0);
          }
        });
      } else if (thisChallenge.value.difficulty !== "custom") {
        if (playerWon.value) {
          switch (thisChallenge.value.difficulty) {
            case "beginner":
              firstWin.value =
                !thisChallenge.value.challenge.difficulties!.beginner.userWon;
              break;
            case "novice":
              firstWin.value =
                !thisChallenge.value.challenge.difficulties!.novice.userWon;
              break;
            case "intermediate":
              firstWin.value =
                !thisChallenge.value.challenge.difficulties!.intermediate
                  .userWon;
              break;
            case "skilled":
              firstWin.value =
                !thisChallenge.value.challenge.difficulties!.skilled.userWon;
              break;
            case "advanced":
              firstWin.value =
                !thisChallenge.value.challenge.difficulties!.advanced.userWon;
              break;
            default: // This includes "custom", won't ever be a first win there
              firstWin.value = false;
          }
        } else {
          firstWin.value = false;
        }
      }
    }
  }
);

// *********************************************************************************************************************
// Functions
// *********************************************************************************************************************

function updateChessboardClasses() {
  // Bit of a convoluted way to replace the vue3-chessboard class "main-wrap" with our own "chessboard-wrap" so we can
  // control it better
  const observer = new MutationObserver((mutations, obs) => {
    const mainWrap = document.getElementsByClassName("main-wrap");
    if (mainWrap.length > 0) {
      mainWrap[0].classList.add("chessboard-wrap");
      mainWrap[0].classList.remove("main-wrap");
    }

    const cgBoard = document.getElementsByTagName("cg-board");
    for (const el of cgBoard) {
      el.classList.add("ph-no-capture");
    }
  });

  // Start observing
  observer.observe(document.body, {
    childList: true, // observe direct children
    subtree: true, // and lower descendants too
  });

  // Optional: Disconnect observer when component unmounts to clean up
  onUnmounted(() => observer.disconnect());
}

function returnToHome() {
  router.push({
    name: "home",
  });
}

function initPractice(
  practiceId: string,
  preselectedBotId: string | undefined = undefined
) {
  generalStore.getPractice(practiceId).then((p) => {
    boardConfig.fen = p.start_position;
    boardConfig.orientation = p.side == "white" ? "white" : "black";
    thisChallenge.value = {
      challenge: {
        id: p.id,
        type: "from_position",
        start_position: p.start_position,
        user_side: p.side,
        customBot: {
          botId: "",
        },
      },
      difficulty: "practice",
    };

    if (p.user_achieved) {
      thisChallenge.value.challenge.customBot!.previousBestBeatenBot = {
        id: p.user_botId!,
        rating: p.user_botRatingAtWin!,
        gainedPoints: p.user_points!,
      };
    }
    challengeState.value = ChallengeState.StartingPractice;

    if (preselectedBotId !== undefined) {
      userInput({ type: UserInput.StartChallenge, data: preselectedBotId });
    } else {
      customChallengeSelectedBotId.value = localStorage.getItem(
        "customChallengeSelectedBotId"
      );
      loadingGame.value = false;
    }
  });
}

function initCustomChallenge(challenge: ChallengeFromPosition) {
  boardConfig.fen = challenge.start_position;
  boardConfig.orientation = challenge.user_side == "white" ? "white" : "black";
  thisChallenge.value = {
    challenge: challenge,
    difficulty: "custom",
  };

  challengeState.value = ChallengeState.StartingCustomChallenge;
  if (challenge.customBot?.botId != null) {
    userInput({
      type: UserInput.StartChallenge,
      data: challenge.customBot!.botId,
    });
  } else {
    customChallengeSelectedBotId.value = localStorage.getItem(
      "customChallengeSelectedBotId"
    );
    loadingGame.value = false;
  }
}

function initChallenge(challengeId: string, difficulty: Difficulty) {
  generalStore.getChallenge(challengeId).then((c) => {
    boardConfig.fen = c.start_position;
    boardConfig.orientation = c.user_side == "white" ? "white" : "black";
    thisChallenge.value = {
      challenge: c,
      difficulty: difficulty,
    };

    challengeState.value = ChallengeState.StartingChallenge;
    setupBotForChallenge(
      c.difficulties![difficulty].botId,
      "start_of_challenge"
    );
  });
}

function initDailyPosition(
  type: "dailyendgame" | "dailymaster",
  difficulty: RelativeDifficulty
) {
  generalStore.getDailyPosition(type).then((c) => {
    if (c == null) {
      console.error("Missing daily position");
      returnToHome();
      return;
    }
    boardConfig.fen = c.position;
    boardConfig.orientation = c.userSide == Side.White ? "white" : "black";
    challengeState.value = ChallengeState.StartingDailyPosition;

    thisDailyPosition.value = c;

    setupBotForChallenge(
      c.difficulties[difficulty].botId,
      "start_of_challenge"
    );
  });
}

function initDailyMatchup() {
  generalStore.getDailyMatchup().then((c) => {
    boardConfig.orientation = c.userSide == Side.White ? "white" : "black";
    thisDailyMatchup.value = c;

    setupBotForChallenge(c.botId, "daily_matchup_ongoing").then(() => {
      challengeState.value = ChallengeState.StartingDailyMatchup;
      loadingGame.value = false;
    });
  });
}

function initPuzzleSet(botId: string) {
  generalStore.getPuzzleSet(botId).then((c) => {
    bs.getUserBotProfile(botId).then((response) => {
      bot = ref(response.data.bot);

      challengeState.value = c.some((p) => p.user_result == null)
        ? ChallengeState.StartingPuzzleSet
        : ChallengeState.ContinuingPuzzleSet;

      setBoardBg(bot.value.config.boardbg);
      backgroundStore.background = ps.img(
        bot.value.id,
        ImageType.BotBackground,
        null
      );

      currentPuzzleSet.value = c;

      let nextPuzzleIndex = currentPuzzleSet.value!.findIndex(
        (p) => p.user_result == null
      );
      updateRemainingHints([
        ...currentPuzzleSet
          .value!.filter((p) => p.user_result != null)
          .map((p) => p.user_result as PuzzleSolveHistory),
        currentPuzzleSolveHistory.value!,
      ]);

      loadingChat.value = true;
      if (nextPuzzleIndex == -1) {
        challengeState.value = ChallengeState.FinishedPuzzleSet;
        bs.getChat(bot.value.id, "finished_puzzleset", {
          results: getResultText(currentPuzzleSet.value!),
        })
          .then((r) => {
            chatHistory.value.push(r);
            loadingGame.value = false;
            loadingChat.value = false;
          })
          .catch(() => {
            // Something went wrong with the retrieval of starting chat, so just set a default
            chatHistory.value.push(
              "You finished all my puzzles, try some other!"
            );
            loadingGame.value = false;
            loadingChat.value = false;
          });
      } else {
        bs.getChat(
          bot.value.id,
          nextPuzzleIndex === 0 ? "start_of_puzzleset" : "continuing_puzzleset"
        )
          .then((r) => {
            chatHistory.value.push(r);
            loadingGame.value = false;
            loadingChat.value = false;
          })
          .catch(() => {
            // Something went wrong with the retrieval of starting chat, so just set a default
            chatHistory.value.push("Welcome to my puzzles!");
            loadingGame.value = false;
            loadingChat.value = false;
          });
      }
    });
  });
}

function initCasual(useBotId: string, isIntro: boolean = false) {
  bs.getUserBotProfile(useBotId).then((response) => {
    bot = ref(response.data.bot);
    challengeState.value = ChallengeState.StartingCasual;
    setBoardBg(bot.value.config.boardbg);
    backgroundStore.background = ps.img(
      bot.value.id,
      ImageType.BotBackground,
      null
    );
    loadingGame.value = false;
    if (props.autoStart) {
      userInput({ type: UserInput.StartCasual, data: { isIntro: isIntro } });
    }
  });
}

function initContinue(useGameId: string) {
  gameStore.refreshGame(useGameId).then((game) => {
    if (
      game == null ||
      !game.isActive ||
      game.result != null ||
      game.termination == GameTermination.Aborted
    ) {
      returnToHome();
      return;
    }

    const promisesToAwait: Promise<any>[] = [bs.getUserBotProfile(game.bot.id)];
    playerColor = game.userSide == Side.White ? "white" : "black";
    boardConfig.orientation = game.userSide == Side.White ? "white" : "black";

    lastMoveTimeSpent.value = game.lastMoveSpentTime ?? 0;

    if (game.challenge != undefined) {
      if (game.challenge.id == "custom") {
        thisChallenge.value = {
          challenge: {
            id: "custom",
            type: "from_position",
            user_side: game.userSide == Side.White ? "white" : "black",
            customBot: {
              botId: game.bot.id,
            },
            start_position: game.startPosition!,
          },
          difficulty: "custom",
        };
      } else if (game.type == "dailyendgame" || game.type == "dailymaster") {
        promisesToAwait.push(generalStore.getDailyPosition(game.type));
      } else if (game.challenge.difficulty == "dailymatchup") {
        promisesToAwait.push(generalStore.getDailyMatchup());
      } else if (game.challenge.difficulty == "practice") {
        promisesToAwait.push(generalStore.getPractice(game.challenge?.id));
      } else {
        promisesToAwait.push(generalStore.getChallenge(game.challenge?.id));
      }
    }
    Promise.all(promisesToAwait).then((result) => {
      if (result.length == 2) {
        // Won't have a second result if challenge was undefined, so can safely assume it's there
        if (game.type == "dailyendgame" || game.type == "dailymaster") {
          thisDailyPosition.value = result[1];
        } else if (game.challenge?.difficulty == "dailymatchup") {
          thisDailyMatchup.value = result[1];
        } else if (game.challenge?.difficulty == "practice") {
          thisChallenge.value = {
            challenge: result[1],
            difficulty: "practice",
          };
          thisChallenge.value.challenge.customBot = {
            botId: game.bot.id,
          };

          if (result[1].user_achieved) {
            thisChallenge.value.challenge.customBot.previousBestBeatenBot = {
              id: result[1].user_botId!,
              rating: result[1].user_botRatingAtWin!,
              gainedPoints: result[1].user_points!,
            };
          }
        } else {
          thisChallenge.value = {
            challenge: result[1],
            difficulty: game.challenge!.difficulty,
          };
        }
      }

      if (game.rated) {
        showRatedBox.value = true;
      }

      if (game.rated && game.ratingChange != null) {
        ratingInfo.value = game.ratingChange;
      }

      bot = ref(result[0].data.bot);
      setBoardBg(bot.value.config.boardbg);
      backgroundStore.background = ps.img(
        bot.value.id,
        ImageType.BotBackground,
        null
      );
      loadingGame.value = false;
    });
  });
}

function nextPuzzle() {
  let nextPuzzleIndex = currentPuzzleSet.value!.findIndex(
    (p) => p.user_result == null
  );

  endOfPuzzleAnimation.value = null;
  currentViewingPuzzle.value = null;

  if (nextPuzzleIndex === -1) {
    // Since we're initializing, we shouldn't really get here unless the user typed it in manually or refreshed the page
    // Just give a generic message. We could do it dynamically, but should be rare that this happens
    currentPuzzleIndex.value = -1;
    challengeState.value = ChallengeState.FinishedPuzzleSet;
    loadingChat.value = true;
    bs.getChat(bot.value.id, "finished_puzzleset", {
      results: getResultText(currentPuzzleSet.value!),
    })
      .then((r) => {
        chatHistory.value.push(r);
        loadingGame.value = false;
        loadingChat.value = false;
      })
      .catch(() => {
        // Something went wrong with the retrieval of starting chat, so just set a default
        chatHistory.value.push("You finished all my puzzles, try some other!");
        loadingGame.value = false;
        loadingChat.value = false;
      });
  } else {
    currentPuzzleIndex.value = nextPuzzleIndex;
    currentPuzzle.value = currentPuzzleSet.value![nextPuzzleIndex];

    const storedPuzzle =
      localStorage.getItem("currentPuzzleSolveHistory") == null
        ? null
        : JSON.parse(localStorage.getItem("currentPuzzleSolveHistory")!);

    if (
      storedPuzzle == null ||
      storedPuzzle.puzzleId != currentPuzzle.value.id
    ) {
      // There either weren't any stored puzzle or the stored puzzle didn't match the current one, so we start from scratch
      currentPuzzleMoveIndex.value = 0;
      // Initialize the solve history with empty arrays
      currentPuzzleSolveHistory.value = Array.from(
        {
          length: Math.ceil(currentPuzzle.value.moves.split(" ").length / 2),
        },
        () => []
      );

      boardConfig.fen = currentPuzzle.value.fen;
    } else {
      // We have a stored puzzle, so let's use that
      currentPuzzleMoveIndex.value = storedPuzzle.moveIndex;
      currentPuzzleSolveHistory.value = storedPuzzle.solveHistory;
      boardConfig.fen = storedPuzzle.fen;
    }

    challengeState.value = ChallengeState.PlayingPuzzle;
    boardConfig.viewOnly = false;
    playerColor = currentPuzzle.value.side;
    boardConfig.orientation = currentPuzzle.value.side;

    updateRemainingHints([
      ...currentPuzzleSet
        .value!.filter((p) => p.user_result != null)
        .map((p) => p.user_result as PuzzleSolveHistory),
      currentPuzzleSolveHistory.value!,
    ]);

    if (boardAPI.value != null) {
      // If the boardAPI is initialized we do this here, otherwise it's done in the boardCreated hook
      setTimeout(() => {
        makeEnginePuzzleMove();
      }, 1000);
    }
    loadingChat.value = true;

    // Create the parameters for start_of_puzzle chat

    let params: Record<string, string> = {
      solved_so_far: currentPuzzleIndex.value + "",
      results_so_far: getResultText(currentPuzzleSet.value!),
      current_puzzle_index: currentPuzzleIndex.value + 1 + "",
    };

    bs.getChat(bot.value.id, "start_of_puzzle", params)
      .then((r) => {
        chatHistory.value.push(r);
        loadingGame.value = false;
        loadingChat.value = false;
      })
      .catch(() => {
        // Something went wrong with the retrieval of starting chat, so just set a default
        chatHistory.value.push("Next puzzle up!");
        loadingGame.value = false;
        loadingChat.value = false;
      });
  }
}

function sideToMove(): "user" | "bot" | null {
  if (boardAPI.value == null || gameResult.value != null) {
    return null;
  }

  return playerColor == boardAPI.value?.getTurnColor() ? "user" : "bot";
}

function setBoardBg(color: string | null | undefined) {
  if (!color) {
    color = "#3cbfe0";
  }
  botBg.value = color;

  if (usePageStore().boardColorOverride.active) {
    chessBoardBg.value = usePageStore().boardColorOverride.color;
  } else {
    chessBoardBg.value = color;
  }
}

async function setupBotForChallenge(botId: string, triggerId: ChatTriggerId) {
  let response = await bs.getUserBotProfile(botId);

  bot = ref(response.data.bot);

  setBoardBg(response.data.bot.config.boardbg);
  backgroundStore.background = ps.img(
    bot.value.id,
    ImageType.BotBackground,
    null
  );
  try {
    loadingChat.value = true;
    let r = await bs.getChat(bot.value.id, triggerId);
    chatHistory.value.push(r);
    loadingGame.value = false;
    loadingChat.value = false;
  } catch (e) {
    // Something went wrong with the retrieval of starting chat, so just set a default
    chatHistory.value.push("Let's get started!");
    loadingGame.value = false;
  }
}

function exposeEffect() {
  // Assuming the divs are positioned centrally to begin with
  // This will push them outwards in all directions
  let left = anime({
    targets: ".expose-item-right",
    keyframes: [
      { translateX: anime.stagger("20vw", { from: "center", grid: [3, 3] }) },
    ],
    duration: 2000,
    loop: false,
    autoplay: false,
    direction: "alternate",
  });
  let right = anime({
    targets: ".expose-item-left",
    keyframes: [
      { translateX: anime.stagger("-40vw", { from: "center", grid: [3, 3] }) },
    ],
    duration: 2000,
    loop: false,
    autoplay: false,
    direction: "alternate",
  });
  let revert = anime({
    targets: [".expose-item-left", ".expose-item-right"],
    keyframes: [{ translateX: 0 }, { translateY: 0 }],
    duration: 2000,
    autoplay: false,
  });

  if (reverseExpose) {
    revert.play();
  } else {
    left.play();
    right.play();
  }
  reverseExpose = !reverseExpose;
}

async function updateBoard(
  game: Game | null = null,
  engineMoveJustMade: string | null = null
) {
  if (game == null) {
    if (gameId.value == undefined) {
      return;
    }
    game = await gameStore.refreshGame(gameId.value!);
  }

  if (game.timeControl != null) {
    timeControl.value = game.timeControl; // TODO Setting this every time seem unnecessary, it will be the same every time
    currentTimes.value = timeLeftPerSide(
      game.startedAt,
      game.times!,
      game.timeControl
    );
  }

  if (engineMoveJustMade) {
    makeChessgroundMove(
      boardAPI.value!,
      engineMoveJustMade,
      gameStore.games[gameId.value!].positions[
        gameStore.games[gameId.value!].positions.length - 1
      ]
    );
  } else {
    let pgn: string = "";

    if (game.startPosition != null) {
      let fen = game.startPosition;

      if (fen.split(" ").length <= 1) {
        fen += " w KQkq - 0 1";
      }

      pgn += '[SetUp "1"]\n[FEN "' + fen + '"]\n\n';
    }

    pgn += gameStore.moveString(gameId.value!) as string;

    boardAPI.value?.loadPgn(pgn);
  }

  moves.value = boardAPI.value?.getHistory() ?? [];

  usePageStore().setPlayMoveSound(moves.value[moves.value.length - 1]);

  currentPosition.value = boardAPI.value?.getFen() ?? "";
  boardConfig.viewOnly = false;

  if (
    game.result != null ||
    (game.termination != null && game.termination == GameTermination.Aborted)
  ) {
    setEndOfGameState(game);
  } else if (
    (game.userSide == Side.White && game.moves.length == 0) ||
    (game.userSide == Side.Black && game.moves.length <= 1)
  ) {
    if (game.rated) {
      challengeState.value = ChallengeState.FirstMoveRated;
    } else {
      challengeState.value = ChallengeState.FirstMoveCasual;
    }
  } else {
    if (game.rated) {
      challengeState.value = ChallengeState.PlayingRated;
    } else {
      challengeState.value = ChallengeState.PlayingCasual;
    }
  }

  if (game.result == null && playerColor != boardAPI.value?.getTurnColor()!) {
    await makeEngineMove();
  } else if (game.result == null) {
    // If we get here the game didn't finish yet (the chat for that is retrieved in the setEndOfGameState function)
    // and it's the user's turn, so a good time to request a chat

    getChatTriggerIdFromGame(game).then((triggeredTrigger) => {
      if (triggeredTrigger != null) {
        loadingChat.value = true;

        bs.getChat(
          game!.bot.id,
          triggeredTrigger.id,
          triggeredTrigger.data
        ).then((r) => {
          chatHistory.value.push(r);
          loadingChat.value = false;
        });
      }
    });
  }
}

async function moveMade(move: Move) {
  currentPosition.value = boardAPI.value?.getFen() ?? "";

  usePageStore().setPlayMoveSound(move.san);

  if (props.type == "puzzle") {
    // Wrapping this entire thing in a timeout since the board needs time to update. Probably a better way to do this
    nextTick().then(() => {
      // We'll end up here from both "engine" and user moves, so have to figure out what we're doing here based on the index
      currentPuzzleMoveIndex.value! += 1;
      if (currentPuzzleMoveIndex.value! % 2 === 0) {
        // This was a user move, so check if correct etc. and then make engine move if still moves left

        if (
          move.lan ==
          currentPuzzle.value!.moves.split(" ")[
            currentPuzzleMoveIndex.value! - 1
          ]
        ) {
          updateCurrentPuzzleSolveHistory(
            "correct_move",
            Math.floor((currentPuzzleMoveIndex.value! - 1) / 2)
          );

          boardConfig.viewOnly = true;

          if (
            currentPuzzleMoveIndex.value! >=
            currentPuzzle.value!.moves.split(" ").length
          ) {
            // Puzzle is done so clean any solve history (had a bug where an old puzzle got stuck, shouldn't be a big thing but might as well)
            localStorage.removeItem("currentPuzzleSolveHistory");

            currentPuzzle.value!.user_result = currentPuzzleSolveHistory.value!;

            endOfPuzzleAnimation.value = currentPuzzleSolveHistory.value!.every(
              (subArray) =>
                subArray.every((element) => element === "correct_move")
            )
              ? "win"
              : "win_with_hint";
            generalStore
              .solvePuzzle(
                bot.value.id,
                currentPuzzle.value!.id,
                currentPuzzleSolveHistory.value!
              )
              .then(() => {
                currentViewingPuzzle.value = currentPuzzle.value!;
                challengeState.value = ChallengeState.ViewingPuzzle;
              });
          } else {
            // Puzzle is not solved, so make engine move
            setTimeout(() => {
              makeEnginePuzzleMove();
            }, 500);
          }
        } else {
          // Wrong move, so record it and then undo it if hints left or fail it if not
          updateRemainingHints([
            ...currentPuzzleSet
              .value!.filter((p) => p.user_result != null)
              .map((p) => p.user_result as PuzzleSolveHistory),
            currentPuzzleSolveHistory.value!,
          ]);
          if (currentPuzzleHints.value >= 1) {
            currentPuzzleMoveIndex.value! -= 1;

            usePageStore().setPlaySound("lose-hint");

            setTimeout(() => {
              boardAPI.value?.undoLastMove();
              updateCurrentPuzzleSolveHistory(
                "wrong_move",
                Math.floor(currentPuzzleMoveIndex.value! / 2)
              );
            }, 1000);
          } else {
            updateCurrentPuzzleSolveHistory(
              "failed",
              Math.floor((currentPuzzleMoveIndex.value! - 1) / 2)
            );

            currentPuzzle.value!.user_result = currentPuzzleSolveHistory.value!;
            endOfPuzzleAnimation.value = "lose";

            generalStore
              .solvePuzzle(
                bot.value.id,
                currentPuzzle.value!.id,
                currentPuzzleSolveHistory.value!
              )
              .then(() => {
                currentViewingPuzzle.value = currentPuzzle.value!;
                enterViewingMode(currentPuzzle.value!);
                challengeState.value = ChallengeState.ViewingPuzzle;
              });
          }

          // Fail was recorded so refresh the current hints
          updateRemainingHints([
            ...currentPuzzleSet
              .value!.filter((p) => p.user_result != null)
              .map((p) => p.user_result as PuzzleSolveHistory),
            currentPuzzleSolveHistory.value!,
          ]);
          boardcontainer.value?.classList.add("shake");
          setTimeout(() => {
            boardcontainer.value?.classList.remove("shake");
          }, 1000);
        }
      } else {
        // This was an engine move, so make it possible for the user to answer
        boardConfig.viewOnly = false;
      }
    });
  } else {
    if (gameId.value == undefined) {
      console.error("Making move in unknown game");
      return;
    }

    pendingMove.value = true;
    lastMoveTimeSpent.value = 0; // Since the user just made a move, we don't need the spent time anymore (it's only used when initializing a game and we haven't kept track of the time in the client)

    if (boardAPI.value?.getIsGameOver()) {
      // Game is over after this move, so just send the move to the server
      gameStore.makeMove(gameId.value, move).then((game: Game) => {
        pendingMove.value = false;
        moves.value = boardAPI.value?.getHistory() ?? [];
        if (game.result != null) {
          setEndOfGameState(game);
        } else {
          // This should never happen since we just checked if the game was over, but just in case ask for the engine move
          console.error("Game should've been over");
          updateBoard(game);
          makeEngineMove();
        }
      });
    } else {
      gameStore
        .makeMoveAndMakeEngineMove(
          gameId.value,
          move,
          usePageStore().currentMoveTime
        )
        .then((r) => {
          if (r.engineTakenTime != null && r.engineTakenTime > 3000) {
            awaitingBotMove.value = true;
          }
          setTimeout(() => {
            gameStore.games[gameId.value!] = r.game;
            pendingMove.value = false;
            moves.value = boardAPI.value?.getHistory() ?? [];

            updateBoard(r.game, r.game.moves[r.game.moves.length - 1]);
            awaitingBotMove.value = false;
            if (r.game.result != null) {
              setEndOfGameState(r.game);
            } else if (pendingPremove) {
              try {
                let premove: Move = new Chess(boardAPI.value?.getFen()).move(
                  pendingPremove
                );
                // This is usually reset on a move coming in from the server, but since we're making a move
                // immediately here, it won't have time to reach the client, so we have to reset it manually
                usePageStore().currentMoveTime = 0;
                boardAPI.value?.move(premove.san);
              } catch (e) {
                // We're using the error from chess.js to catch invalid move, so this is expected, just cancel the premove
                // @ts-ignore - The board is not accessible unfortunately, so have to hack it a bit (typescript won't recognize this)
                boardAPI.value?.board.cancelPremove();
              } finally {
                pendingPremove = null;
              }
            }
          }, getThinkingDelay(r));
        })
        .catch(() => {
          useToast().error(
            "Failed to send move, try again! (make sure your internet connection is working)"
          );
          boardAPI.value?.undoLastMove();
        });
    }
  }
}

function boardAnimationState(data: any) {
  boardAnimationRunning.value = data.isRunning;
}

function getThinkingDelay(r: { game: Game; engineTakenTime?: number }) {
  if (r.game.timeControl == null && usePageStore().botPlaysInstantInInfinite) {
    return 0;
  }

  return r.engineTakenTime == null ? 0 : r.engineTakenTime;
}

function setEndOfGameState(game: Game) {
  if (game.termination == GameTermination.Aborted) {
    gameResultString.value = "-";
    gameResult.value = game.result;
    gameTermination.value = game.termination;
    challengeState.value = ChallengeState.Aborted;
    return;
  }

  gameResultString.value = resultString(game.result);
  gameResult.value = game.result;
  gameTermination.value = game.termination;

  if (userWon(game)) {
    if (game.challenge == null) {
      if (game.rated) {
        challengeState.value = ChallengeState.FinishedRatedWin;
      } else {
        challengeState.value = ChallengeState.FinishedCasualWin;
      }
    } else if (game.challenge.id == "custom") {
      challengeState.value = ChallengeState.FinishedChallengeCustomWin;
    } else if (game.type == "dailyendgame" || game.type == "dailymaster") {
      challengeState.value = ChallengeState.FinishedDailyPositionWin;
    } else if (game.challenge.difficulty == "dailymatchup") {
      challengeState.value = ChallengeState.FinishedDailyMatchupWin;
    } else if (game.challenge.difficulty == "practice") {
      challengeState.value = ChallengeState.FinishedPracticeWin;
    } else {
      challengeState.value = ChallengeState.FinishedChallengeDifficultyWin;
    }
  } else {
    if (game.challenge == null) {
      if (game.rated) {
        challengeState.value = ChallengeState.FinishedRatedNotWin;
      } else {
        challengeState.value = ChallengeState.FinishedCasualNotWin;
      }
    } else if (game.challenge.id == "custom") {
      challengeState.value = ChallengeState.FinishedChallengeCustomNotWin;
    } else if (game.type == "dailyendgame" || game.type == "dailymaster") {
      challengeState.value = ChallengeState.FinishedDailyPositionNotWin;
    } else if (game.challenge.difficulty == "dailymatchup") {
      challengeState.value = ChallengeState.FinishedDailyMatchupNotWin;
    } else if (game.challenge.difficulty == "practice") {
      challengeState.value = ChallengeState.FinishedPracticeNotWin;
    } else {
      challengeState.value = ChallengeState.FinishedChallengeDifficultyNotWin;
    }
  }

  if (game.ratingChange != null) {
    ratingInfo.value = game.ratingChange;
  }

  // Game is over, so finish with retrieving the last chat
  let chatTriggerId: ChatTriggerId | null = null;
  switch (challengeState.value) {
    case ChallengeState.FinishedCasualWin:
    case ChallengeState.FinishedRatedWin:
      chatTriggerId = "end_of_game_bot_lost";
      break;
    case ChallengeState.FinishedCasualNotWin:
    case ChallengeState.FinishedRatedNotWin:
      // Not a win so need to figure out what the result was
      switch (game.termination) {
        case GameTermination.Resign:
          chatTriggerId = "end_of_game_bot_won_resign";
          break;
        case GameTermination.Checkmate:
          chatTriggerId = "end_of_game_bot_won_checkmate";
          break;
        case GameTermination.FiftyMove:
          chatTriggerId = "end_of_game_draw_50";
          break;
        case GameTermination.InsufficientMaterial:
          chatTriggerId = "end_of_game_draw_material";
          break;
        case GameTermination.Stalemate:
          chatTriggerId = "end_of_game_draw_stalemate";
          break;
        case GameTermination.Threefold:
          chatTriggerId = "end_of_game_draw_repetition";
          break;
        default:
          chatTriggerId = null;
      }
      break;
    case ChallengeState.FinishedDailyPositionWin:
    case ChallengeState.FinishedChallengeCustomWin:
    case ChallengeState.FinishedChallengeDifficultyWin:
    case ChallengeState.FinishedPracticeWin:
      chatTriggerId = "end_of_challenge_succeeded";
      break;
    case ChallengeState.FinishedDailyPositionNotWin:
    case ChallengeState.FinishedChallengeCustomNotWin:
    case ChallengeState.FinishedChallengeDifficultyNotWin:
    case ChallengeState.FinishedPracticeNotWin:
      chatTriggerId = "end_of_challenge_failed";
      break;
    case ChallengeState.FinishedDailyMatchupWin:
      chatTriggerId = "end_of_daily_matchup_succeeded";
      break;
    case ChallengeState.FinishedDailyMatchupNotWin:
      chatTriggerId = "end_of_daily_matchup_failed";
      break;
  }

  if (chatTriggerId != null) {
    loadingChat.value = true;
    bs.getChat(game.bot.id, chatTriggerId)
      .then((r) => {
        chatHistory.value.push(r);
        loadingChat.value = false;
      })
      .catch(() => {
        // Something went wrong with the retrieval of starting chat, so just set a default
        chatHistory.value.push("Game over!");
        loadingChat.value = false;
      });
  }
}

function userWon(game: Game): boolean | null {
  if (game.result == null) {
    return null;
  }

  if (game.userSide == Side.White) {
    return game.result == Result.White;
  } else {
    return game.result == Result.Black;
  }
}

async function makeEngineMove() {
  if (gameId.value == undefined) {
    console.error("Requesting engine move in unknown game");
    return;
  }

  gameStore
    .makeEngineMove(gameId.value)
    .then((r) => {
      setTimeout(() => {
        gameStore.games[gameId.value!] = r.game;
        updateBoard(r.game);
      }, getThinkingDelay(r));
    })
    .catch(() => {
      failedEngineMove.value = true;
    });
}

function updateRemainingHints(solveHistories: PuzzleSolveHistory[]): void {
  const initialHints = 2;
  const maxHints = 3;

  let hints = calculateRemainingHintsRaw(
    solveHistories,
    initialHints,
    maxHints
  );

  if (hints < 0) {
    // With correct data this shouldn't happen
    hints = 0;
    console.error("Error: hints went below 0");
  }

  ps.puzzleHintsChanged = {
    change: hints - currentPuzzleHints.value,
    current: hints,
  };

  currentPuzzleHints.value = hints;
}

function makeEnginePuzzleMove() {
  if (currentPuzzleMoveIndex.value! % 2 === 1) {
    console.error("Not engine's turn");
    // This shouldn't happen, but can be caused by a wrong state being stored, so remove the state just in case,
    // this will make it so if the user refreshes the page it will start from scratch and work
    localStorage.removeItem("currentPuzzleSolveHistory");
    return;
  }
  boardAPI.value!.move(
    uciMoveToMove(
      currentPuzzle.value!.moves.split(" ")[currentPuzzleMoveIndex.value!]
    )
  );
  currentPosition.value = boardAPI.value?.getFen() ?? "";
}

const resultString = (result: Result | null): string | null => {
  if (gameId.value == null) {
    return null;
  }

  switch (result) {
    case Result.White:
      return "1-0";
    case Result.Black:
      return "0-1";
    case Result.Draw:
      return "1/2-1/2";
    default:
      return null;
  }
};

function resetIfHistory() {
  if (viewingHistoryPly.value != null) {
    track("game_arena", "reset_to_history", "click");
    viewingHistoryPly.value = null;
    boardAPI.value?.stopViewingHistory();
  }
}

function updateCurrentPuzzleSolveHistory(
  hintType:
    | "wrong_move"
    | "correct_move"
    | "hint1"
    | "hint2"
    | "solution"
    | "failed",
  index: number
) {
  currentPuzzleSolveHistory.value![index].push(hintType);

  localStorage.setItem(
    "currentPuzzleSolveHistory",
    JSON.stringify({
      puzzleId: currentPuzzle.value?.id,
      moveIndex: currentPuzzleMoveIndex.value,
      fen: boardAPI.value?.getFen(),
      solveHistory: currentPuzzleSolveHistory.value,
    })
  );
}

function enterViewingMode(puzzle: Puzzle) {
  boardConfig.viewOnly = true;
  currentViewingPuzzle.value = puzzle;
  challengeState.value = ChallengeState.ViewingPuzzle;
  boardAPI.value?.resetBoard();
  boardConfig.fen = puzzle.fen;
  boardConfig.orientation = puzzle.side;
  viewingHistoryPly.value = 0;
}

function userInput(input: any) {
  if (input.type == UserInput.ViewPuzzle) {
    let viewPuzzleIndex = currentPuzzleSet.value!.findIndex(
      (p) => p.id == input.puzzle.id
    );

    let includeCurrentPuzzle =
      currentPuzzleSet.value![currentPuzzleIndex.value!].user_result != null;

    if (
      viewPuzzleIndex < currentPuzzleIndex.value ||
      (includeCurrentPuzzle && viewPuzzleIndex == currentPuzzleIndex.value)
    ) {
      enterViewingMode(input.puzzle);
    }
  } else if (input.type == UserInput.UsePuzzleHint) {
    // Temporarily disable the hint button so it can't be spammed
    showPuzzleHintIfAvailable.value = false;
    setTimeout(() => {
      showPuzzleHintIfAvailable.value = true;
    }, 2000);

    if (currentPuzzleHints.value < 1) {
      // No hints left, so shouldn't have been possible to come here in the first place
      return;
    }

    let currentMoveAttempts =
      currentPuzzleSolveHistory.value![
        Math.floor(currentPuzzleMoveIndex.value! / 2)
      ];

    if (
      currentMoveAttempts.includes("failed") ||
      currentMoveAttempts.includes("correct_move") ||
      currentMoveAttempts.includes("solution")
    ) {
      // Shouldn't be here since the puzzle is already failed, solved or all hints used
      return;
    }

    usePageStore().setPlaySound("use-hint");

    // Default to using hint1 unless it was already used
    let hintType = "hint1" as "hint1" | "hint2" | "solution";

    if (currentMoveAttempts.includes("hint2")) {
      // Hint2 was used, so the hint left is solution
      hintType = "solution";
    } else if (currentMoveAttempts.includes("hint1")) {
      // hint1 was used, so next is hint2
      hintType = "hint2";
    }

    updateCurrentPuzzleSolveHistory(
      hintType,
      Math.floor(currentPuzzleMoveIndex.value! / 2)
    );

    updateRemainingHints([
      ...currentPuzzleSet
        .value!.filter((p) => p.user_result != null)
        .map((p) => p.user_result as PuzzleSolveHistory),
      currentPuzzleSolveHistory.value!,
    ]);

    chatHistory.value.push(currentPuzzle.value!.hints![0][hintType]);
  } else if (input.type == UserInput.StartPuzzle) {
    nextPuzzle();
  } else if (input.type == UserInput.RetryPractice) {
    if (thisChallenge.value == null) {
      console.error("No practice to retry");
      return;
    }

    if (input.switchOpponent) {
      window.location.href =
        window.location.href.split("?")[0] +
        "?practiceId=" +
        thisChallenge.value.challenge.id +
        "&type=practice";
    } else {
      window.location.href =
        window.location.href.split("?")[0] +
        "?practiceId=" +
        thisChallenge.value.challenge.id +
        "&bid=" +
        thisChallenge.value.challenge.customBot?.botId +
        "&type=practice";
    }
  } else if (input.type == UserInput.RetryCustomChallenge) {
    if (thisChallenge.value == null) {
      console.error("No challenge to retry");
      return;
    }

    if (input.switchOpponent) {
      window.location.href =
        window.location.href.split("?")[0] +
        "?fen=" +
        thisChallenge.value.challenge.start_position +
        "&side=" +
        thisChallenge.value.challenge.user_side +
        "&type=custom";
    } else {
      window.location.href =
        window.location.href.split("?")[0] +
        "?fen=" +
        thisChallenge.value.challenge.start_position +
        "&bid=" +
        thisChallenge.value.challenge.customBot!.botId +
        "&side=" +
        thisChallenge.value.challenge.user_side +
        "&type=custom";
    }
  } else if (input.type == UserInput.RetryChallenge) {
    if (thisChallenge.value == null) {
      console.error("No challenge to retry");
      return;
    }

    window.location.href =
      window.location.href.split("?")[0] +
      "?challengeId=" +
      thisChallenge.value!.challenge.id +
      "&difficulty=" +
      thisChallenge.value!.difficulty +
      "&type=challenge";
  } else if (input.type == UserInput.PlayNext) {
    challengeState.value = ChallengeState.HandlingInput;

    if (input.bot != null) {
      window.location.href =
        window.location.href.split("?")[0] +
        "?bid=" +
        input.bot.id +
        "&type=casual";
    } else if (input.unbeatenChallenge != null) {
      window.location.href =
        window.location.href.split("?")[0] +
        "?challengeId=" +
        input.unbeatenChallenge.challenge.id +
        "&difficulty=" +
        input.unbeatenChallenge.unbeatenDifficulty +
        "&type=challenge";
    }
  } else if (input.type == UserInput.MorePuzzles) {
    window.location.href =
      window.location.href.split("?")[0] +
      "?puzzleId=" +
      input.bot.id +
      "&type=puzzle";
  } else if (input.type == UserInput.HistoryGoto) {
    viewingHistoryPly.value = input.ply;
    boardAPI.value?.viewHistory(input.ply);
    // @ts-ignore board is private, but we need to use it since the API doesn't reflect history
    currentPosition.value = boardAPI.value.board.getFen();
  } else if (input.type == UserInput.HistoryStart) {
    viewingHistoryPly.value = 0;

    if (challengeState.value == ChallengeState.ViewingPuzzle) {
      boardConfig.fen = currentViewingPuzzle.value!.fen;
    } else {
      boardAPI.value?.viewStart();
    }
    // @ts-ignore board is private, but we need to use it since the API doesn't reflect history
    currentPosition.value = boardAPI.value.board.getFen();
  } else if (input.type == UserInput.HistoryPrevious) {
    if (challengeState.value == ChallengeState.ViewingPuzzle) {
      if (viewingHistoryPly.value == null) {
        viewingHistoryPly.value =
          currentViewingPuzzle.value!.moves.split(" ").length - 1;
      } else {
        viewingHistoryPly.value--;
      }

      if (viewingHistoryPly.value < 0) {
        viewingHistoryPly.value = 0;
        return;
      }

      // To move back we need to load all moves in the chessjs object and then undo back to where we want to be
      let chess = new Chess(currentViewingPuzzle.value!.fen);
      currentViewingPuzzle.value!.moves.split(" ").forEach((move) => {
        chess.move(move);
      });

      for (
        let i = 0;
        i <
        currentViewingPuzzle.value!.moves.split(" ").length -
          viewingHistoryPly.value;
        i++
      ) {
        chess.undo();
      }

      boardConfig.fen = chess.fen();
    } else {
      if (viewingHistoryPly.value == null) {
        viewingHistoryPly.value = boardAPI.value!.getHistory().length - 1;
      } else {
        viewingHistoryPly.value--;
      }

      if (viewingHistoryPly.value < 0) {
        viewingHistoryPly.value = 0;
      }
      boardAPI.value?.viewHistory(viewingHistoryPly.value);
    }
    // @ts-ignore board is private, but we need to use it since the API doesn't reflect history
    currentPosition.value = boardAPI.value.board.getFen();
  } else if (input.type == UserInput.HistoryNext) {
    if (viewingHistoryPly.value == null) {
      return;
    }

    viewingHistoryPly.value++;

    if (challengeState.value == ChallengeState.ViewingPuzzle) {
      let chess = new Chess(boardAPI.value?.getFen());

      let currentMove =
        currentViewingPuzzle.value!.moves.split(" ")[
          viewingHistoryPly.value - 1
        ];

      if (currentMove == null) {
        // We're at the end of the puzzle, so shouldn't be here
        return;
      }

      chess.move(currentMove);
      boardConfig.fen = chess.fen();

      if (
        viewingHistoryPly.value >=
        currentViewingPuzzle.value!.moves.split(" ").length
      ) {
        viewingHistoryPly.value = null;
      }
    } else {
      if (viewingHistoryPly.value >= boardAPI.value!.getHistory().length) {
        viewingHistoryPly.value = null;
      }

      boardAPI.value?.viewHistory(
        viewingHistoryPly.value == null
          ? boardAPI.value!.getHistory().length
          : viewingHistoryPly.value
      );
    }
    // @ts-ignore board is private, but we need to use it since the API doesn't reflect history
    currentPosition.value = boardAPI.value.board.getFen();
  } else if (input.type == UserInput.HistoryEnd) {
    if (challengeState.value == ChallengeState.ViewingPuzzle) {
      if (viewingHistoryPly.value == null) {
        // Already at the end on the puzzle so shouldn't be here
        return;
      }
      viewingHistoryPly.value = null;
      let chess = new Chess(boardAPI.value?.getFen());
      currentViewingPuzzle.value!.moves.split(" ").forEach((move) => {
        chess.move(move);
      });

      boardConfig.fen = chess.fen();
    } else {
      viewingHistoryPly.value = null;
      boardAPI.value?.stopViewingHistory();
      boardAPI.value?.viewHistory(boardAPI.value!.getHistory().length);
    }
    // @ts-ignore board is private, but we need to use it since the API doesn't reflect history
    currentPosition.value = boardAPI.value.board.getFen();
  } else if (input.type == UserInput.StartDailyMatchup) {
    challengeState.value = ChallengeState.HandlingInput;

    gameStore.newDailyMatchupGame().then((game: Game) => {
      gameId.value = game.id;

      if (game.rated && game.ratingChange) {
        ratingInfo.value = game.ratingChange;
        showRatedBox.value = true;
      }
      playerColor = game.userSide == Side.White ? "white" : "black";
      boardConfig.orientation = game.userSide == Side.White ? "white" : "black";
      updateBoard(game);
    });
  } else if (input.type == UserInput.StartDailyPosition) {
    challengeState.value = ChallengeState.HandlingInput;

    if (props.type != "dailyendgame" && props.type != "dailymaster") {
      console.error("Starting daily position with wrong type");
      return;
    }

    if (props.relativeDifficulty == null) {
      console.error("Starting daily position with no difficulty");
      return;
    }

    gameStore
      .newDailyPositionGame(props.type, props.relativeDifficulty)
      .then((game: Game) => {
        gameId.value = game.id;
        playerColor = game.userSide == Side.White ? "white" : "black";
        boardConfig.orientation =
          game.userSide == Side.White ? "white" : "black";
        updateBoard(game);
      });
  } else if (input.type == UserInput.StartChallenge) {
    challengeState.value = ChallengeState.HandlingInput;

    if (thisChallenge.value == null) {
      console.error("No challenge to start");
      return;
    }

    if (thisChallenge.value.difficulty == "custom") {
      gameStore
        .newCustomChallengeGame(
          thisChallenge.value.challenge.customBot!.botId,
          thisChallenge.value.challenge.start_position,
          thisChallenge.value.challenge.user_side
        )
        .then((game: Game) => {
          gameId.value = game.id;
          playerColor = game.userSide == Side.White ? "white" : "black";
          boardConfig.orientation =
            game.userSide == Side.White ? "white" : "black";
          boardConfig.fen = thisChallenge.value!.challenge.start_position;

          updateBoard(game);
        });
    } else if (thisChallenge.value.difficulty == "practice") {
      gameStore
        .newPracticeGame(
          thisChallenge.value!.challenge.id,
          thisChallenge.value.challenge.customBot!.botId
        )
        .then((game: Game) => {
          gameId.value = game.id;
          playerColor = game.userSide == Side.White ? "white" : "black";
          boardConfig.orientation =
            game.userSide == Side.White ? "white" : "black";
          boardConfig.fen = game.startPosition!;
          updateBoard(game);
        });
    } else {
      gameStore
        .newChallengeGame(
          thisChallenge.value!.challenge.id,
          thisChallenge.value!.difficulty
        )
        .then((game: Game) => {
          gameId.value = game.id;
          playerColor = game.userSide == Side.White ? "white" : "black";
          boardConfig.orientation =
            game.userSide == Side.White ? "white" : "black";
          boardConfig.fen = thisChallenge.value!.challenge.start_position;

          updateBoard(game);
        });
    }
  } else if (input.type == UserInput.SelectBotForChallenge) {
    loadingBotForChallenge.value = true;
    thisChallenge.value!.challenge.customBot!.botId = input.data;

    setupBotForChallenge(input.data, "start_of_challenge").then(() => {
      userInput({ type: UserInput.StartChallenge });
      loadingBotForChallenge.value = false;
    });
  } else if (input.type == UserInput.StartCasual) {
    let isIntroGame = input.data != null && input.data.isIntro;

    challengeState.value = ChallengeState.HandlingInput;
    if (
      ps.gameSettings?.rated == null ||
      ps.gameSettings?.rated == "rated" ||
      isIntroGame
    ) {
      let timeControl = usePageStore().gameSettings?.timeControl;
      if (isIntroGame) {
        // Override time to no time if it's the intro game (shouldn't be needed, but doesn't hurt)
        timeControl = null;
      }

      gameStore.newGameRated(props.botId, timeControl).then((game) => {
        showRatedBox.value = true;
        gameId.value = game.id;
        playerColor = game.userSide == Side.White ? "white" : "black";
        boardConfig.orientation =
          game.userSide == Side.White ? "white" : "black";

        if (game.rated && game.ratingChange != null) {
          // This sohuld always be rated and if it's rated it should always have a ratingChange, but double-checking just in case
          ratingInfo.value = game.ratingChange;
        }

        updateBoard(game);
        loadingChat.value = true;

        bs.getChat(game.bot.id, "start_of_game_rated")
          .then((r) => {
            chatHistory.value.push(r);
            loadingGame.value = false;
            loadingChat.value = false;
          })
          .catch(() => {
            // Something went wrong with the retrieval of starting chat, so just set a default
            chatHistory.value.push("Let's go!");
            loadingGame.value = false;
            loadingChat.value = false;
          });
      });
    } else {
      let color;
      if (
        ps.gameSettings?.color == "white" ||
        ps.gameSettings?.color == "black"
      ) {
        color = ps.gameSettings.color == "white" ? Side.White : Side.Black;
      } else {
        let sides = [Side.White, Side.Black];
        color = sides[Math.floor(Math.random() * sides.length)];
      }

      let timeControl = usePageStore().gameSettings?.timeControl;

      gameStore
        .newGameCasual(props.botId!, color, timeControl)
        .then((game: Game) => {
          gameId.value = game.id;
          playerColor = game.userSide == Side.White ? "white" : "black";
          boardConfig.orientation =
            game.userSide == Side.White ? "white" : "black";
          updateBoard(game);

          loadingChat.value = true;

          bs.getChat(game.bot.id, "start_of_game_casual")
            .then((r) => {
              chatHistory.value.push(r);
              loadingGame.value = false;
              loadingChat.value = false;
            })
            .catch(() => {
              // Something went wrong with the retrieval of starting chat, so just set a default
              chatHistory.value.push("Let's go!");
              loadingGame.value = false;
              loadingChat.value = false;
            });
        });
    }
  } else if (input.type == UserInput.Abort) {
    challengeState.value = ChallengeState.HandlingInput;
    if (gameId.value == undefined) {
      console.error("No game to abort");
    } else {
      gameStore.abort(gameId.value).then((gameState) => {
        viewingHistoryPly.value = null;
        updateBoard(gameState.gameState);
      });
    }
  } else if (input.type == UserInput.Resign) {
    challengeState.value = ChallengeState.HandlingInput;
    if (gameId.value == undefined) {
      console.error("No game to resign");
    } else {
      gameStore.resign(gameId.value).then((gameState) => {
        viewingHistoryPly.value = null;
        updateBoard(gameState.gameState);
      });
    }
  } else if (input.type == UserInput.PlayAnotherSimilarRatedOpponent) {
    if (!us.user.data?.rating?.rating) {
      // User didn't have a rating, shouldn't happen since user just finished a rated game, but handling it just in case
      useToast().error("Failed to find a bot, please try again later.");
      return;
    }
    let bot = bs.getRandomBot(us.user.data!.rating.rating!);

    if (bot == null) {
      // Couldn't find a bot, shouldn't happen, but handling it just in case
      useToast().error("Failed to find a bot, please try again later.");
      return;
    }

    router
      .push({
        name: "game",
        query: {
          bid: bot.id,
          type: "casual",
        },
      })
      .then(() => {
        router.go(0);
      });
  } else if (input.type == UserInput.Rematch) {
    challengeState.value = ChallengeState.HandlingInput;
    // Ridicuously hacky solution to just reset the stupid component. This is what happens if challenging from a different url
    // so not that bad really, but there should be more graceful solutions

    window.location.href =
      window.location.href.split("?")[0] +
      "?bid=" +
      bot.value.id +
      "&type=casual&auto=true";
  } else {
    console.error("Unknown user input: " + input.type);
  }
}

async function reloadPage() {
  // Refresh the games to make sure we're not stuck on some old state
  if (gameId.value != undefined) {
    await gameStore.refreshGame(gameId.value);
  }

  await gameStore.refreshActiveGame();

  // Does a simple reload of the page which keeps the query parameters and should be fine
  window.location.reload();
}

function getResultFromUserPerspective(): "win" | "draw" | "loss" | null {
  if (playerWon.value == null || gameResult.value == null) {
    // Game not over yet
    return null;
  }

  if (gameResult.value == Result.Draw) {
    return "draw";
  }

  return playerWon.value ? "win" : "loss";
}
</script>

<style scoped>
.opponent-box {
  margin-right: auto;
  margin-top: 1rem;
}
.container {
  display: flex;
  gap: 1rem;
}

.left {
  flex: 2;
  margin-top: -1.5rem;
  display: flex;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.boardcontainer {
  width: min(800px, 80vh);
  height: min(800px, 80vh);
  position: relative;
  display: flex;
  margin-right: 1rem;
}

.chessboard-wrap {
  width: 100%;
  height: 100%;
}

.side-indicator {
  position: absolute;
  bottom: -1rem;
  right: -1.5rem;
}

.side-indicator-white {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.side-indicator-black {
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

@media (max-width: 1200px) {
  .left {
    margin-top: 0rem;
  }
  .container {
    flex-direction: column;
    gap: 0rem;
    margin-top: -0.5rem;
  }

  .boardcontainer {
    width: min(100%, 78vh);
    height: min(100%, 78vh);
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
  }

  .side-indicator {
    bottom: 0rem;
    right: 0.5rem;
  }

  .opponent-box {
    margin-bottom: 1rem;
  }
}

.error-icon {
  height: 1.3rem;
  margin-right: 0.4rem;
  margin-top: -0.1rem;
}

:deep(cg-board) {
  background-color: v-bind(
    "chessBoardBg"
  ); /* Override the board background color */
}

.shake {
  animation: shake 0.6s ease-out forwards;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px) rotate(-0.1deg);
  }

  20%,
  80% {
    transform: translateX(2px) rotate(0.2deg);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px) rotate(-0.4deg);
  }

  40%,
  60% {
    transform: translateX(4px) rotate(0.4deg);
  }
}
.toggle-force-flip-board {
  outline: none;
  --toggle-width: 3rem;
  --toggle-font-size: 1rem;
  --toggle-bg-on: var(--clr-accent);
  --toggle-bg-off: var(--clr-main-lighter);
  --toggle-text-on: white;
  --toggle-text-off: white;
  --toggle-border-on: var(--clr-accent);
  --toggle-border-off: var(--clr-main-lighter);
  --toggle-handle-enabled: var(--clr-rect-2);
}
</style>
