<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <ConfettiExplosion
      v-if="showConfetti"
      :stageHeight="getViewportSize().height"
      :stageWidth="getViewportSize().width"
    />
    <div id="main-text"></div>
    <div id="sub-text" class="sub-text">&nbsp;</div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, type PropType, ref } from "vue";
import ConfettiExplosion from "vue-confetti-explosion";
import { usePageStore } from "@/stores/pageStore";
import { getViewportSize } from "@/util/util.js";
import { GameTermination } from "@/types/apitypes";

let showConfetti = ref(false);

const props = defineProps({
  termination: {
    type: Object as PropType<GameTermination>,
  },
});

const emit = defineEmits(["isRunning"]);

const explode = async () => {
  showConfetti.value = false;
  await nextTick();
  showConfetti.value = true;
};

function getWinText(termination: GameTermination | undefined) {
  switch (termination) {
    case GameTermination.Checkmate:
      return "by checkmate!";
    case GameTermination.Time:
      return "by time";
    case GameTermination.Resign:
      return "by resignation";
    default: // Should never happen since the only win conditions are above
      return "";
  }
}

function start() {
  emit("isRunning", { isRunning: true });
  function drawText(word: string = "WIN") {
    let wordArray = word.split("");

    let mainTextElement: HTMLElement | null =
      document.getElementById("main-text");
    let subTextElement: HTMLElement | null =
      document.getElementById("sub-text");
    if (mainTextElement === null || subTextElement === null) {
      return;
    }

    mainTextElement.innerHTML = "";
    subTextElement.innerHTML = "";
    mainTextElement.classList.remove("done-animating");
    subTextElement.classList.remove("done-animating");

    wordArray.forEach(function (letter: any, index: any) {
      let span = document.createElement("span");
      span.textContent = letter;
      span.className = "letter animate";

      span.style["animation-delay" as any] = 50 * index + "ms";

      mainTextElement!.appendChild(span);

      if (index === wordArray.length - 1) {
        span.addEventListener("animationend", function () {
          subTextElement!.innerHTML = getWinText(props.termination);
          subTextElement!.classList.add("swing-in-top-fwd");
          subTextElement!.addEventListener("animationend", function (event) {
            if (event.animationName === "scale-out") {
              explode();
              setTimeout(() => {
                showConfetti.value = false;
              }, 2500);
              mainTextElement!.innerHTML = "";
              subTextElement!.innerHTML = "";
              emit("isRunning", { isRunning: false });
            } else if (event.animationName === "swing-in-top-fwd") {
              mainTextElement!.classList.add("done-animating");
              subTextElement!.classList.remove("swing-in-top-fwd");
              subTextElement!.classList.add("scale-out");
            }
          });
        });
      }
    });
  }

  drawText();

  usePageStore().setPlaySound("win");
}

onMounted(() => {
  start();
});
</script>

<style>
@keyframes dash {
  0% {
    transform: skew(-30deg, 0deg) translateX(300%) scale(0.8);
    opacity: 1;
  }

  40% {
    transform: skew(10deg, 0deg) translateX(100%) scale(0.9);
  }

  60% {
    transform: skew(10deg, 0deg) translateX(-10px) scale(1.2);
  }

  70% {
    transform: skew(0, 0deg) translateX(0) scale(1.3);
  }

  75% {
    transform: skew(0, 0deg) translateX(0) scale(1.3);
  }

  90% {
    transform: skew(0, 0deg) translateX(0) scale(0.85);
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes done-animating {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  70% {
    transform: scale(2);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

#main-text {
  position: relative;
  z-index: 1;
}

.done-animating {
  animation: done-animating 500ms ease-in;
}

.scale-out {
  animation: scale-out 500ms ease-in;
}

#text-spot {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sub-text {
  font-size: 2rem;
  color: #fff2af;
  font-family: "Luckiest Guy";
  font-weight: 100;
  -webkit-text-stroke: 1.5px black;
  position: absolute;
  margin-top: 7rem;
}

.letter {
  display: inline-block;
  font-size: 120px;
  color: #fff2af;
  letter-spacing: 10px;
  font-family: "Luckiest Guy";
  font-weight: 100;
  -webkit-text-stroke: 2px black;
}

.letter.animate {
  animation: dash 500ms ease-in forwards;
  opacity: 0;
}

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
  animation: swing-in-top-fwd 1s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
</style>
